import { auth as firebaseauth } from "firebase/app";
import "firebase/auth";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { FmTextField } from "../../../formManager/FmField";
import { FmForm, FmFormRenderProps } from "../../../formManager/FmForm";
import { completeRegistration } from "../requests/completeRegistration";
import { PrimaryButton } from "../../../components/buttons";
import {
  Dialog,
  getDialogMethods,
  makeDialog,
} from "../../../components/dialogTools/DialogManager";
import { InfoDialogBase } from "../../../components/dialogTools/InfoDialog";
import { notifyError } from "../../../components/NotificationManager";
import { PageContainer } from "../../../components/PageContainer";
import PageIntro from "../../../components/PageIntro";
import SettingDescription from "../../../components/SettingDescription";
import SettingsContainer from "../../../components/SettingsContainer";
import { Title } from "../../../components/Title";
import SettingHeader from "../../../components/SettingHeader";

interface FormData {
  fmFormDataVersion: number; // required by FmForm
  email: string;
  password: string;
}

export interface CompleteRegistrationProps {}

export const CompleteRegistration = () => {
  const history = useHistory();
  const handleSubmit = async (
    fmFormRenderProps: FmFormRenderProps<FormData>
  ) => {
    const formData = fmFormRenderProps.formData;
    const auth = firebaseauth();
    if (!auth.isSignInWithEmailLink(window.location.href)) {
      throw new Error(
        "There was a problem with verifying your email address. You may have incorrectly copied the URL from the verification email."
      );
    }
    // signInWithEmailLink also verifies the email address
    auth
      .signInWithEmailLink(formData.email, window.location.href)
      .then(() => {
        window.localStorage.removeItem("emailForSignIn");
      })
      .then(() => {
        // We're logged in.
        // Now we verify the email address by sending the registration token.
        const tokens = history.location.pathname.split("/");
        const registrationToken = tokens[tokens.length - 1];
        return completeRegistration({
          registrationToken,
          password: formData.password,
        });
      })
      .then(() => {
        // sign in again, otherwise Firebase auth will throw an error in the normal
        // auth code in RouterTop. That's because the auth method changed from
        // an email link sign in to email/password.
        auth
          .signInWithEmailAndPassword(formData.email, formData.password)
          .then((_userCredential) => {
            openDialog({}).then(() => history.push("/home"));
          });
      })
      .catch(function (error) {
        notifyError(error);
      });
  };
  return (
    <FmForm
      fetch={{
        handler: () => {
          const email = window.localStorage.getItem("emailForSignIn");
          return Promise.resolve({
            fmFormDataVersion: 0,
            email: email ?? "",
            password: "",
          });
        },
      }}
      onSubmit={handleSubmit}
    >
      {(fmProps) => (
        <SettingsContainer>
          <Title>Complete Registration</Title>
          <PageIntro>
            Make sure your email address is correct, enter a password (which
            will be your password), then press Complete Registration. You will
            be able to create games immediately. If you forget your password,
            you can create a new one by pressing "SEND PASSWORD RESET EMAIL" on
            the login page.
          </PageIntro>
          <FmTextField<FormData>
            name="email"
            initialFocus
            width="20rem"
            isRequired
          />
          <FmTextField<FormData>
            name="password"
            inputType="password"
            isRequired
            maxLength={100}
            width="25rem"
            textFieldProps={{
              InputProps: {
                spellCheck: false,
              },
            }}
          />
          <PrimaryButton
            type="submit" // submit buttons automatically cause submit to be called from FmForm
            disabled={fmProps.isSubmitting && fmProps.formData.email !== ""}
          >
            Complete Registration
          </PrimaryButton>
        </SettingsContainer>
      )}
    </FmForm>
  );
};

interface Props {}
export let dialogInternal: Dialog<string[], Props>;

export const openDialog = (props: Props) => {
  if (!dialogInternal) {
    dialogInternal = makeComponent();
  }
  return getDialogMethods().open(dialogInternal, props);
};

const makeComponent = () => {
  return makeDialog<string[], Props>({
    componentRenderer: (dialogRenderProps) => {
      return (
        <InfoDialogBase
          onClose={() => {
            dialogRenderProps.close(false, undefined);
          }}
          open={true}
          title={"Wahoo!"}
        >
          <PageContainer>
            <SettingHeader>Welcome to the Cortex</SettingHeader>
            <SettingDescription>
              We're glad you registered. If you have questions or comments,
              please send them to support@cortexplay.com. You're now ready to
              author your first game. Enjoy!
            </SettingDescription>
          </PageContainer>
        </InfoDialogBase>
      );
    },
  });
};
