import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/AddBoxRounded";
import SpellIcon from "@material-ui/icons/FontDownload";
import SettingsIcon from "@material-ui/icons/Settings";
import * as React from "react";
import { useEffect, useState } from "react";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import { notifyError } from "../../../../components/NotificationManager";
import { TopicFrame } from "../../components/TopicFrame";
import { AdminUsersPage } from "./AdminUsersPage";

const drawerWidth = 240;
const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    title: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
      // position: "static",
    },
    drawerContainer: {
      overflow: "auto",
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
    // Loads information about the app bar, including app bar height
    toolbar: theme.mixins.toolbar,
  });
});

enum Page {
  Users,
  Settings,
}

export interface AdminTopicProps {
  admin: number;
}

export const AdminTopic: React.FunctionComponent<AdminTopicProps> = (props) => {
  const classes = useStyles();
  const [settingsToolbar, setSettingsToolbar] = useState<JSX.Element>(null);

  const history = useHistory();
  let { path } = useRouteMatch();

  // Check to see if we're admin
  useEffect(() => {
    if (props.admin < 1) {
      notifyError("Not an admin.");
      history.push("/home");
    }
  }, []);

  const leftNav = (
    <List>
      <ListItem button onClick={() => history.push(`${path}/users`)}>
        <ListItemText primary="Users" />
      </ListItem>
      <ListItem button onClick={() => history.push(`${path}/settings`)}>
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary="Settings" />
      </ListItem>
    </List>
  );

  const usersToolbar = (
    <Toolbar>
      <Typography variant="h6" className={classes.title}>
        Admin: Users
      </Typography>
      <Button color="inherit" startIcon={<AddIcon />} onClick={() => {}}>
        Add Something
      </Button>
    </Toolbar>
  );

  const [page, setPage] = useState(Page.Users);

  useEffect(() => {
    switch (history.location.pathname) {
      case `${path}/users`:
      case `${path}`:
        setPage(Page.Users);
        break;
      case `${path}/settings`:
        setPage(Page.Settings);
        break;
      default:
        setPage(Page.Users);
        break;
    }
  }, [history.location.pathname]);
  return (
    <>
      <TopicFrame
        appIcon={
          <div
            onClick={() => {
              history.push(`${path}/users`);
            }}
          >
            <SpellIcon />
          </div>
        }
        appIconMenuText="Admin"
        leftNav={leftNav}
        toolbar={
          page === Page.Users ? (
            usersToolbar
          ) : page === Page.Settings ? (
            <Toolbar>{settingsToolbar}</Toolbar>
          ) : (
            <Toolbar>{usersToolbar}</Toolbar>
          )
        }
      >
        <Switch>
          <Route
            exact
            path={`${path}`}
            render={(routeProps) => {
              return (
                <>
                  <AdminUsersPage />
                </>
              );
            }}
          />
          <Route
            path={`${path}/users`}
            render={(routeProps) => {
              return <AdminUsersPage />;
            }}
          />
          <Route
            path={[`${path}/settings`]}
            render={(routeProps) => {
              return null;
              // return <AdminSettingsPage setToolbar={setSettingsToolbar} />;
            }}
          />
        </Switch>
      </TopicFrame>
    </>
  );
};
