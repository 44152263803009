import { auth as firebaseauth } from "firebase/app";
import "firebase/auth";
import * as React from "react";
import { createContext, useEffect, useRef, useState } from "react";
import { Route, Switch } from "react-router-dom";
import { AdminTopic } from "../admin/components/AdminTopic";
import { ChangeEmail } from "./ChangeEmail";
import { ChangePassword } from "./ChangePassword";
import { CompleteRegistration } from "./CompleteRegistration";
import { GamesTopic } from "./GamesTopic";
import { Login } from "./Login";
import { Logoff } from "./Logoff";
import { Privacy } from "./Privacy";
import { Profile } from "./Profile";
import { Register, RegistrationSubmitted } from "./Register";
import { SettingsTopic } from "./SettingsTopic";
import { Subscription } from "./Subscription";
import { Terms } from "./Terms";
import { TopAppBar } from "./TopAppBar";
import { Home } from "./Home";

/*
The main nav is topics, such as the games (Spell, Compute, etc.), subscription management, and profile managemenent.
Work is done on pages, such as SpellGamesPageComponent, which is the Games pages in the Spell topic.
Navigation is ultimately to pages.

Nav is /<topic>/<page>.
*/

export interface AuthUserInfo {
  email: string;
  displayName: string;
}

let authUserInfo: AuthUserInfo;

export const UserContext = createContext<AuthUserInfo>({
  email: "",
  displayName: "",
});

let lastAuthIdTokenRefresh = Date.now();
let authPromise: Promise<string>;

export const getAuthIdToken = () => {
  const currentUser = firebaseauth().currentUser;
  if (!currentUser) {
    authPromise = undefined;
    return Promise.resolve(undefined);
  }
  const now = Date.now();
  // Refresh every 15m
  if (authPromise && now - lastAuthIdTokenRefresh < 15 * 60 * 1000) {
    return authPromise;
  }
  authPromise = currentUser
    .getIdToken(/* forceRefresh */ true)
    .then(function (authIdToken) {
      lastAuthIdTokenRefresh = now;
      return authIdToken;
    })
    .catch(function (error) {
      console.error(error);
      throw new Error("Failed to get Id token");
    });
  return authPromise;
};

export interface RouterTopProps {}
export const RouterTop = () => {
  const [uid, setUid] = useState(undefined);
  const [admin, setAdmin] = useState<number>(undefined);
  const toggleNavMenu = useRef<() => void>(undefined);
  useEffect(() => {
    firebaseauth().onAuthStateChanged(function (user) {
      authUserInfo = {
        email: "",
        displayName: "",
      };
      if (user) {
        getAuthIdToken()
          .then(function () {
            authUserInfo = {
              email: firebaseauth().currentUser.email,
              displayName: firebaseauth().currentUser.displayName,
            };
            setUid(user.uid);
          })
          .then(async (r) => {
            const idTokenResult = await firebaseauth().currentUser.getIdTokenResult();
            const adminData = idTokenResult.claims["admin"] as string;
            if (!adminData) {
              setAdmin(0);
            } else {
              setAdmin(parseInt(adminData));
            }
            return r;
          })
          .catch(function (error) {
            console.error(error);
            throw new Error("Failed to get Id token");
          });
      } else {
        setUid(null);
      }
    });
  }, []);

  // If first time in return null. Otherwise if the user is already logged in we first see the login page flash.
  if (uid === undefined) return null;

  if (uid === null) {
    return (
      <>
        <TopAppBar
          isAdmin={false}
          setToggleNavMenu={(f: () => void) => {
            toggleNavMenu.current = f;
          }}
        />
        <Switch>
          <Route exact path="/login">
            <Login />
          </Route>
          <Route exact path="/logoff">
            <Logoff />
          </Route>
          <Route exact path="/privacy">
            <Privacy />
          </Route>
          <Route exact path="/terms">
            <Terms />
          </Route>
          <Route exact path="/register">
            <Register />
          </Route>
          <Route path="/registrationsubmitted">
            <RegistrationSubmitted />
          </Route>
          <Route path="/completeregistration">
            <CompleteRegistration />
          </Route>
          <Route path="/">
            <Login />
          </Route>
        </Switch>
      </>
    );
  }
  // We check isAdmin. We don't want to force the user back to the home page until we figure out if they are an admin.
  if (admin === undefined) return null;
  return (
    <UserContext.Provider value={authUserInfo}>
      <TopAppBar
        isAdmin={admin > 0}
        setToggleNavMenu={(f: () => void) => {
          toggleNavMenu.current = f;
        }}
      />
      <Switch>
        <Route exact path={["/", "/home", "/register"]}>
          <Home getToggleNavMenu={() => toggleNavMenu.current} />
        </Route>
        <Route exact path="/logoff">
          <Logoff />
        </Route>
        <Route exact path="/privacy">
          <Privacy />
        </Route>
        <Route exact path="/terms">
          <Terms />
        </Route>
        <Route exact path="/profile">
          <Profile />
        </Route>
        <Route exact path="/subscription">
          <Subscription />
        </Route>
        <Route exact path="/changeemail">
          <ChangeEmail />
        </Route>
        <Route exact path="/changepassword">
          <ChangePassword />
        </Route>
        <Route path="/settings">
          <SettingsTopic />
        </Route>
        <Route path="/games">
          <GamesTopic />
        </Route>
        <Route
          path="/admin"
          render={(routeProps) => {
            // Admin will boot anyone not an admin
            return <AdminTopic admin={admin} />;
          }}
        />
      </Switch>
    </UserContext.Provider>
  );
};
