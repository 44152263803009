import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import * as React from "react";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    container: {
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "column",
      margin: "6rem 1rem 0rem 1rem",
      justifyContent: "center",
      alignItems: "center",
    },
  });
});
interface NotFoundProps {}

export const NotFound: React.FunctionComponent<NotFoundProps> = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {props.children ?? "Nothing to display. Try navigating elsewhere."}
    </div>
  );
};
