import * as React from "react";
import { ColumnContainer } from "../../../../../components/ColumnContainer";
import SettingDescription from "../../../../../components/SettingDescription";
import { TellMeMore } from "../../../../../components/TellMeMore";
import { Tip } from "../../../../../components/Tip";
import { FmTextField } from "../../../../../formManager/FmField";
import { FormData } from "./TwistEditPage";
import Hider from "../../../../../components/Hider";
import { TwistRulesComponent } from "../../../../twist/components/TwistRulesComponent";
import { TwistPlaySpec } from "../../../../../domain/twist_types";
import SettingSubheader from "../../../../../components/SettingSubheader";

export const TwistEditRulesPrologueTab = (props: {
  rulesPrologue: string;
  isActive: boolean;
  playSpec: TwistPlaySpec;
}) => {
  return (
    <Hider hidden={!props.isActive}>
      <ColumnContainer center>
        <Tip>
          Most rules are generated automatically. Read them below before writing
          a prologue.
        </Tip>
        <SettingDescription>
          The rules prologue will be the first information displayed when the
          user sees the rules. It might briefly describe the theme of the game,
          and special rules that are not generated automatically. For example,
          consider stating the focus of the game, such as learning certain
          spelling rules.
          <TellMeMore>
            Automatically generated rules include:
            <ul>
              <li>whether or not the solutions are case-sensitive</li>
              <li>the number of challenges with extra letters</li>
              <li>the number of challenges and points per game</li>
              <li>the points required to reach each level</li>
            </ul>
          </TellMeMore>
        </SettingDescription>
        <div>
          <FmTextField<FormData>
            name="rulesPrologue"
            width="70vw"
            textFieldProps={{ multiline: true, rows: 6 }}
          />
        </div>
        <SettingSubheader>Rules</SettingSubheader>
        <SettingDescription>
          Below are the rules, inluding the prologue (if any) above.
        </SettingDescription>
        <TwistRulesComponent
          playSpec={props.playSpec}
          gameInfo={{
            maxPoints: props.playSpec.challenges?.length,
            highestLevel:
              props.playSpec.levels[props.playSpec.levels.length - 1],
          }}
        />
      </ColumnContainer>
    </Hider>
  );
};
