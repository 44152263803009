import MoreVertIcon from "@material-ui/icons/MoreVert";
import * as React from "react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { SecondaryButton, SubtleButton } from "../../../components/buttons";
import SettingDescription from "../../../components/SettingDescription";
import SettingsContainer from "../../../components/SettingsContainer";
import { GameType, getGameTypeLabel } from "../../../domain/types";
import { gameAbstractsRecord } from "../requests/manageGameAbstracts";

export interface TemplateSettingsProps {
  gameType: GameType;
}
export const TemplateSettings: React.FunctionComponent<TemplateSettingsProps> = (
  props
) => {
  const [templateName, setTemplateName] = useState<string>(undefined);
  // undefined templateId means not yet fetched, null means no template
  const [templateId, setTemplateId] = useState<string>(undefined);
  useEffect(() => {
    gameAbstractsRecord().then((abstractsRecord) => {
      const templateId = Object.keys(abstractsRecord.gameAbstracts).find(
        (gameId) => {
          const abstract = abstractsRecord.gameAbstracts[gameId];
          return abstract.template && abstract.gameType === props.gameType;
        }
      );
      const abstract = abstractsRecord.gameAbstracts[templateId];
      if (templateId) {
        setTemplateName(abstract.name);
        setTemplateId(templateId);
      } else {
        setTemplateId(null);
      }
    });
  }, []);
  const history = useHistory();
  const gameTypeLabel = getGameTypeLabel(props.gameType);
  return (
    <SettingsContainer>
      {templateName && (
        <>
          <SettingDescription>
            To view or change your current template(
            {templateName}), press the button below.
          </SettingDescription>
          <SecondaryButton
            onClick={() => {
              history.push(`/games/edit/${templateId}`);
            }}
          >
            Go To My {gameTypeLabel} Game Template
          </SecondaryButton>
        </>
      )}
      {!templateName && (
        <SettingDescription>
          You have no game template. To create one, find a game on the
          <SubtleButton
            onClick={() => {
              history.push(`/games`);
            }}
          >
            Games search page
          </SubtleButton>
          , and choose "Make Template" from the command menu (
          <MoreVertIcon fontSize="small" />) next to the game.
        </SettingDescription>
      )}
    </SettingsContainer>
  );
};
