import { ApiResponseHeader } from "../../../../domain/serverContract";
import { makeRequestWithAuthentication } from "../../../../http/authenticated";

export interface DeleteUserGameDataRequest {
  tuid: string;
}

export interface DeleteUserGameDataResponse extends ApiResponseHeader {}

export async function deleteUserGameData(
  request: DeleteUserGameDataRequest
): Promise<DeleteUserGameDataResponse> {
  return makeRequestWithAuthentication(
    request,
    "/api/admin/delete_user_game_data"
  );
}
