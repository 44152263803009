import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import SensitiveIcon from "@material-ui/icons/Block";
import AdditionalWordsIcon from "@material-ui/icons/PostAdd";
import SettingsIcon from "@material-ui/icons/Settings";
import HiddenIcon from "@material-ui/icons/VisibilityOff";
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import { DialogManagerContext } from "../../../components/dialogTools/DialogManager";
import { ExtraWordsPage } from "./ExtraWordsPage";
import { HiddenWordsPage } from "./HiddenWordsPage";
import { SensitiveWordsPage } from "./SensitiveWordsPage";
import { TopicFrame } from "./TopicFrame";

const drawerWidth = 240;
const useStyles = makeStyles((theme: Theme) => {
  const c = createStyles({
    title: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
      // position: "static",
    },
    drawerContainer: {
      overflow: "auto",
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
    // Loads information about the app bar, including app bar height
    toolbar: theme.mixins.toolbar,
  });
  return c;
});

enum Page {
  Hidden,
  Extra,
  Sensitive,
}

export interface SettingsTopicProps {}

export const SettingsTopic: React.FunctionComponent<SettingsTopicProps> = (
  props
) => {
  const classes = useStyles();
  const dialogMethods = useContext(DialogManagerContext);
  const [extraWordsToolbar, setExtraWordsToolbar] = useState<JSX.Element>(null);
  const [hiddenWordsToolbar, setHiddenWordsToolbar] = useState<JSX.Element>(
    null
  );
  const [sensitiveWordsToolbar, setSensitiveWordsToolbar] = useState<
    JSX.Element
  >(null);
  const [templatesToolbar, setTemplatesToolbar] = useState<JSX.Element>(null);
  const history = useHistory();
  let { path } = useRouteMatch();

  const leftNav = (
    <List>
      <ListItem button onClick={() => history.push(`${path}/hidden`)}>
        <ListItemIcon>
          <HiddenIcon />
        </ListItemIcon>
        <ListItemText primary="Hidden Words" />
      </ListItem>
      <ListItem button onClick={() => history.push(`${path}/extra`)}>
        <ListItemIcon>
          <AdditionalWordsIcon />
        </ListItemIcon>
        <ListItemText primary="Extra Words" />
      </ListItem>
      <ListItem button onClick={() => history.push(`${path}/sensitive`)}>
        <ListItemIcon>
          <SensitiveIcon />
        </ListItemIcon>
        <ListItemText primary="Sensitive Words" />
      </ListItem>
    </List>
  );

  const [page, setPage] = useState(Page.Hidden);

  useEffect(() => {
    switch (history.location.pathname) {
      case `${path}/hidden`:
      case `${path}`:
        setPage(Page.Hidden);
        break;
      case `${path}/extra`:
        setPage(Page.Extra);
        break;
      case `${path}/sensitive`:
        setPage(Page.Sensitive);
        break;
      default:
        setPage(Page.Hidden);
        break;
    }
  }, [history.location.pathname]);
  return (
    <>
      <TopicFrame
        appIcon={
          <div
            onClick={() => {
              history.push(`${path}/hidden`);
            }}
          >
            <SettingsIcon />
          </div>
        }
        appIconMenuText="Settings"
        leftNav={leftNav}
        toolbar={
          page === Page.Hidden ? (
            <Toolbar>{hiddenWordsToolbar}</Toolbar>
          ) : page === Page.Extra ? (
            <Toolbar>{extraWordsToolbar}</Toolbar>
          ) : page === Page.Sensitive ? (
            <Toolbar>{sensitiveWordsToolbar}</Toolbar>
          ) : (
            hiddenWordsToolbar
          )
        }
      >
        <Switch>
          <Route
            exact
            path={`${path}`}
            render={(routeProps) => {
              return (
                <>
                  <HiddenWordsPage setToolbar={setHiddenWordsToolbar} />
                </>
              );
            }}
          />
          <Route
            path={`${path}/hidden`}
            render={(routeProps) => {
              return <HiddenWordsPage setToolbar={setHiddenWordsToolbar} />;
            }}
          />
          <Route
            path={[`${path}/extra`]}
            render={(routeProps) => {
              return <ExtraWordsPage setToolbar={setExtraWordsToolbar} />;
            }}
          />
          <Route
            path={[`${path}/sensitive`]}
            render={(routeProps) => {
              return (
                <SensitiveWordsPage setToolbar={setSensitiveWordsToolbar} />
              );
            }}
          />
        </Switch>
      </TopicFrame>
    </>
  );
};
