import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import PangramIcon from "@material-ui/icons/Category";
import DeleteIcon from "@material-ui/icons/Delete";
import AdditionalWordsIcon from "@material-ui/icons/PostAdd";
import LookupIcon from "@material-ui/icons/Search";
import SettingsIcon from "@material-ui/icons/Settings";
import SpecialIcon from "@material-ui/icons/Stars";
import LongestIcon from "@material-ui/icons/TextRotationNone";
import HiddenIcon from "@material-ui/icons/VisibilityOff";
import * as React from "react";
import { SecondaryButton } from "../../../../../components/buttons";
import { ColumnContainer } from "../../../../../components/ColumnContainer";
import { validateWords } from "../../../components/HiddenWordsPage";
import { notifyError } from "../../../../../components/NotificationManager";
import { PopupMenu } from "../../../../../components/PopupMenu";
import { RowContainer } from "../../../../../components/RowContainer";
import {
  saveUserGameSettings,
  userGameSettings,
} from "../../../requests/manageUserGameSettings";
import { max } from "../../../../../utilities";
import { isPangram } from "../wordFinder";
import { openAddGameWordsDialog } from "./AddGameWordsDialog";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      overflowY: "auto",
      maxHeight: "11rem",
      border: "1px solid black",
      //backgroundColor: theme.palette.background.paper,
    },
    tile: {
      margin: ".25rem",
      minWidth: "10rem",
      padding: ".25rem .5rem .25rem .5rem",
    },
  })
);

export const GearIcon = () => (
  <SettingsIcon style={{ marginRight: "0.5rem" }} fontSize="small" />
);

export enum WordSource {
  System,
  Extra,
}

export const Tile = (props: {
  word: string;
  source?: WordSource;
  actions: { remove: () => void; toggleSpecial: () => void };
  isPangram: boolean;
  isLongestWord: boolean;
  isSpecialWord: boolean;
}) => {
  const {
    word,
    source,
    actions,
    isLongestWord,
    isPangram,
    isSpecialWord,
  } = props;
  const classes = useStyles();

  return (
    <Paper className={classes.tile}>
      <RowContainer center>
        <PopupMenu
          menuElements={(pmProps) => {
            return [
              <MenuItem
                key="lookup"
                onClick={() => {
                  window.open(`https://www.google.com/#q=define+${word}`);
                  pmProps.close();
                }}
              >
                <LookupIcon
                  style={{ marginRight: "0.5rem" }}
                  fontSize="small"
                />
                Lookup Word (Google)
              </MenuItem>,
              <MenuItem
                key="remove"
                onClick={() => {
                  actions.remove();
                  pmProps.close();
                }}
              >
                <DeleteIcon
                  style={{ marginRight: "0.5rem" }}
                  fontSize="small"
                />
                Remove Word
              </MenuItem>,
              <MenuItem
                key="special"
                onClick={() => {
                  props.actions.toggleSpecial();
                  pmProps.close();
                }}
              >
                <SpecialIcon style={{ marginRight: "0.5rem" }} />
                {props.isSpecialWord ? "Remove Special" : "Make Special"}
              </MenuItem>,
              <MenuItem
                key="hidden"
                onClick={async () => {
                  const settings = await userGameSettings();
                  const errorMessage = validateWords(
                    [word],
                    settings.hiddenWords,
                    "hidden",
                    settings.extraWords,
                    "extra"
                  );
                  if (errorMessage) {
                    notifyError(errorMessage);
                    return;
                  }
                  saveUserGameSettings((userGameSettings) => {
                    userGameSettings.hiddenWords = [
                      ...userGameSettings.hiddenWords,
                      word,
                    ];
                  }).then(() => {
                    actions.remove();
                    pmProps.close();
                  });
                }}
              >
                <HiddenIcon style={{ marginRight: "0.5rem" }} />
                Add to Hidden Words (cannot undo) and Remove
              </MenuItem>,
              <MenuItem
                key="extra"
                onClick={async () => {
                  const settings = await userGameSettings();
                  const errorMessage = validateWords(
                    [word],
                    settings.extraWords,
                    "extra",
                    settings.hiddenWords,
                    "hidden"
                  );
                  if (errorMessage) {
                    notifyError(errorMessage);
                    return;
                  }
                  saveUserGameSettings((userGameSettings) => {
                    userGameSettings.extraWords = [
                      ...userGameSettings.extraWords,
                      word,
                    ];
                  }).then(() => {
                    pmProps.close();
                  });
                }}
              >
                <AdditionalWordsIcon style={{ marginRight: "0.5rem" }} />
                Add to Extra Words (cannot undo)
              </MenuItem>,
            ];
          }}
        >
          {() => (
            <RowContainer>
              <IconButton color="primary" aria-label="settings" size="small">
                <GearIcon />
                <Typography>{word}</Typography>
              </IconButton>
            </RowContainer>
          )}
        </PopupMenu>
        {source ?? WordSource.System === WordSource.System ? null : (
          <AdditionalWordsIcon />
        )}
        <Tooltip title="Pangram" aria-label="pangram" disableFocusListener>
          <PangramIcon
            fontSize="small"
            style={{
              marginLeft: ".5rem",
              display: isPangram ? null : "none",
            }}
          />
        </Tooltip>
        <Tooltip
          title="Longest Word"
          aria-label="longest word"
          disableFocusListener
        >
          <LongestIcon
            fontSize="small"
            style={{
              marginLeft: ".5rem",
              display: isLongestWord ? null : "none",
            }}
          />
        </Tooltip>
        <Tooltip
          title="Special Word"
          aria-label="special word"
          disableFocusListener
        >
          <IconButton
            color="primary"
            aria-label="remove"
            size="small"
            onClick={() => {
              props.actions.toggleSpecial();
            }}
          >
            <SpecialIcon
              fontSize="small"
              style={{
                marginLeft: ".5rem",
                display: isSpecialWord ? null : "none",
              }}
            />
          </IconButton>
        </Tooltip>
      </RowContainer>
    </Paper>
  );
};

export interface WordListProps {
  items: { word: string; source?: WordSource; isSpecial?: boolean }[];
  actions: {
    addWords: (words: string[]) => void;
    remove: (word: string) => void;
    toggleSpecial: (word: string) => void;
  };
  allLetters: string[];
  requiredLetters: string;
  optionalLetters: string;
  minimumWordLength: number;
}
const WordListInternal = (props: WordListProps) => {
  const greatestLength = max(props.items.map((item) => item.word.length));
  const { items, requiredLetters, optionalLetters, minimumWordLength } = props;
  return (
    <ColumnContainer center>
      <Box m={1} />
      <RowContainer>
        {props.items.map((tile) => (
          <Tile
            key={tile.word}
            word={tile.word}
            source={tile.source}
            actions={{
              remove: () => props.actions.remove(tile.word),
              toggleSpecial: () => props.actions.toggleSpecial(tile.word),
            }}
            isPangram={isPangram(tile.word, props.allLetters)}
            isSpecialWord={tile.isSpecial ?? false}
            isLongestWord={tile.word.length === greatestLength}
          />
        ))}
      </RowContainer>
      <Box m={1} />
      <SecondaryButton
        onClick={async () => {
          const dr = await openAddGameWordsDialog({
            minimumWordLength,
            optionalLetters,
            requiredLetters,
            foundWords: items.map((item) => item.word),
          });
          if (!dr.isOkay) return;
          const wordsToAddToExtraWordsList = dr.result;
          if (wordsToAddToExtraWordsList?.length > 0) {
            props.actions.addWords(wordsToAddToExtraWordsList);
          }
        }}
      >
        Add Additional Words to This Game
      </SecondaryButton>
    </ColumnContainer>
  );
};

export const WordList = React.memo(WordListInternal, (prevProps, nextProps) => {
  if (prevProps.items !== nextProps.items) return false;
  return true;
});
