import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import * as React from "react";
const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    title: {
      margin: "1rem 0 1rem 0",
    },
  });
});
interface SettingHeaderProps {}

const SettingHeader: React.FunctionComponent<SettingHeaderProps> = (props) => {
  const classes = useStyles();
  return (
    <Typography variant="h5" className={classes.title}>
      {props.children}
    </Typography>
  );
};

export default SettingHeader;
