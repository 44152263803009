import * as React from "react";
import { PageContainer } from "../../../components/PageContainer";
import SettingsContainer from "../../../components/SettingsContainer";
import Title from "../../../components/Title";

export const Terms = () => {
  return (
    <PageContainer>
      <Title>Terms</Title>
      <SettingsContainer>
        These terms describes what you can expect from us (Cortex Play) and what
        we expect from you regarding use of Cortex Author ("the App"). It is
        effective as of 13 August 2020. Questions about this policy should be
        emailed to terms @ cortexplay.com.
        <ul>
          <li>
            What you can expect from us:
            <ul>
              <li>
                the App should help you design games that are enjoyable and
                interesting
              </li>
              <li>the App should have good performance and availability</li>
              <li>
                we will not share your personal information outside of Cortex
                Play
              </li>
            </ul>
          </li>
          <li>
            What we expect from you:
            <ul>
              <li>not to allow other people to use your account.</li>
              <li>
                not to publish your games for commercial purposes. You may not
                charge for your games.
              </li>
              <li>
                to create a strong password that would be nearly impossible to
                guess.
              </li>
              <li>
                not to create games that are offensive in the context in which
                you are sharing them. The App allows you to choose which words
                are used in game play, and in game titles, descriptions, and
                elsewhere. While the App includes some tools to help you avoid
                creating offensive games, these tools offer little protection.
                Game content is entirely your responsibility.
              </li>
              <li>
                not to use the App for any purpose other than designing games.
                For example, you may not attempt to disrupt the App for other
                users by starting a denial of service attack on the App's web
                site.
              </li>
              <li>
                to share your games with your students, colleagues, friends, and
                family, but not with a public audience.
              </li>
            </ul>
          </li>
        </ul>
      </SettingsContainer>
    </PageContainer>
  );
};
