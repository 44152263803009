import * as React from "react";
import { useEffect, useRef } from "react";
import {
  Dialog,
  getDialogMethods,
  makeDialog,
} from "./dialogTools/DialogManager";
import { InfoDialogBase } from "./dialogTools/InfoDialog";
import SettingDescription from "./SettingDescription";
import SettingHeader from "./SettingHeader";
import SettingsContainer from "./SettingsContainer";

const MyHelp = () => {
  return (
    <SettingsContainer>
      <SettingHeader>Getting Help</SettingHeader>
      <SettingDescription>
        Sorry. There is no help for this page. If there is help for the page you
        are viewing, then it will appear here when you click "HELP".
      </SettingDescription>
    </SettingsContainer>
  );
};

const helpPages: Record<string, JSX.Element> = {
  home: <MyHelp />,
};

// Look for the active help item with the highest instanceId.
interface HelpItem {
  key: string;
  isActive: boolean;
}
// record key is the instanceId
const helpItems: Record<number, HelpItem> = Object.create(null);
let helpInstanceId = 0;
function getNextHelpInstanceId() {
  helpInstanceId++;
  return helpInstanceId;
}
export const useHelp = (
  key: string,
  helpElement: JSX.Element,
  isActive: boolean
) => {
  // Set current of myHelpInstanceIdRef the first time only.
  // That way it will have the helpInstanceId for this instance of the component.
  const myHelpInstanceIdRef = useRef(getNextHelpInstanceId());

  if (helpItems[myHelpInstanceIdRef.current]) {
    helpItems[myHelpInstanceIdRef.current].isActive = isActive;
  }

  // register the help element
  helpPages[key] = helpElement;
  useEffect(() => {
    // register instance
    helpItems[myHelpInstanceIdRef.current] = { key, isActive };
    return () => {
      // de-register instance
      delete helpItems[myHelpInstanceIdRef.current];
    };
  }, []);
};

const getHighestActiveHelpKey = () => {
  const id = Object.keys(helpItems).reduce(
    (largestActiveId: number, instanceIdText: string) => {
      const instanceId = Number(instanceIdText);
      const item = helpItems[instanceId];
      if (item.isActive && instanceId > largestActiveId) {
        largestActiveId = instanceId;
      }
      return largestActiveId;
    },
    -1
  );
  return helpItems[id]?.key;
};

export interface HelpDialogProps {}
export let getWordsDialogInternal: Dialog<string[], HelpDialogProps>;
export const openHelpDialog = (props: HelpDialogProps) => {
  if (!getWordsDialogInternal) {
    getWordsDialogInternal = makeHelpDialogComponent();
  }
  return getDialogMethods().open(getWordsDialogInternal, props);
};

export const makeHelpDialogComponent = () => {
  return makeDialog<string[], HelpDialogProps>({
    componentRenderer: (dialogRenderProps) => {
      let helpKey = getHighestActiveHelpKey();
      return (
        <InfoDialogBase
          onClose={() => {
            dialogRenderProps.close(false, undefined);
          }}
          open={true}
          title={`Help ${helpKey}`}
        >
          {helpPages[helpKey] ?? "No help available."}
        </InfoDialogBase>
      );
    },
  });
};
