import { useState } from "react";
import { ApiResponseHeader } from "../../../domain/serverContract";
import { UserProfile } from "../../../domain/types";
import { useIsMounted } from "../../../hooks/useIsMounted";
import { makeRequestWithAuthentication } from "../../../http/authenticated";
import { getAuthIdToken } from "../components/RouterTop";

export const useUserProfile = () => {
  const isMountedRef = useIsMounted();
  const [userProfile, setUserProfile] = useState<UserProfile>({
    displayName: "Cortex User",
    initials: "",
  });
  getUserProfile().then((up) => {
    // Somehow, the then clause may be executed before the userProfile record is received.
    // I don't know why.
    // I should not need to set a null replacement value for userProfile here.
    // Don't set state unless it has really changed.
    if (!up && userProfile.displayName === "") return;
    if (!up.initials) up.initials = up.displayName[0];
    if (up) {
      if (
        up.displayName === userProfile.displayName &&
        up.initials === userProfile.initials
      ) {
        return;
      }
    }
    isMountedRef.current &&
      setUserProfile(up ?? { displayName: "", initials: "" });
  });
  return userProfile;
};

let promiseCache: Promise<UserProfile>;

export const getUserProfile = async () => {
  const idToken = await getAuthIdToken();
  const up: UserProfile = { initials: "", displayName: "" };
  if (!idToken) return Promise.resolve(up);
  if (promiseCache) return promiseCache;
  promiseCache = gUserProfile()
    .then((response) => {
      return response.userProfile;
    })
    .catch((error) => {
      promiseCache = undefined;
      throw error;
    });
  return promiseCache;
};

// We get it back because the server may have changed it
interface SaveUserProfileResponse extends ApiResponseHeader {
  userProfile: UserProfile;
}

// Saves settings to the database
// Updates cache if save is successful
export async function saveUserProfile(
  mutator: (draftUserProfile: UserProfile) => void
): Promise<SaveUserProfileResponse> {
  const up = await getUserProfile();
  mutator(up);
  const response = await makeRequestWithAuthentication<SaveUserProfileResponse>(
    { userProfile: up },
    "/api/save_user_profile"
  );
  promiseCache = Promise.resolve(up);
  return response;
}

interface GetUserProfileResponse extends ApiResponseHeader {
  userProfile: UserProfile;
}
async function gUserProfile(): Promise<GetUserProfileResponse> {
  return makeRequestWithAuthentication({}, "/api/get_user_profile");
}
