import { dictionaryWords } from "./generated/dictionaryWords";
import { sensitiveWords } from "./generated/sensitiveWords";
import { userGameSettings } from "../../requests/manageUserGameSettings";

let sensitiveWordsMapCache: Record<string, boolean>;
const makeSnipDictionary = (
  wordCollections: string[][],
  includeSensitiveWords: boolean
) => {
  const a = performance.now();
  if (sensitiveWordsMapCache === undefined) {
    sensitiveWordsMapCache = sensitiveWords.reduce((map, word) => {
      map[word] = true;
      return map;
    }, Object.create(null));
  }

  const wordCount = wordCollections.reduce((sum, collection) => {
    sum += collection.length;
    return sum;
  }, 0);
  console.log(
    "Total dictionary size in words: ",
    wordCount - (includeSensitiveWords ? 0 : sensitiveWords.length)
  );

  const snipDictionary: Record<string, string[]> = Object.create(null);
  for (let j = 0; j < wordCollections.length; j++) {
    const dictionary = wordCollections[j];
    for (let i = 0; i < dictionary.length; i++) {
      const word = dictionaryWords[i];
      if (includeSensitiveWords === false && sensitiveWordsMapCache[word])
        continue;
      const letters = [...(word ?? [])].sort();
      let last = letters[0];
      const snip: string[] = [last];
      for (let j = 1; j < letters.length; j++) {
        const letter = letters[j];
        if (last != letter) {
          snip.push(letter);
          last = letter;
        }
      }
      const snipString = snip.join("");
      if (snipDictionary[snipString] === undefined)
        snipDictionary[snipString] = [];
      snipDictionary[snipString].push(word);
    }
  }
  const b = performance.now();
  console.log("makeSnipDictionary", (b - a) / 1000);
  console.log("we have snips:", Object.values(snipDictionary).length);
  return snipDictionary;
};

let extraWordsCache: string[];
let includeSensitiveWordsCache: boolean;
let snipDictionaryCache: Record<string, string[]>;
const getCurrentSnipDictionary = async (includeSensitiveWords: boolean) => {
  const settings = await userGameSettings();
  if (
    settings.extraWords !== extraWordsCache ||
    includeSensitiveWords != includeSensitiveWordsCache
  ) {
    extraWordsCache = settings.extraWords;
    const dictionaryList = [dictionaryWords, extraWordsCache];
    snipDictionaryCache = makeSnipDictionary(
      dictionaryList,
      includeSensitiveWords
    );
    includeSensitiveWordsCache = includeSensitiveWords;
  }
  return snipDictionaryCache;
};

let hiddenWordsMapCache: Record<string, boolean>;
let hiddenWordsCache: string[];
const getCurrentHiddenWordsMap = async () => {
  const settings = await userGameSettings();
  if (settings.hiddenWords !== hiddenWordsCache) {
    hiddenWordsCache = settings.hiddenWords;
    hiddenWordsMapCache = hiddenWordsCache.reduce((map, word) => {
      map[word] = true;
      return map;
    }, Object.create(null));
  }
  return hiddenWordsMapCache;
};

export const getWordsThatDoNotMatchCriteria = (
  words: string[],
  requiredLetters: string,
  optionalLetters: string,
  minimumWordLength: number
) => {
  // Not converting to lower case.
  // All word inputs and all dictionaries are lower case only, so we should not see upper case letters here.
  const rls = [...(requiredLetters ?? [])];
  const ols = [...(optionalLetters ?? [])];
  const allLetters = [...rls, ...ols];
  return words.reduce((result, word) => {
    if (word.length < minimumWordLength) {
      result.push(word);
      return result;
    }
    if (undefined !== rls.find((rl) => word.indexOf(rl) < 0)) {
      result.push(word);
      return result;
    }
    if (
      undefined !== [...(word ?? [])].find((l) => allLetters.indexOf(l) < 0)
    ) {
      result.push(word);
      return result;
    }
    return result;
  }, []);
};

// Assumes the word consists only of letters in allLetters
export const isPangram = (word: string, allLetters: string[]) => {
  return undefined === allLetters.find((letter) => word.indexOf(letter) < 0);
};

export const isWordInDictionary = (word: string) => {
  return dictionaryWords.indexOf(word) < 0 ? false : true;
};

// z["udl"] = dull, dud
// # of sets of n letters = 2^n (includes empty set)
// # sets if n letters where n > 2 = 2^n - 2^2
// 7 letters => 45
// 10 letters => 1021
// For each letter combination that has the required letters:
// * sort the combination ascending
// * look up list of matching words
// * eliminate short words
//
// Generate combinations
// * First generate combinations of optional letters
// * Loop from 1 to 2^o-1
// * Make bin map
// * If fewer than r bits, discard
// * Take the letters corresponding to the ones
// * Once done, add in required letters.
//

const makeSnips = (optionalLetters: string[], requiredLetters: string[]) => {
  const o = optionalLetters.length;
  const rlCount = requiredLetters.length;
  const b1 = 1;
  const b2 = (2 << (o - 1)) - 1;
  const clips: string[][] = [];
  for (let b = b1; b <= b2; b++) {
    let pattern = 1;
    const included = optionalLetters.reduce((result, letter) => {
      if (pattern & b) result.push(letter);
      pattern = pattern << 1;
      return result;
    }, [] as string[]);
    clips.push(included);
  }
  const snips = clips.map((clip) => {
    if (requiredLetters) clip.push(...requiredLetters);
    return clip.sort().join("");
  });
  if (rlCount > 0) snips.push(requiredLetters.sort().join(""));
  console.log({ snips });
  return snips;
};

export const findWords = async (
  requiredLetters: string,
  optionalLetters: string,
  minimumWordLength: number,
  maxWordCount: number
) => {
  const a = performance.now();
  const settings = await userGameSettings();
  const snips = makeSnips(
    [...(optionalLetters ?? [])],
    [...(requiredLetters ?? [])]
  );
  const snipDictionary = await getCurrentSnipDictionary(
    settings.includeSensitiveWords
  );
  const hiddenWordsMap = await getCurrentHiddenWordsMap();
  const words = snips.reduce((foundWords, snip) => {
    const wordList = snipDictionary[snip];
    if (!wordList) return foundWords;
    const addWords = wordList.filter(
      (word) =>
        word.length >= minimumWordLength &&
        foundWords.length <= maxWordCount &&
        hiddenWordsMap[word] === undefined
    );
    foundWords.push(...addWords);
    return foundWords;
  }, [] as string[]);
  const b = performance.now();
  console.log("findWords", (b - a) / 1000);
  return words;
};
