import * as React from "react";
import { SubtleButton } from "../../../../components/buttons";
import { ColumnContainer } from "../../../../components/ColumnContainer";
import { ConfirmationDialogBase } from "../../../../components/dialogTools/ConfirmationDialogBase";
import {
  Dialog,
  getDialogMethods,
  makeDialog,
} from "../../../../components/dialogTools/DialogManager";
import { notifyError } from "../../../../components/NotificationManager";
import SettingDescription from "../../../../components/SettingDescription";
import {
  FmCheckboxField,
  FmTextField,
  FmNumberField,
} from "../../../../formManager/FmField";
import { FmForm } from "../../../../formManager/FmForm";
import {
  convertBooleanToFilter,
  convertStringToFilter,
  Filter,
  convertIntToFilter,
} from "../requests/getUsers";

interface FormData {
  fmFormDataVersion: number; // required by FmForm
  uid: string;
  email: string;
  emailVerified: boolean;
  displayName: string;
  admin: number;
  disabled: boolean;
}

export interface FilterUsersDialogProps {
  filter: Filter;
}

export let filterUsersDialogInternal: Dialog<Filter, FilterUsersDialogProps>;

export const openFilterUsersDialog = (props: FilterUsersDialogProps) => {
  if (!filterUsersDialogInternal) {
    filterUsersDialogInternal = makeFilterUsersDialogComponent();
  }
  return getDialogMethods().open(filterUsersDialogInternal, props);
};

export const makeFilterUsersDialogComponent = () => {
  return makeDialog<Filter, FilterUsersDialogProps>({
    componentRenderer: (dialogRenderProps) => {
      const filter = dialogRenderProps.props.filter;
      return (
        <FmForm<FormData>
          suppressPrompt
          name="FilterUsersDialog"
          suppressSpinner
          fetch={{
            handler: () =>
              Promise.resolve({
                fmFormDataVersion: undefined,
                uid: filter?.uid?.value ?? "",
                displayName: filter?.displayName?.value ?? "",
                email: filter?.email?.value ?? "",
                admin: filter?.admin?.isAny ? undefined : filter?.admin?.value,
                emailVerified: filter?.emailVerified?.isAny
                  ? undefined
                  : filter?.emailVerified?.value,
                disabled: filter?.disabled?.isAny
                  ? undefined
                  : filter?.disabled?.value,
              }),
          }}
          onSubmit={(fmProps) => {
            const f = fmProps.formData;
            const r: Filter = {
              displayName: convertStringToFilter(f.displayName),
              email: convertStringToFilter(f.email),
              admin: convertIntToFilter(f.admin),
              uid: convertStringToFilter(f.uid),
              emailVerified: convertBooleanToFilter(f.emailVerified),
              disabled: convertBooleanToFilter(f.disabled),
            };
            return Promise.resolve(r);
          }}
        >
          {(fmProps) => (
            <ConfirmationDialogBase
              onClose={(isOkay) => {
                if (isOkay) {
                  fmProps
                    .submit()
                    .then((response: Filter) => {
                      dialogRenderProps.close(true, response);
                      return response;
                    })
                    .catch((reason) => notifyError(reason));
                } else {
                  dialogRenderProps.close(false, undefined);
                }
              }}
              open={true}
              title={"Filter"}
              okayText={"Set Filter"}
            >
              <ColumnContainer>
                <SubtleButton
                  onClick={() => {
                    fmProps.setFormData((draft) => {
                      draft.email = "";
                      draft.displayName = "";
                      draft.uid = "";
                      draft.admin = undefined;
                      draft.emailVerified = undefined;
                    });
                  }}
                >
                  Clear Filter
                </SubtleButton>
                <SettingDescription>
                  Enter filter criteria below
                </SettingDescription>
                <FmTextField<FormData> name="uid" width="25rem" initialFocus />
                <FmTextField<FormData>
                  name="email"
                  width="25rem"
                  initialFocus
                />
                <FmTextField<FormData> name="displayName" width="25rem" />
                <FmCheckboxField<FormData> name="emailVerified" width="25rem" />
                <FmCheckboxField<FormData> name="disabled" width="25rem" />
                <FmNumberField<FormData> name="admin" width="25rem" />
              </ColumnContainer>
            </ConfirmationDialogBase>
          )}
        </FmForm>
      );
    },
  });
};
