import { auth as firebaseauth } from "firebase/app";
import "firebase/auth";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { PrimaryButton, SubtleButton } from "../../../components/buttons";
import { ColumnContainer } from "../../../components/ColumnContainer";
import {
  notifyError,
  notifySuccess,
} from "../../../components/NotificationManager";
import Title from "../../../components/Title";
import { FmTextField } from "../../../formManager/FmField";
import { FmForm, FmFormRenderProps } from "../../../formManager/FmForm";

interface FormData {
  fmFormDataVersion: number; // required by FmForm
  email: string;
  password: string;
}

const initialFormData: FormData = {
  fmFormDataVersion: 0,
  email: "",
  password: "",
};

export interface LoginProps {}

export const Login = () => {
  const history = useHistory();
  const handleSubmit = async (
    fmFormRenderProps: FmFormRenderProps<FormData>
  ) => {
    const formData = fmFormRenderProps.formData;
    firebaseauth()
      .setPersistence(firebaseauth.Auth.Persistence.LOCAL)
      .then(() =>
        firebaseauth()
          .signInWithEmailAndPassword(formData.email, formData.password)
          .then((_userCredential) => {
            // replace, not push, so that BACK doesn't go back to the login page
            // But if we came from the logoff page, go home instead!
            const redirectPath = history.location.pathname;
            history.replace(
              redirectPath === "/logoff" || redirectPath === "/login"
                ? "/"
                : redirectPath
            );
          })
      )
      .catch(function () {
        notifyError("Login failed. Check the email address and password.");
      });
  };
  return (
    <FmForm
      suppressPrompt
      fetch={{ handler: () => Promise.resolve(initialFormData) }}
      onSubmit={handleSubmit}
    >
      {(fmProps) => (
        <ColumnContainer center>
          <Title>Login</Title>
          <FmTextField<FormData>
            name="email"
            initialFocus
            width="25rem"
            textFieldProps={{
              InputProps: {
                spellCheck: false,
              },
            }}
          />
          <FmTextField<FormData>
            name="password"
            width="25rem"
            textFieldProps={{
              type: "Password",
            }}
          />
          <PrimaryButton
            type="submit"
            onClick={() => fmProps.submit()}
            disabled={fmProps.isSubmitting}
          >
            Login
          </PrimaryButton>
          <SubtleButton
            onClick={() => {
              if (fmProps.formData.email === "") {
                notifyError(
                  "Enter an email address before requesting a new password."
                );
                return;
              }
              firebaseauth()
                .sendPasswordResetEmail(fmProps.formData.email)
                .then(() => {
                  notifySuccess(
                    "Check your email (and spam folder) for the password reset email."
                  );
                })
                .catch((error) => {
                  notifyError(error);
                });
            }}
            disabled={fmProps.isSubmitting}
          >
            Send password reset email
          </SubtleButton>
        </ColumnContainer>
      )}
    </FmForm>
  );
};
