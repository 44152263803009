import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import * as React from "react";
import { ConfirmationDialogBase } from "../../../../../components/dialogTools/ConfirmationDialogBase";
import {
  Dialog,
  getDialogMethods,
  makeDialog,
} from "../../../../../components/dialogTools/DialogManager";
import { notifyError } from "../../../../../components/NotificationManager";
import SettingDescription from "../../../../../components/SettingDescription";
import { FmTextField } from "../../../../../formManager/FmField";
import { FmForm } from "../../../../../formManager/FmForm";
import { openAddGameDialog } from "../../../components/AddGameDialog";
import { getGame } from "../../../requests/getGame";

interface FormData {
  fmFormDataVersion: number; // required by FmForm
  url: string;
}

export interface ImportGameDialogProps {}

export let getWordsDialogInternal: Dialog<string[], ImportGameDialogProps>;

export const openImportGameDialog = (props: ImportGameDialogProps) => {
  if (!getWordsDialogInternal) {
    getWordsDialogInternal = makeImportGameDialogComponent();
  }
  return getDialogMethods().open(getWordsDialogInternal, props);
};

export const makeImportGameDialogComponent = () => {
  return makeDialog<string[], ImportGameDialogProps>({
    componentRenderer: (dialogRenderProps) => {
      return (
        <FmForm
          suppressPrompt
          name="ImportGameDialog"
          fetch={{
            handler: () =>
              Promise.resolve({ url: "", fmFormDataVersion: undefined }),
          }}
          onSubmit={async (fmProps) => {
            // Pull out the gameRef from the URL.
            // First, strip any query params.
            // Then take token after last /
            const url = fmProps.formData.url;
            if (url.length === 0) return;
            const t1s = url.split("?");
            const t2s = t1s[0].split("/");
            const gameRef = t2s[t2s.length - 1];
            const r = await getGame({ id: gameRef });
            openAddGameDialog({
              templateGameRef: gameRef, // use this game as the template
              title: "Import Game",
              gameType: r.gameRecord.gameType,
            });
            // Just want to close this dialog
            dialogRenderProps.close(false, undefined);
            return Promise.resolve();
          }}
        >
          {(fmProps) => (
            <ConfirmationDialogBase
              onClose={(isOkay) => {
                if (isOkay) {
                  fmProps
                    .submit()
                    .then((response: string[]) => {
                      dialogRenderProps.close(true, response);
                      return response;
                    })
                    .catch((reason) => notifyError(reason));
                } else {
                  dialogRenderProps.close(false, undefined);
                }
              }}
              open={true}
              title={"Import Game"}
              okayText={"Next"}
            >
              <Grid container direction="column" alignItems="center">
                <SettingDescription>
                  Enter the URL for the game you wish to import. On the next
                  page you will be able to specify the name of your game.
                </SettingDescription>
                <Box m={0.5} />
                <Grid container direction="column" alignContent="center">
                  <FmTextField<FormData> name="url" width="100%" initialFocus />
                </Grid>
              </Grid>
            </ConfirmationDialogBase>
          )}
        </FmForm>
      );
    },
  });
};
