import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import * as React from "react";
import { useEffect, useState } from "react";
import { getVersion } from "../app/author/requests/getVersion";
import { InfoDialogBase } from "./dialogTools/InfoDialog";
import { openJustOkDialog } from "./JustOkDialog";

export interface VersionProps {}

const Version = (props: VersionProps) => {
  const [versionItems, setVersionItems] = useState<string[]>([""]);

  useEffect(() => {
    getVersion({})
      .then((reponse) => setVersionItems(reponse.versionItems))
      .catch((reason) => {
        throw new Error("Failed to get version: " + reason);
      });
  }, []);

  return (
    <Box m={1}>
      <Grid container direction="column" alignItems="flex-start">
        {versionItems.map((item, index) => {
          return <Typography key={index}>{item}</Typography>;
        })}
      </Grid>
    </Box>
  );
};

export const openVersionDialog = () => {
  openJustOkDialog({ content: <Version />, title: "Version" });
};
