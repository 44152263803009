import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import * as React from "react";
import { ConfirmationDialogBase } from "../../../../../components/dialogTools/ConfirmationDialogBase";
import {
  Dialog,
  getDialogMethods,
  makeDialog,
} from "../../../../../components/dialogTools/DialogManager";
import { notifyError } from "../../../../../components/NotificationManager";
import SettingDescription from "../../../../../components/SettingDescription";
import { FmTextField } from "../../../../../formManager/FmField";
import { FmForm, FmFormRenderProps } from "../../../../../formManager/FmForm";
import {
  findDuplicates,
  makeSeparatedStringFromList,
  intersection,
} from "../../../../../utilities";
import { Challenge } from "../../../../../domain/twist_types";
import { ColumnContainer } from "../../../../../components/ColumnContainer";

interface FormData {
  fmFormDataVersion: number; // required by FmForm
  words: string;
}

export interface BulkAddChallengesDialogProps {
  title: string;
  currentChallenges: Challenge[];
}

export interface BulkExport {
  word: string;
  clue: string;
}

export let getBulkAddChallengesDialogInternal: Dialog<
  BulkExport[],
  BulkAddChallengesDialogProps
>;

export const openBulkAddChallengesDialog = (
  props: BulkAddChallengesDialogProps
) => {
  if (!getBulkAddChallengesDialogInternal) {
    getBulkAddChallengesDialogInternal = makeBulkAddChallengesDialogComponent();
  }
  return getDialogMethods().open(getBulkAddChallengesDialogInternal, props);
};

export const makeBulkAddChallengesDialogComponent = () => {
  return makeDialog<BulkExport[], BulkAddChallengesDialogProps>({
    componentRenderer: (dialogRenderProps) => {
      const { currentChallenges } = dialogRenderProps.props;
      const fmFormSubmitHandler = (
        fmFormRenderProps: FmFormRenderProps<FormData>
      ) => {
        const formData = fmFormRenderProps.formData;
        const lines = formData.words
          .replace(/[ \f\r\t\v]+/g, " ")
          .trim()
          .split("\n");
        if (lines.length === 0 || lines[0] === "") {
          return Promise.reject("There are no words to add.");
        }
        const bulkExports = lines.map((line) => {
          // Anything after the first comma is the clue.
          const tokens = line.split(",", 2);
          return {
            word: tokens[0],
            clue: tokens.length === 1 ? "" : tokens[1],
          };
        });
        const words = bulkExports.map((be) => be.word);
        const dups = findDuplicates(words);
        if (dups.length > 0) {
          return Promise.reject(
            "These words were entered more than once in your list: " +
              makeSeparatedStringFromList(dups)
          );
        }
        const existing = intersection(
          words,
          currentChallenges.map((challenge) => challenge.solution)
        );
        if (existing.length > 0) {
          return Promise.reject(
            "These words are already part of existing challenges: " +
              makeSeparatedStringFromList(dups)
          );
        }
        return Promise.resolve(bulkExports);
      };

      return (
        <FmForm
          suppressPrompt
          name="BulkAddChallengesDialog"
          suppressSpinner
          fetch={{
            handler: () =>
              Promise.resolve({ words: "", fmFormDataVersion: undefined }),
          }}
          onSubmit={fmFormSubmitHandler}
        >
          {(fmProps) => (
            <ConfirmationDialogBase
              onClose={(isOkay) => {
                if (isOkay) {
                  fmProps
                    .submit()
                    .then((response: BulkExport[]) => {
                      dialogRenderProps.close(true, response);
                      return response;
                    })
                    .catch((reason) => notifyError(reason));
                } else {
                  dialogRenderProps.close(false, undefined);
                }
              }}
              open={true}
              title={dialogRenderProps.props.title}
              okayText={dialogRenderProps.props.title}
            >
              <ColumnContainer center>
                <SettingDescription>
                  Enter one or more solutions, one to a line. One challenge will
                  be created for each solution. You may also add a comma to a
                  line, followed by a clue.
                </SettingDescription>
                <Box m={0.5} />
                <Grid container direction="column" alignContent="center">
                  <FmTextField<FormData>
                    name="words"
                    width="100%"
                    textFieldProps={{ multiline: true, rows: 6 }}
                    initialFocus
                  />
                </Grid>
              </ColumnContainer>
            </ConfirmationDialogBase>
          )}
        </FmForm>
      );
    },
  });
};
