import { Box } from "@material-ui/core";
import * as React from "react";
import {
  MildGuidingButton,
  StrongGuidingButton,
} from "../../../../../components/buttons";
import { ColumnContainer } from "../../../../../components/ColumnContainer";
import ExplanatoryNote from "../../../../../components/ExplanatoryNote";
import Hider from "../../../../../components/Hider";
import SettingDescription from "../../../../../components/SettingDescription";
import { TellMeMore } from "../../../../../components/TellMeMore";
import {
  GameType,
  gameTypeHasAnswers,
  getGameTypeLabel,
} from "../../../../../domain/types";
import { FmTextField } from "../../../../../formManager/FmField";
import { CommonFormData } from "../editorTypes";
import { useHelp } from "../../../../../components/HelpDialog";
import RedoIcon from "@material-ui/icons/Redo";
import UndoIcon from "@material-ui/icons/Undo";
import SettingsContainer from "../../../../../components/SettingsContainer";
import SettingHeader from "../../../../../components/SettingHeader";
import SettingSubheader from "../../../../../components/SettingSubheader";
import Emphasize from "../../../../../components/Emphasize";

const navHelp = (gameType: GameType) => (
  <>
    At the top-right of this page is a solid yellow button that looks like this:
    <Box m={0.25} />
    <StrongGuidingButton>
      Choose {gameType === "s" ? "Words" : "Challenges"}
    </StrongGuidingButton>
    <Box m={0.25} />
    Click it. To create a game, navigate back and forth through the game editor
    pages using the navigation buttons at the top. The forward buttons are solid
    yellow, and the backwards buttons are outlined, like this:
    <Box m={0.25} />
    <MildGuidingButton>Name Game</MildGuidingButton>
    <Box m={0.25} />
    There is no backwards button on this page, since it is the first page in the
    game editor.
  </>
);

const EditNameGameTabInternal = (props: {
  formData: CommonFormData;
  showAnswerKey: boolean;
  gameType: GameType;
  isActive: boolean;
}) => {
  const { formData } = props;
  useHelp("EditNameGameTab", <MyHelp />, props.isActive);
  return (
    <Hider hidden={!props.isActive}>
      <ColumnContainer>
        <Hider hidden={!formData.isTemplateGame}>
          <ExplanatoryNote>
            <Emphasize>
              This game is the template for your{" "}
              {getGameTypeLabel(props.gameType)} games.
            </Emphasize>
          </ExplanatoryNote>
        </Hider>
        <SettingDescription>
          The name will be part of the game URL, making it easier for you to
          differentiate between URLs.
        </SettingDescription>
        <FmTextField<CommonFormData>
          name="name"
          initialFocus
          isRequired
          maxLength={20}
        />
        <SettingDescription>
          The title will appear at the top of the game.
        </SettingDescription>
        <FmTextField<CommonFormData> name="title" maxLength={50} />
        <SettingDescription>
          The subtitle will appear just below the title.
        </SettingDescription>
        <FmTextField<CommonFormData> name="subtitle" maxLength={100} />
        <SettingDescription>
          The description is for your use only. It is not shown to players.
        </SettingDescription>
        <FmTextField<CommonFormData> name="description" maxLength={100} />

        <Hider hidden={!gameTypeHasAnswers(props.gameType)}>
          <ColumnContainer>
            <SettingDescription>
              Players can use the answer key to unlock the answers. It lets
              users see the words they missed. Case doesn't matter. Don't share
              it until you want them to know all of the answers.
            </SettingDescription>
            <FmTextField<CommonFormData> name="answerKey" maxLength={50} />
          </ColumnContainer>
        </Hider>

        <SettingDescription>
          <TellMeMore color="primary" buttonText="What do I do next?">
            {navHelp(props.gameType)}
          </TellMeMore>
        </SettingDescription>
      </ColumnContainer>
    </Hider>
  );
};

export const EditNameGameTab = React.memo(
  EditNameGameTabInternal,
  (prevProps, nextProps) => {
    if (prevProps.isActive !== nextProps.isActive) return false;
    if (prevProps.formData.name !== nextProps.formData.name) return false;
    if (prevProps.formData.title !== nextProps.formData.title) return false;
    if (prevProps.formData.subtitle !== nextProps.formData.subtitle)
      return false;
    if (prevProps.formData.answerKey !== nextProps.formData.answerKey)
      return false;
    if (prevProps.formData.isTemplateGame !== nextProps.formData.isTemplateGame)
      return false;
    return true;
  }
);

const MyHelp = () => {
  return (
    <SettingsContainer>
      <SettingHeader>Name Game</SettingHeader>
      <SettingSubheader>Navigating Between Editor Tabs</SettingSubheader>
      <SettingDescription>{navHelp}</SettingDescription>
      <SaveUndoRedoHelp />

      <SettingSubheader>Name</SettingSubheader>
      <SettingDescription>
        The name will be part of the game URL, making it easier for you to
        differentiate between URLs. The name must consist entirely of letters,
        digits, underscores, and dashes (no spaces, because it is part of a
        URL). This allows the name to be easily read in URLs, so that you can
        identify the game to which a URL refers.
      </SettingDescription>

      <SettingSubheader>Title</SettingSubheader>
      <SettingDescription>
        The title will appear at the top of the game.
      </SettingDescription>

      <SettingSubheader>Subtitle</SettingSubheader>
      <SettingDescription>
        The subtitle will appear just below the title. It is optional.
      </SettingDescription>

      <SettingSubheader>Description</SettingSubheader>
      <SettingDescription>
        The description lets you document information about a game. It is not
        shown to players. It is optional.
      </SettingDescription>

      <SettingSubheader>Answer Key</SettingSubheader>
      <SettingDescription>
        Some games do not have an answer key. Players can use the answer key to
        unlock the answers. It lets users see the words they missed. Case
        doesn't matter. Don't share it until you want them to know all of the
        answers.
      </SettingDescription>

      <SettingSubheader>Undo and Redo</SettingSubheader>
      <SettingDescription>
        While editing a game you can undo and redo changes, using the buttons{" "}
        <UndoIcon />
        and <RedoIcon />. If you leave and then return to the editor, you will
        not be able to undo or redo changes made previously.
      </SettingDescription>
    </SettingsContainer>
  );
};

export const SaveUndoRedoHelp = () => {
  return (
    <>
      <SettingSubheader>Save</SettingSubheader>
      <SettingDescription>
        Changes you make to the game while editing are not saved automatically.
        You must press SAVE.
      </SettingDescription>
      <SettingSubheader>Undo and Redo</SettingSubheader>
      <SettingDescription>
        While editing a game you can undo and redo changes, using the buttons{" "}
        <UndoIcon />
        and <RedoIcon />. If you leave and then return to the editor, you will
        not be able to undo or redo changes made previously.
      </SettingDescription>
    </>
  );
};
