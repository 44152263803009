import { ApiResponseHeader } from "../../../domain/serverContract";
import { makeRequest } from "../../../http/http";

interface GetConfigurationRequest {}

interface GetConfigurationResponse extends ApiResponseHeader {
  gameUrlPattern: string;
  recaptchaSiteKey: string;
}

async function getConfigurationInternal(
  request: GetConfigurationRequest
): Promise<GetConfigurationResponse> {
  return makeRequest(request, "/api/get_configuration");
}

interface Configuration {
  gameUrlPattern: string;
  recaptchaSiteKey: string;
}

let configuration: Configuration;

export async function getConfiguration(): Promise<Configuration> {
  if (configuration) return configuration;
  const response = await getConfigurationInternal({});
  configuration = {
    gameUrlPattern: response.gameUrlPattern,
    recaptchaSiteKey: response.recaptchaSiteKey,
  };
  return configuration;
}
