import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import * as React from "react";
import Divider from "@material-ui/core/Divider";
const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    body: {
      margin: "0.25rem 0 0.25rem 0",
      fontSize: theme.typography.body1.fontSize,
    },
  });
});
interface PageIntroProps {}

const PageIntro: React.FunctionComponent<PageIntroProps> = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.body}>
      {props.children}
      <Divider />
    </div>
  );
};
export default PageIntro;
