import Grid from "@material-ui/core/Grid";
import produce, { applyPatches, Draft, Patch } from "immer";
import * as React from "react";
import { createContext, useEffect, useRef, useState } from "react";
import { Prompt } from "react-router-dom";
import { busyPromise } from "../components/BusySpinner";
import { notifyError } from "../components/NotificationManager";
import { useIsMounted } from "../hooks/useIsMounted";

export interface FmFormRenderProps<TFormData> {
  isDirty: boolean;
  setCleanFormData: (formData: TFormData) => void;
  isSubmitting: boolean;
  setIsSubmitting: (isSubmitting: boolean) => void;
  formData: TFormData;
  cleanFormData: TFormData;
  submit: () => Promise<unknown>;
  setFormData: (
    mutator: (formData: Draft<TFormData>) => TFormData | void
  ) => TFormData;
  setDerivedFormData: (
    mutator: (formData: Draft<TFormData>) => TFormData | void
  ) => TFormData;
  undo: () => void;
  isUndoAvailable: boolean;
  redo: () => void;
  isRedoAvailable: boolean;
  registerValidator: (validator: Validator<TFormData>) => void;
}

export interface FmFormProps<TFormData> {
  fetch: { handler: () => Promise<TFormData>; trigger?: () => unknown };
  onSubmit: (
    fmFormRenderProps: FmFormRenderProps<TFormData>
  ) => Promise<unknown>;
  name?: string;
  suppressSpinner?: boolean;
  // Suppress the prompt the blocks navigation
  suppressPrompt?: boolean;
  debug?: boolean;
}

export interface Validator<TFormData> {
  (formData: TFormData): string; // returns undefined if there is no error, otherwise the error message
}

export const FmFormContext = createContext<FmFormRenderProps<any>>(null);

type TypeFormData = {
  [K in "fmFormDataVersion"]: number;
};

export const FmForm = <TFormData extends TypeFormData>(
  props: FmFormProps<TFormData> & {
    children?: (fmProps: FmFormRenderProps<TFormData>) => React.ReactNode;
  }
): React.ReactElement<any, any> | null => {
  if (props.debug) console.debug("FmForm rendering");
  const validators = useRef<Validator<TFormData>[]>([]);

  const [fetchedFailed, setFetchedFailed] = useState(false);
  const version = useRef(0);
  const [formDataForceRender, setFormDataForceRender] = useState(0);
  const formDataRef = useRef<TFormData>(undefined);
  // Get formData via function call so as not to get stale via closure
  const getFormData = () => formDataRef.current;
  const setFormData = (f: () => TFormData) => {
    const newData = f();
    if (newData === formDataRef.current) return;
    formDataRef.current = newData;
    // Don't rely on knowing prev state.
    // It might be stale due to closure AND that we use React.memo for some components.
    setFormDataForceRender(new Date().getTime());
  };

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDirty, setIsDirty] = useState(undefined);
  const [cleanVersionNumber, setCleanVersionNumber] = useState(version.current);
  const cleanFormDataRef = useRef<TFormData>(undefined);
  const getCleanFormData = () => cleanFormDataRef.current;
  const setCleanVersion = (version: number) => {
    setCleanVersionNumber(version);
    setIsDirty(false);
    cleanFormDataRef.current = formDataRef.current;
  };
  interface PatchChunk {
    patches: Patch[];
    isDerived?: boolean;
  }
  const changeChunks = useRef<PatchChunk[]>([]);
  const inverseChangeChunks = useRef<PatchChunk[]>([]);
  const redoChangeChunks = useRef<
    { changes: PatchChunk; inverseChanges: PatchChunk }[]
  >([]);

  const fmSetCleanFormData = (formData: TFormData) => {
    setCleanVersion(formData.fmFormDataVersion);
  };

  const undo = () => {
    if (inverseChangeChunks.current.length > 0) {
      const patchChunk = inverseChangeChunks.current.pop();
      const redoChangeChunk = {
        changes: changeChunks.current.pop(),
        inverseChanges: patchChunk,
      };
      redoChangeChunks.current.push(redoChangeChunk);
      setFormData(() => {
        return applyPatches(getFormData(), patchChunk.patches);
      });
    }
  };

  const redo = () => {
    if (redoChangeChunks.current.length > 0) {
      const redoChangeChunk = redoChangeChunks.current.pop();
      changeChunks.current.push(redoChangeChunk.changes);
      inverseChangeChunks.current.push(redoChangeChunk.inverseChanges);
      setFormData(() =>
        applyPatches(getFormData(), redoChangeChunk.changes.patches)
      );
    }
  };

  const setFormDataInternal = (
    mutator: (draftFormData: Draft<TFormData>) => TFormData,
    isDerived = false
  ) => {
    // cannot redo once state is changed (except when doing undo/redo)
    if (!isDerived) redoChangeChunks.current = [];
    const rv = produce(
      getFormData(),
      (draft) => {
        if (!isDerived) draft.fmFormDataVersion = ++version.current;
        mutator(draft);
        return;
      },
      (patches, inversePatches) => {
        if (isDerived) return;
        changeChunks.current.push({ patches, isDerived });
        inverseChangeChunks.current.push({
          patches: inversePatches,
          isDerived,
        });
      }
    ) as TFormData;
    setFormData(() => rv);
    return rv;
  };

  const setIsSubmittingInternal = (s: boolean) => {
    setIsSubmitting(s);
  };

  const fmFormRenderProps: FmFormRenderProps<TFormData> = {
    isDirty,
    setCleanFormData: fmSetCleanFormData,
    formData: getFormData(),
    cleanFormData: getCleanFormData(),
    setIsSubmitting: setIsSubmittingInternal,
    isSubmitting,
    submit: undefined as any,
    setFormData: setFormDataInternal,
    setDerivedFormData: (mutator: (formData: Draft<TFormData>) => TFormData) =>
      setFormDataInternal(mutator, true),
    undo,
    isUndoAvailable: inverseChangeChunks.current.length > 0,
    redo,
    isRedoAvailable: redoChangeChunks.current.length > 0,
    registerValidator: (validator: Validator<TFormData>) =>
      validators.current.push(validator),
  };

  const isMountedRef = useIsMounted();

  // Re-fetch if the fetch trigger changed
  useEffect(() => {
    const p = props.fetch
      .handler()
      .then((formData) => {
        setFormData(() => formData);
        setCleanVersion(formData.fmFormDataVersion);
        setFetchedFailed(false);
      })
      .catch((error) => {
        setFetchedFailed(true);
        notifyError(error);
        throw error;
      });
    busyPromise(p); // show spinner
  }, [props.fetch.trigger && props.fetch.trigger()]);

  function beforeunloadListener(event: BeforeUnloadEvent) {
    event.preventDefault();
    event.returnValue = "You may lose changes if you proceed...";
  }

  const beforeunloadListenerRef = useRef<(event: BeforeUnloadEvent) => void>(
    undefined
  );

  useEffect(() => {
    const isDirtyNow = cleanVersionNumber !== getFormData()?.fmFormDataVersion;
    setIsDirty(isDirtyNow);
    if (props.suppressPrompt) return;
    // Set up browser to check for exiting
    if (isDirtyNow && isDirty === false) {
      // Just got dirty...add listener
      beforeunloadListenerRef.current = beforeunloadListener;
      window.addEventListener("beforeunload", beforeunloadListenerRef.current);
    } else if (!isDirtyNow && isDirty) {
      if (beforeunloadListenerRef.current) {
        window.removeEventListener(
          "beforeunload",
          beforeunloadListenerRef.current
        );
        beforeunloadListenerRef.current = undefined;
      }
    }
  });

  // Remove beforeunload listener when we unmount
  useEffect(() => {
    return () => {
      if (beforeunloadListenerRef.current) {
        window.removeEventListener(
          "beforeunload",
          beforeunloadListenerRef.current
        );
      }
    };
  }, []);

  const doSubmit = async () => {
    const invalid = validators.current.find((validator) => {
      const errorMessage = validator(fmFormRenderProps.formData);
      if (errorMessage) {
        notifyError(errorMessage);
        return true;
      }
    });
    if (invalid) {
      return Promise.resolve();
    }
    setIsSubmitting(true);
    const promise = props
      .onSubmit(fmFormRenderProps) // form may want to update form data when call returns
      .then((data) => {
        if (isMountedRef.current === false) return data;
        setCleanVersion(getFormData().fmFormDataVersion);
        return data;
      })
      .catch((error) => {
        notifyError(error);
        throw error;
      })
      .finally(() => {
        // The submit might close the form.
        if (isMountedRef.current) setIsSubmitting(false);
      });
    if (props.suppressSpinner !== true) {
      busyPromise(promise);
    }
    return promise;
  };

  fmFormRenderProps.submit = () => {
    return doSubmit();
  };

  if (fetchedFailed) {
    return (
      <Grid container justify="center">
        There is no information to display.
      </Grid>
    );
  }

  if (getFormData() === undefined) {
    return (
      <Grid container justify="center">
        Working...
      </Grid>
    );
  }

  return (
    <>
      {props.suppressPrompt !== true && (
        <Prompt
          when={isDirty}
          message={() =>
            "Are you sure you want leave this page? You will lose unsaved data."
          }
        />
      )}

      <FmFormContext.Provider value={fmFormRenderProps}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            doSubmit();
          }}
          style={{ height: "100%", width: "100%" }}
        >
          <Child
            fmFormRenderProps={fmFormRenderProps}
            children={props.children}
          />
        </form>
      </FmFormContext.Provider>
    </>
  );
};

const Child = (props: {
  fmFormRenderProps: FmFormRenderProps<any>;
  children: (fmFormRenderProps: FmFormRenderProps<any>) => React.ReactNode;
}) => {
  return <>{props.children(props.fmFormRenderProps)}</>;
};
