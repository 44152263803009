import { Draft } from "immer";
import * as React from "react";
import { useContext } from "react";
import { PrimaryButton } from "../components/buttons";
import { FmFormContext, FmFormRenderProps } from "./FmForm";
import {
  DragDropContext,
  DropResult,
  ResponderProvided,
  Droppable,
  Draggable,
} from "react-beautiful-dnd";
export interface FmListRenderProps<TFormData, TItem> {
  value: TItem;
  index: number;
  values: TItem[];
  sortedIndex: number;
  setFormDataValue: (value: TItem) => void;
  itemAction: (action: string) => void;
  removeItem: () => void;
  fmFormRenderProps: FmFormRenderProps<TFormData>;
}

export interface ItemActionRenderProps<TFormData, TItem> {
  action: string;
  item: TItem;
  items: TItem[];
  position: number;
  setFormData: (
    mutator: (formData: Draft<TFormData>) => void | TFormData
  ) => TFormData;
}

export interface FmListProps<TFormData, TItem> {
  name: keyof TFormData & string;
  sortComparer?: (a: TItem, b: TItem) => number;
  itemAction?: (renderProps: ItemActionRenderProps<TFormData, TItem>) => void;
  showAddButton?: boolean;
  children: (
    fmListItemProps: FmListRenderProps<TFormData, TItem>
  ) => JSX.Element;
  minItems?: number;
  maxItems?: number;
  reorder?: (
    draftFormData: Draft<TFormData>,
    originalIndex: number,
    newIndex: number
  ) => void;
  addButtonLabel?: string;
}

export const FmList = <TFormData, TItem>(
  props: FmListProps<TFormData, TItem>
) => {
  const fmFormRenderProps: FmFormRenderProps<TFormData> = useContext(
    FmFormContext
  );
  const { name, sortComparer, itemAction, minItems, maxItems } = props;
  const { formData, setFormData } = fmFormRenderProps;
  const items = (formData[name] as unknown) as TItem[];
  const unsortedValues = items.map((value, index) => {
    return { value, index };
  });
  // Sort in order rendered so that rendered component knows order
  const values = sortComparer
    ? [...unsortedValues].sort((a, b) => sortComparer(a.value, b.value))
    : unsortedValues;
  let renderItems = values.map((wrappedValue, index, wrappedValues) => {
    const fmListRenderProps: FmListRenderProps<TFormData, TItem> = {
      fmFormRenderProps,
      value: wrappedValue.value,
      index: wrappedValue.index,
      values: wrappedValues.map((wrappedValue) => wrappedValue.value),
      sortedIndex: index,
      setFormDataValue: (value: TItem) => {
        setFormData((formData) => {
          (formData as any)[name][wrappedValue.index] = value;
        });
      },
      itemAction: (action: string) => {
        if (action === "add" && items.length < (maxItems ?? 25))
          itemAction({
            action,
            item: wrappedValue.value,
            items: values.map((value) => value.value),
            position: wrappedValue.index,
            setFormData,
          });
      },
      removeItem: () => {
        if (items.length <= (minItems ?? 0)) return;
        setFormData((formData) => {
          (formData as any)[name].splice(index, 1);
        });
      },
    };
    return {
      value: wrappedValue.value,
      element: props.children(fmListRenderProps),
    };
  });

  const onDragEnd = (result: DropResult, provided: ResponderProvided) => {
    if (!result.destination) {
      return;
    }
    fmFormRenderProps.setFormData((draftFormData) => {
      props.reorder(
        draftFormData,
        result.source.index,
        result.destination.index
      );
    });
  };

  return (
    <React.Fragment>
      {props.showAddButton && (
        <PrimaryButton
          onClick={() => {
            if (items.length < (maxItems ?? 25))
              itemAction({
                action: "add",
                item: undefined, // because we are adding to top
                items: values.map((value) => value.value),
                position: -1,
                setFormData,
              });
          }}
        >
          {props.addButtonLabel ?? "Add"}
        </PrimaryButton>
      )}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={"FmList"}>
          {(providedDroppable, snapshot) => (
            <div
              ref={providedDroppable.innerRef}
              {...providedDroppable.droppableProps}
            >
              {providedDroppable.placeholder}
              {renderItems.map((renderItem, index) => (
                <Draggable
                  key={index}
                  draggableId={index.toString()}
                  isDragDisabled={props.reorder == null}
                  index={index}
                >
                  {(provided, snapshot) => {
                    return (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        {renderItem.element}
                      </div>
                    );
                  }}
                </Draggable>
              ))}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </React.Fragment>
  );
};
