import { auth as firebaseauth } from "firebase/app";
import "firebase/auth";
import * as React from "react";
import { useContext } from "react";
import { PrimaryButton } from "../../../components/buttons";
import {
  notifyError,
  notifySuccess,
} from "../../../components/NotificationManager";
import SettingsContainer from "../../../components/SettingsContainer";
import Title from "../../../components/Title";
import { FmTextField } from "../../../formManager/FmField";
import { FmForm, FmFormRenderProps } from "../../../formManager/FmForm";
import { UserContext } from "./RouterTop";

interface FormData {
  fmFormDataVersion: number; // required by FmForm
  password: string;
}

export interface ChangePasswordProps {}

export const ChangePassword = () => {
  const handleSubmit = async (
    fmFormRenderProps: FmFormRenderProps<FormData>
  ) => {
    const formData = fmFormRenderProps.formData;
    const auth = firebaseauth();
    const user = auth.currentUser;
    user
      .updatePassword(formData.password)
      .then(function () {
        notifySuccess("Your password has been changed.");
      })
      .catch(function (error) {
        notifyError(error.message);
      });
  };
  const authUserInfo = useContext(UserContext);
  const initialFormData: FormData = {
    fmFormDataVersion: 0,
    password: "",
  };
  return (
    <FmForm
      fetch={{ handler: () => Promise.resolve(initialFormData) }}
      onSubmit={handleSubmit}
    >
      {(fmProps) => (
        <SettingsContainer>
          <Title>
            Change Password for {authUserInfo.displayName} ({authUserInfo.email}
            )
          </Title>
          <FmTextField<FormData>
            name="password"
            initialFocus
            width="20rem"
            textFieldProps={{
              type: "Password",
            }}
          />
          <PrimaryButton
            type="submit"
            onClick={() => fmProps.submit()}
            disabled={fmProps.isSubmitting || fmProps.isDirty !== true}
          >
            Change password
          </PrimaryButton>
        </SettingsContainer>
      )}
    </FmForm>
  );
};
