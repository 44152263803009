import { Checkbox, CheckboxProps, Slider, Typography } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Switch, { SwitchProps } from "@material-ui/core/Switch";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import * as React from "react";
import { useContext, useEffect, useRef, useState } from "react";
import { notifyError } from "../components/NotificationManager";
import { RowContainer } from "../components/RowContainer";
import { FmFormContext, FmFormRenderProps } from "./FmForm";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    text: {
      marginTop: "8px",
      marginBottom: "8px",
    },
  });
});

const toLabelFromVariableName = (name: string) => {
  const nameArray = [...(name ?? [])];
  const labelLetters = nameArray.reduce((result, letter, index) => {
    const upcase = letter.toUpperCase();
    if (index === 0) {
      result.push(upcase);
    } else if (upcase !== letter) {
      result.push(letter);
    } else if (nameArray[index - 1] === nameArray[index - 1].toUpperCase()) {
      // Don't add a space between consecutive capitals
      result.push(letter);
    } else {
      // Add a space
      result.push(" ");
      result.push(letter);
    }
    return result;
  }, [] as string[]);
  return labelLetters.join("");
};

export const FmTextField = <TFormData,>(props: {
  name?: keyof TFormData & string;
  // valueProxyFuncs lets parent deal with state updates
  valueProxyFuncs?: {
    get: () => string;
    mutate: (value: string) => void;
  };
  error?: boolean;
  errorText?: string;
  textFieldProps?: TextFieldProps;
  initialFocus?: boolean;
  readOnly?: boolean;
  labelText?: string;
  placeholderText?: string;
  maxLength?: number;
  width?: string;
  forceToLowerCase?: boolean;
  isRequired?: boolean;
  className?: string;
  inputType?: "text" | "number" | "password";
  onChange?: (args: {
    oldFieldData: string;
    fieldData: string;
    draftFormData: TFormData;
  }) => void;
  onBlur?: (args: {
    fieldData: string;
    formData: TFormData;
    setErrorMessage: (message: string) => void;
  }) => void;
}) => {
  const {
    name,
    textFieldProps,
    labelText,
    className,
    placeholderText,
    valueProxyFuncs,
  } = props;

  if (name == null && valueProxyFuncs == null) {
    console.error("Need either name or valueProxyFuncs");
  }
  if (valueProxyFuncs && props.onChange) {
    console.error("Cannot use both onChange and valueProxyFuncs");
  }
  const label = labelText ?? toLabelFromVariableName(name as string);
  const fmFormContext = useContext(FmFormContext) as FmFormRenderProps<
    TFormData
  >;
  const { formData, setFormData } = fmFormContext;
  const ref = useRef(null);
  useEffect(() => {
    if (!props.initialFocus) return;
    const inputElement: HTMLInputElement = ref.current;
    if (inputElement) inputElement.focus();
  }, []);

  const classes = useStyles();

  const fieldData = valueProxyFuncs ? valueProxyFuncs.get() : formData[name];

  useEffect(() => {
    if (props.isRequired) {
      fmFormContext.registerValidator((formData: TFormData) => {
        const value = valueProxyFuncs ? valueProxyFuncs.get() : formData[name];
        if (typeof value === "string") {
          return value.length > 0
            ? undefined
            : `Field "${label}" requires a value.`;
        }
        console.error("Should not get here.");
      });
    }
  }, []);

  const typeProp = props.inputType ? { type: props.inputType } : {};
  const newTextFieldProps = { ...typeProp, ...textFieldProps };

  const [internalErrorMessage, setInternalErrorMessage] = useState("");

  return (
    <div
      className={className}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: props.width ?? "auto",
      }}
    >
      <TextField
        className={classes.text}
        error={(props.error ?? false) || internalErrorMessage !== ""}
        helperText={internalErrorMessage || props.errorText}
        inputRef={ref}
        variant="outlined"
        fullWidth
        name={name}
        autoComplete={name}
        value={fieldData}
        label={label}
        placeholder={placeholderText ?? label}
        margin="dense"
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          readOnly: props.readOnly ?? false,
        }}
        {...newTextFieldProps}
        onChange={(event) => {
          let textInput = props.forceToLowerCase
            ? event.currentTarget.value.toLowerCase()
            : event.currentTarget.value;
          if (textInput.length > props.maxLength ?? Number.MAX_VALUE) return;
          if (valueProxyFuncs) {
            valueProxyFuncs.mutate(textInput);
            return;
          }
          setFormData((draftFormData) => {
            const oldFieldData = ((draftFormData as any)[
              name as keyof TFormData
            ] as any) as string;

            (draftFormData as any)[name] = textInput;

            // The onChange function should change draftFormData directly.
            props.onChange &&
              props.onChange({
                oldFieldData,
                fieldData: textInput,
                draftFormData: draftFormData as TFormData,
              });
          });
          // send old value and new value
        }}
        onBlur={(event) => {
          if (!props.onBlur) return;
          props.onBlur({
            fieldData: fieldData as string,
            formData,
            setErrorMessage: setInternalErrorMessage,
          });
        }}
      />
    </div>
  );
};

const positiveIntegers = /^\d+$/;

export enum FmFieldErrorCode {
  "OverMax",
  "UnderMin",
}

export const FmNumberField = <TFormData,>(props: {
  name?: keyof TFormData & string;
  // Let parent deal with state updates
  valueProxyFuncs?: {
    get: () => number;
    mutate: (value: number) => void;
  };
  textFieldProps?: TextFieldProps;
  initialFocus?: boolean;
  labelText?: string;
  placeholderText?: string;
  maxLength?: number;
  width?: string;
  className?: string;
  inputType?: "text" | "number";
  minValue?: number;
  maxValue?: number;
  onChange?: (args: {
    oldValue: number;
    value: number;
    draftFormData: TFormData;
  }) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  stringToValue?: (stringValue: string) => number;
  valueToString?: (value: number) => string;
  disabled?: boolean;
  customErrorMessages?: Partial<Record<FmFieldErrorCode, string>>;
}) => {
  const {
    name,
    textFieldProps,
    labelText,
    className,
    placeholderText,
    valueProxyFuncs,
    onChange,
    onBlur,
    onFocus,
    customErrorMessages,
  } = props;

  if (name == null && valueProxyFuncs == null) {
    console.error("Need either name or valueProxyFuncs");
  }
  if (valueProxyFuncs && onChange) {
    console.error("Cannot use both onChange and valueProxyFuncs");
  }

  // Handling only whole numbers...need to enhance later
  const stringToValue =
    props.stringToValue ??
    ((stringValue: string) => (stringValue == null ? 0 : Number(stringValue)));
  const valueToString =
    props.valueToString ??
    ((value: number) => (value == null ? "" : value.toString()));

  const minValue = props.minValue ?? 0;
  const maxValue = props.maxValue ?? Number.MAX_VALUE;
  const maxLength = props.maxLength ?? 10;
  const label = labelText ?? toLabelFromVariableName(name as string);
  const fmFormContext = useContext(FmFormContext) as FmFormRenderProps<
    TFormData
  >;
  const { formData, setFormData } = fmFormContext;
  const [hasFocus, setHasFocus] = useState(false);
  const formDataValue = valueProxyFuncs
    ? valueProxyFuncs.get()
    : ((formData[name] as unknown) as number);
  const [stringValue, setStringValue] = useState(valueToString(formDataValue));
  const ref = useRef(null);
  useEffect(() => {
    if (!props.initialFocus) return;
    const inputElement: HTMLInputElement = ref.current;
    if (inputElement) inputElement.focus();
  }, []);

  const typeProp = props.inputType
    ? { type: props.inputType }
    : { type: "text" };
  const newTextFieldProps = { ...typeProp, ...textFieldProps };
  const fieldValue = hasFocus ? stringValue : valueToString(formDataValue);
  const handleChange = (stringValue: string) => {
    const value = stringToValue(stringValue);
    if (value === formDataValue) return; // no change
    if (value < minValue) {
      notifyError(
        customErrorMessages?.[FmFieldErrorCode.UnderMin] ??
          `The value cannot be small than ${valueToString(minValue)}`
      );
      return;
    }
    if (value > maxValue) {
      notifyError(
        customErrorMessages?.[FmFieldErrorCode.OverMax] ??
          `The value cannot be greater than ${valueToString(maxValue)}`
      );
      return;
    }
    if (valueProxyFuncs) {
      valueProxyFuncs.mutate(value);
      return;
    }

    setFormData((draftFormData) => {
      const oldFieldData = (draftFormData as unknown)[name] as number;

      (draftFormData as unknown)[name] = value;

      // The onChange function should change draftFormData directly.
      props.onChange &&
        props.onChange({
          oldValue: oldFieldData,
          value: value,
          draftFormData: draftFormData as TFormData,
        });
    });
  };
  return (
    <div
      className={className}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: props.width ?? "auto",
      }}
    >
      <TextField
        inputRef={ref}
        variant="outlined"
        fullWidth
        name={name}
        value={fieldValue}
        label={label}
        placeholder={placeholderText ?? label}
        margin="normal"
        disabled={props.disabled}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          style: { textAlign: "right" },
          onFocus: () => {
            setHasFocus(true);
            setStringValue(valueToString(formDataValue));
            onFocus && onFocus();
          },
          onBlur: () => {
            setHasFocus(false);
            handleChange(stringValue);
            onBlur && onBlur();
          },
        }}
        {...newTextFieldProps}
        onChange={(event) => {
          const textInput: string = event.target.value;

          // Only support non-negative ints for now
          if (textInput !== "" && !positiveIntegers.test(textInput)) return;
          if (textInput.length > maxLength) return;
          const value =
            textInput == null || textInput === ""
              ? 0
              : stringToValue(textInput);

          if (hasFocus) {
            setStringValue(textInput);
            return;
          }
          handleChange(textInput);
        }}
      />
    </div>
  );
};

export const FmSwitchField = <TFormData,>(props: {
  name: keyof TFormData & string;
  switchFieldProps?: SwitchProps;
  labelText?: string;
  width?: string;
  className?: string;
  onChange?: (args: { fieldData: boolean; draftFormData: TFormData }) => void;
}) => {
  const { name, labelText, className, switchFieldProps } = props;
  const label = labelText ?? toLabelFromVariableName(name as string);
  const fmFormContext = useContext(FmFormContext) as FmFormRenderProps<
    TFormData
  >;
  const { formData, setFormData } = fmFormContext;

  return (
    <FormControlLabel
      control={
        <Switch
          checked={(formData[name] as unknown) as boolean}
          name={name}
          color="primary"
          {...switchFieldProps}
          onChange={(event, checked) => {
            const newFieldData = event.currentTarget.checked;
            setFormData((draftFormData) => {
              (draftFormData as unknown)[name] = newFieldData;

              // The onChange function should change draftFormData directly.
              props.onChange &&
                props.onChange({
                  fieldData: newFieldData,
                  draftFormData: draftFormData as TFormData,
                });
            });
            // send old value and new value
          }}
        />
      }
      label={label}
    />
  );
};

export const FmCheckboxField = <TFormData,>(props: {
  name: keyof TFormData & string;
  checkboxFieldProps?: CheckboxProps;
  labelText?: string;
  width?: string;
  className?: string;
  onChange?: (args: { fieldData: boolean; draftFormData: TFormData }) => void;
}) => {
  const { name, labelText, className, checkboxFieldProps } = props;
  const label = labelText ?? toLabelFromVariableName(name as string);
  const fmFormContext = useContext(FmFormContext) as FmFormRenderProps<
    TFormData
  >;
  const { formData, setFormData } = fmFormContext;
  const value = (formData[name] as unknown) as boolean;
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={value != null}
          indeterminate={value == false}
          name={name}
          color="primary"
          {...checkboxFieldProps}
          onChange={(event, checked) => {
            // const newFieldData = event.currentTarget.checked;
            const currentValue = (formData[name] as unknown) as boolean;
            const newValue =
              currentValue === true
                ? false
                : currentValue === false
                ? undefined
                : true;
            setFormData((draftFormData) => {
              (draftFormData as unknown)[name] = newValue;
              props.onChange &&
                props.onChange({
                  fieldData: newValue,
                  draftFormData: draftFormData as TFormData,
                });
            });
          }}
        />
      }
      label={label}
    />
  );
};

export const FmDiscreteSliderField = <TFormData, TData>(props: {
  name?: keyof TFormData & string;
  // Let parent deal with state updates
  valueProxyFuncs?: {
    get: () => TData;
    mutate: (value: TData) => void;
  };
  labelText?: string;
  width?: string;
  className?: string;
  // Sliders run from 0 through 100
  // marks used to map form data values to/from slider values
  marks: { value: TData; label: string }[];
  onChange?: (args: {
    oldValue: TData;
    value: TData;
    draftFormData: TFormData;
  }) => void;
  disabled?: boolean;
}) => {
  const {
    name,
    labelText,
    className,
    valueProxyFuncs,
    onChange,
    disabled,
  } = props;

  if (name == null && valueProxyFuncs == null) {
    console.error("Need either name or valueProxyFuncs");
  }
  if (valueProxyFuncs && onChange) {
    console.error("Cannot use both onChange and valueProxyFuncs");
  }
  const scale = 100 / (props.marks.length - 1);
  const marks = props.marks.map((mark, index) => {
    return { value: index * scale, label: mark.label };
  });

  const mapIn = (formDataValue: TData) => {
    return (
      props.marks.findIndex((mark) => mark.value === formDataValue) * scale
    );
  };
  const mapOut = (scaleValue: number) => {
    return props.marks[Math.round(scaleValue / scale)].value;
  };
  const label = labelText ?? toLabelFromVariableName(name as string);
  const fmFormContext = useContext(FmFormContext) as FmFormRenderProps<
    TFormData
  >;
  const { formData, setFormData } = fmFormContext;
  const formDataValue = valueProxyFuncs
    ? valueProxyFuncs.get()
    : ((formData[name] as unknown) as TData);

  const handleChange = (value: TData) => {
    if (value === formDataValue) return; // no change
    if (valueProxyFuncs) {
      valueProxyFuncs.mutate(value);
      return;
    }

    setFormData((draftFormData) => {
      const oldFieldData = (draftFormData as unknown)[name] as TData;

      (draftFormData as unknown)[name] = value;

      // The onChange function should change draftFormData directly.
      props.onChange &&
        props.onChange({
          oldValue: oldFieldData,
          value: value,
          draftFormData: draftFormData as TFormData,
        });
    });
  };
  return (
    <div
      className={className}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: props.width ?? "auto",
      }}
    >
      <RowContainer center>
        <Typography id="slider" gutterBottom>
          {label}
        </Typography>
        <Slider
          value={mapIn(formDataValue)}
          getAriaValueText={(_value: number, index: number) => {
            return marks[index].label;
          }}
          aria-labelledby="slider"
          step={scale}
          valueLabelDisplay="off"
          disabled={disabled}
          marks={marks}
          onChange={(_event, value: number) => {
            handleChange(mapOut(value));
          }}
        />
      </RowContainer>
    </div>
  );
};
