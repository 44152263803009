import { auth as firebaseauth } from "firebase/app";
import "firebase/auth";
import * as React from "react";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { PrimaryButton } from "../../../components/buttons";
import {
  notifyError,
  notifySuccess,
} from "../../../components/NotificationManager";
import PageIntro from "../../../components/PageIntro";
import SettingsContainer from "../../../components/SettingsContainer";
import { Title } from "../../../components/Title";
import { FmTextField } from "../../../formManager/FmField";
import { FmForm, FmFormRenderProps } from "../../../formManager/FmForm";
import { UserContext } from "./RouterTop";

interface FormData {
  fmFormDataVersion: number; // required by FmForm
  newEmail: string;
}

export interface ChangeEmailProps {}

export const ChangeEmail = () => {
  const history = useHistory();
  const handleSubmit = async (
    fmFormRenderProps: FmFormRenderProps<FormData>
  ) => {
    const formData = fmFormRenderProps.formData;
    const auth = firebaseauth();
    const user = auth.currentUser;
    user
      .updateEmail(formData.newEmail)
      .then(function () {
        notifySuccess(
          "Your email address has been changed. You should receive notification at your previous email address. " +
            " For security reasons, you have been logged off so that you might test your new email address by immediately logging in.",
          () => history.push("/logoff")
        );
      })
      .catch(function (error) {
        notifyError(error.message);
      });
  };
  const authUserInfo = useContext(UserContext);
  const initialFormData: FormData = {
    fmFormDataVersion: 0,
    newEmail: authUserInfo.email ?? "",
  };
  return (
    <FmForm
      fetch={{ handler: () => Promise.resolve(initialFormData) }}
      onSubmit={handleSubmit}
    >
      {(fmProps) => (
        <SettingsContainer>
          <Title>
            Change Email for {authUserInfo.displayName} ({authUserInfo.email})
          </Title>
          <PageIntro>
            Once your email is changed, you will receive an email at your old
            email address. That email will contain a link to allow you to set
            your email back to your old address. You will also be logged off. In
            case you changed your email to the wrong address, you should
            immediately sign in again.
          </PageIntro>
          <FmTextField<FormData> name="newEmail" initialFocus width="20rem" />
          <PrimaryButton
            type="submit"
            onClick={() => fmProps.submit()}
            disabled={
              fmProps.isSubmitting ||
              fmProps.isDirty !== true ||
              fmProps.formData.newEmail === authUserInfo.email
            }
          >
            Change email
          </PrimaryButton>
        </SettingsContainer>
      )}
    </FmForm>
  );
};
