import * as React from "react";
import { ColumnContainer } from "../../../../components/ColumnContainer";
import { ConfirmationDialogBase } from "../../../../components/dialogTools/ConfirmationDialogBase";
import {
  Dialog,
  getDialogMethods,
  makeDialog,
} from "../../../../components/dialogTools/DialogManager";
import { notifyError } from "../../../../components/NotificationManager";
import SettingDescription from "../../../../components/SettingDescription";
import { FmForm } from "../../../../formManager/FmForm";
import { DatePicker } from "@material-ui/pickers";

interface FormData {
  fmFormDataVersion: number; // required by FmForm
  expiry: Date;
}

export interface SetExpiryDialogProps {
  expiry: Date;
}

export let setExpiryDialogInternal: Dialog<Date, SetExpiryDialogProps>;

export const openSetExpiryDialog = (props: SetExpiryDialogProps) => {
  if (!setExpiryDialogInternal) {
    setExpiryDialogInternal = makeSetExpiryDialogComponent();
  }
  return getDialogMethods().open(setExpiryDialogInternal, props);
};

export const makeSetExpiryDialogComponent = () => {
  return makeDialog<Date, SetExpiryDialogProps>({
    componentRenderer: (dialogRenderProps) => {
      return (
        <FmForm<FormData>
          suppressPrompt
          name="SetExpiryDialog"
          suppressSpinner
          fetch={{
            handler: () =>
              Promise.resolve({
                fmFormDataVersion: undefined,
                expiry: dialogRenderProps.props.expiry,
              }),
          }}
          onSubmit={(fmProps) => {
            const f = fmProps.formData;
            return Promise.resolve(f.expiry);
          }}
        >
          {(fmProps) => (
            <ConfirmationDialogBase
              onClose={(isOkay) => {
                if (isOkay) {
                  fmProps
                    .submit()
                    .then((response: Date) => {
                      dialogRenderProps.close(true, response);
                      return response;
                    })
                    .catch((reason) => notifyError(reason));
                } else {
                  dialogRenderProps.close(false, undefined);
                }
              }}
              open={true}
              title={"Set Expiration Date"}
              okayText={"Set Expiration Date"}
            >
              <ColumnContainer>
                <SettingDescription>
                  Enter the subscription expiration date below. If the date is
                  cleared, then the subscription will have no end date.
                </SettingDescription>
                <DatePicker
                  autoOk
                  label="Expiration Date"
                  clearable
                  format="P"
                  value={fmProps.formData.expiry}
                  onChange={(d) => {
                    fmProps.setFormData((draft) => {
                      draft.expiry = d;
                    });
                  }}
                />
              </ColumnContainer>
            </ConfirmationDialogBase>
          )}
        </FmForm>
      );
    },
  });
};
