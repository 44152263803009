import * as React from "react";
import { PageContainer } from "../../../components/PageContainer";
import SettingHeader from "../../../components/SettingHeader";
import { TemplateSettings } from "./TemplateSettings";
import { SpellIcon } from "../../../components/icons/SpellIcon";
import { RowContainer } from "../../../components/RowContainer";
import { ComputeIcon } from "../../../components/icons/ComputeIcon";
import { TwistIcon } from "../../../components/icons/TwistIcon";
import { useHelp } from "../../../components/HelpDialog";
import SettingsContainer from "../../../components/SettingsContainer";
import SettingSubheader from "../../../components/SettingSubheader";
import SettingDescription from "../../../components/SettingDescription";

export const TemplatesPage = () => {
  useHelp("TemplatesPage", <MyHelp />, true);
  return (
    <PageContainer>
      <SettingHeader>
        <RowContainer>
          <SpellIcon />
          <span style={{ marginLeft: "0.5rem" }}>Spell Template</span>
        </RowContainer>
      </SettingHeader>
      <TemplateSettings gameType={"s"} />
      <SettingHeader>
        <RowContainer>
          <ComputeIcon />
          <span style={{ marginLeft: "0.5rem" }}>Compute Template</span>
        </RowContainer>
      </SettingHeader>
      <TemplateSettings gameType={"c"} />
      <SettingHeader>
        <RowContainer>
          <TwistIcon />
          <span style={{ marginLeft: "0.5rem" }}>Twist Template</span>
        </RowContainer>
      </SettingHeader>
      <TemplateSettings gameType={"t"} />
    </PageContainer>
  );
};

const MyHelp = () => {
  return (
    <SettingsContainer>
      <SettingSubheader>Templates</SettingSubheader>
      <SettingDescription>
        The templates page shows the current (if any) template for each game
        type. When you create a new game, its configuration comes from the
        template for its game type.
      </SettingDescription>
    </SettingsContainer>
  );
};
