import { getAuthIdToken } from "../app/author/components/RouterTop";
import { ApiRequest, Payload } from "../domain/serverContract";
import { makeRequest } from "./http";

export async function makeRequestWithAuthentication<TResponse extends Payload>(
  request: ApiRequest,
  path: string
) {
  const authIdToken = await getAuthIdToken();
  const apiRequest = { authIdToken, ...request };
  return makeRequest<TResponse>(apiRequest, path);
}
