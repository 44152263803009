import { getConfiguration } from "../app/author/requests/getConfiguration";
import { GameType, getGameTypeLabel } from "./types";

export const getGameUrl = async (
  gameType: GameType,
  gameId: string,
  gameName: string
) => {
  const configuration = await getConfiguration();
  const prefix = configuration.gameUrlPattern
    ? configuration.gameUrlPattern.replace(
        "GAME",
        getGameTypeLabel(gameType).toLowerCase()
      )
    : "";
  return `${prefix}/run/${gameId}?name=${gameName}`;
};
