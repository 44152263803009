import { makeRequestWithAuthentication } from "../../../../http/authenticated";
import {
  ApiResponseHeader,
  ApiResponse,
} from "../../../../domain/serverContract";
import { User } from "../domain/User";

export interface BooleanFilter {
  value: boolean;
  isAny: boolean;
}

export interface StringFilter {
  value: string;
  isAny: boolean;
}

export interface IntFilter {
  value: number;
  isAny: boolean;
}

export const convertStringToFilter = (value: string): StringFilter => {
  return { value, isAny: value == null || value === "" };
};

export const convertBooleanToFilter = (value: boolean): BooleanFilter => {
  return { value, isAny: value == null };
};

export const convertIntToFilter = (value: number): IntFilter => {
  return { value, isAny: value == null };
};

export interface Filter {
  uid: StringFilter;
  email: StringFilter;
  emailVerified: BooleanFilter;
  displayName: StringFilter;
  admin: IntFilter;
  disabled: BooleanFilter;
}

export const clearFilter = (f: Filter) => {
  f.admin.isAny = true;
  f.displayName.isAny = true;
  f.email.isAny = true;
  f.emailVerified.isAny = true;
  f.uid.isAny = true;
  f.disabled.isAny = true;
};

export const isClear = (f: Filter) => {
  if (f == null) return true;
  return (
    f.admin.isAny &&
    f.displayName.isAny &&
    f.email.isAny &&
    f.emailVerified.isAny &&
    f.uid.isAny &&
    f.disabled.isAny
  );
};

export interface GetUsersRequest {
  filter: Filter;
}

export interface GetUsersResponse extends ApiResponseHeader {
  incomplete: boolean;
  users: User[];
}

export async function getUsers(request: GetUsersRequest) {
  return makeRequestWithAuthentication<GetUsersResponse>(
    request,
    "/api/admin/get_users"
  );
}
