import * as React from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { FmTextField } from "../../../formManager/FmField";
import { FmForm, FmFormRenderProps } from "../../../formManager/FmForm";
import { getConfiguration } from "../requests/getConfiguration";
import { registerNewUser } from "../requests/registerNewUser";
import { PrimaryButton } from "../../../components/buttons";
import ExplanatoryNote from "../../../components/ExplanatoryNote";
import { InfoDialog } from "../../../components/dialogTools/InfoDialog";
import { notifyError } from "../../../components/NotificationManager";
import { PageContainer } from "../../../components/PageContainer";
import PageIntro from "../../../components/PageIntro";
import SettingDescription from "../../../components/SettingDescription";
import { Title } from "../../../components/Title";

declare var CP_RECAPTCHAReady: boolean;
declare var grecaptcha: any;

interface FormData {
  fmFormDataVersion: number; // required by FmForm
  email: string;
  displayName: string;
  recaptchaValue: string;
  recaptchaSiteKey: string;
}

const initialFormData: FormData = {
  fmFormDataVersion: 0,
  email: "",
  displayName: "",
  recaptchaValue: "",
  recaptchaSiteKey: "",
};

interface RegisterProps {}

export const Register = () => {
  const history = useHistory();
  const handleSubmit = async (
    fmFormRenderProps: FmFormRenderProps<FormData>
  ) => {
    const { email, displayName, recaptchaValue } = fmFormRenderProps.formData;
    if ((recaptchaValue ?? "") === "") {
      notifyError("You must first complete the robot challenge.");
      return;
    }
    localStorage.setItem("emailForSignIn", email);
    return registerNewUser({
      email,
      displayName,
      recaptcha: recaptchaValue,
    }).then(() => history.push("/registrationsubmitted"));
  };
  return (
    <FmForm
      fetch={{
        handler: () =>
          getConfiguration().then((config) => {
            return {
              ...initialFormData,
              recaptchaSiteKey: config.recaptchaSiteKey,
            };
          }),
      }}
      onSubmit={handleSubmit}
      suppressPrompt
    >
      {(fmProps) => {
        const [forceUpdate, setForceUpdate] = useState(0);
        React.useEffect(() => {
          if (CP_RECAPTCHAReady == null) {
            // Wait for the recaptcha component to load
            // After we've tried a bunch of times, quit
            if (forceUpdate > 20) {
              notifyError(
                "Having trouble loading recaptcha test. There may be a network issue."
              );
              return;
            }
            setTimeout(() => setForceUpdate(forceUpdate + 1), 250);
          }
        });
        React.useEffect(() => {
          if (!CP_RECAPTCHAReady) return;
          const newDiv = document.getElementById("recaptcha_node");
          grecaptcha.render(newDiv, {
            sitekey: fmProps.formData.recaptchaSiteKey,
            callback: (value: string) => {
              fmProps.setFormData((draft) => {
                draft.recaptchaValue = value;
              });
            },
          });
        }, [CP_RECAPTCHAReady]);

        return (
          <PageContainer center>
            <Title>Register</Title>
            <PageIntro>
              Registration is easy. Provide your email and name. We'll send you
              an email with a confirmation link. Once you click the confirmation
              link and create a password, you will be registered -- and your
              free trial will start.
            </PageIntro>
            <ExplanatoryNote>
              If you don't see the email in your inbox, check your spam folder.
            </ExplanatoryNote>
            <FmTextField<FormData>
              name="email"
              initialFocus
              isRequired
              width="25rem"
              textFieldProps={{
                InputProps: {
                  spellCheck: false,
                },
              }}
            />
            <SettingDescription>
              The display name is used to address you within the application,
              and in emails.
            </SettingDescription>
            <FmTextField<FormData>
              name="displayName"
              isRequired
              width="25rem"
              textFieldProps={{
                InputProps: {
                  spellCheck: false,
                },
              }}
            />

            <div id="recaptcha_node"></div>
            <PrimaryButton
              type="submit"
              onClick={() => fmProps.submit()}
              disabled={fmProps.isSubmitting}
            >
              Send Registration Email
            </PrimaryButton>
          </PageContainer>
        );
      }}
    </FmForm>
  );
};

export const RegistrationSubmitted = () => {
  return (
    <PageContainer center>
      <Title>Almost Registered!</Title>
      <SettingDescription>
        Please check your inbox (and spam folder) for your registration email.
        Until you click the link in the registration email and complete the
        registration process, there will be nothing more to do in this app.
      </SettingDescription>
    </PageContainer>
  );
};
