import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import * as React from "react";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    container: {
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "column",
      margin: "0.5rem 1rem 0rem 1rem",
    },
  });
});
interface PageContainerProps {
  center?: boolean;
}

export const PageContainer: React.FunctionComponent<PageContainerProps> = (
  props
) => {
  const classes = useStyles();
  return (
    <div
      className={classes.container}
      style={{
        justifyContent: props.center ? "center" : "normal",
        alignItems: props.center ? "center" : "normal",
      }}
    >
      {props.children}
    </div>
  );
};
