import * as React from "react";
import { useEffect, useState } from "react";
import { busyPromise } from "../../../../../components/BusySpinner";
import { PrimaryButton, SubtleButton } from "../../../../../components/buttons";
import ExplanatoryNote from "../../../../../components/ExplanatoryNote";
import Hider from "../../../../../components/Hider";
import {
  notifyError,
  notifySuccess,
} from "../../../../../components/NotificationManager";
import { PageContainer } from "../../../../../components/PageContainer";
import PageIntro from "../../../../../components/PageIntro";
import { RowContainer } from "../../../../../components/RowContainer";
import { TellMeMore } from "../../../../../components/TellMeMore";
import { getGameUrl } from "../../../../../domain/gameUrl";
import { GameType } from "../../../../../domain/types";
import { publishGame } from "../../../requests/publishGame";
import SettingHeader from "../../../../../components/SettingHeader";
import SettingSubheader from "../../../../../components/SettingSubheader";
import SettingDescription from "../../../../../components/SettingDescription";
import { useHelp } from "../../../../../components/HelpDialog";
import { CommonFormData } from "../editorTypes";

export const handlePublish = (
  isDirty: boolean,
  gameId: string,
  gameType: GameType,
  setIsPublished: () => void
) => {
  if (isDirty) {
    notifyError("Please save your changes before publishing.");
    return;
  }
  busyPromise(publishGame({ id: gameId, gameType }))
    .then((r) => {
      setIsPublished();
      notifySuccess("Your game is published.");
    })
    .catch((error) => {
      notifyError(error.message);
    });
};

const EditPublishTabInternal = (props: {
  isDirty: boolean;
  isSubmitting: boolean;
  gameId: string;
  gameName: string;
  gameType: GameType;
  isActive: boolean;
  formData: CommonFormData;
  setIsPublished: () => void;
}) => {
  useHelp("EditPublishTab", <MyHelp />, props.isActive);
  const { isDirty, isSubmitting, gameId, gameName, formData } = props;
  const [gameUrl, setGameUrl] = useState("");

  useEffect(() => {
    getGameUrl(props.gameType, gameId, gameName).then((url) => setGameUrl(url));
  }, [gameName]);

  return (
    <Hider hidden={!props.isActive}>
      <PageContainer center>
        <PageIntro>
          When you press Publish your game will become available to your users,
          replacing any previously published version. To play a game after
          publishing, press the PLAY GAME button below.
        </PageIntro>
        <PrimaryButton
          disabled={isSubmitting}
          onClick={() =>
            handlePublish(isDirty, gameId, props.gameType, props.setIsPublished)
          }
        >
          Publish
        </PrimaryButton>
        <ExplanatoryNote>
          Once your game is published, the game URL will be{" "}
          <b style={{ wordBreak: "break-all" }}>{gameUrl}</b>. Share the game
          URL with everyone who will play the game.
        </ExplanatoryNote>
        <RowContainer center>
          <SubtleButton
            onClick={() => {
              navigator.clipboard.writeText(gameUrl);
              notifySuccess("Copied");
            }}
          >
            Copy game URL to clipboard
          </SubtleButton>
          <SubtleButton
            onClick={() => {
              if (!formData.isPublished) {
                notifyError("Publish the game before playing.");
                return;
              }
              if (isDirty) {
                notifyError("Save the game before playing.");
                return;
              }
              window.open(gameUrl);
            }}
          >
            Play game
          </SubtleButton>
          <TellMeMore>
            You may have noticed that the game name is part of the URL. If you
            change the name of the game, the game URL will still work, even with
            the original URL (with an incorrect name). The name is present so
            that part of the URL is human-readable. The computer ignores that
            part.
          </TellMeMore>
        </RowContainer>
      </PageContainer>
    </Hider>
  );
};

export const EditPublishTab = React.memo(
  EditPublishTabInternal,
  (prevProps, nextProps) => {
    if (prevProps.isActive !== nextProps.isActive) return false;
    if (prevProps.gameId !== nextProps.gameId) return false;
    if (prevProps.gameName !== nextProps.gameName) return false;
    if (prevProps.isDirty !== nextProps.isDirty) return false;
    if (prevProps.isSubmitting !== nextProps.isSubmitting) return false;
    if (prevProps.formData.isPublished !== nextProps.formData.isPublished)
      return false;
    return true;
  }
);

const MyHelp = () => {
  return (
    <>
      <SettingHeader>Publish</SettingHeader>
      <SettingSubheader>How Can I Test a Game?</SettingSubheader>
      <SettingDescription>
        First, publish it. Then click PLAY GAME.
      </SettingDescription>
      <SettingSubheader>
        How Can I Allow Others to Play a Game?
      </SettingSubheader>
      <SettingDescription>
        First, click COPY GAME URL TO CLIPBOARD. Then share the URL by posting
        it on a website, send it in email, etc.
      </SettingDescription>
      <SettingSubheader>
        How Can I Prevent Others from Playing a Published Game?
      </SettingSubheader>
      <SettingDescription>
        You'll need to delete the game, which puts it in the trash. When you
        delete it from the trash, it will be permanently deleted. No one will be
        able to play the game or see game progress. Permanent deletion is not
        reversible.
      </SettingDescription>
    </>
  );
};
