import { AutoScoringMethod } from "../../../../domain/types";
import { FormLevel } from "./editorTypes";
import { round } from "../../../../utilities";

let memokey_deriveLevelScores: string;
export const clearLevelScoresCache = () => {
  memokey_deriveLevelScores = undefined;
};
export const deriveLevelScores = (dependencies: {
  levels: FormLevel[]; // this is mutated, but is also a dependency
  highScore: number;
  autoScoring: boolean;
  autoScoringMethod: AutoScoringMethod;
}) => {
  const { autoScoring, highScore, levels } = dependencies;
  if (!autoScoring) return; // no need to mutate
  const memokey = JSON.stringify(dependencies);
  if (memokey === memokey_deriveLevelScores) return;
  memokey_deriveLevelScores = memokey;
  levels[0].score = 0;
  const highIndex = levels.length - 1;
  if (highIndex < 1) return;
  levels[highIndex].score = highScore;
  if (highIndex < 2) return;
  switch (dependencies.autoScoringMethod) {
    case AutoScoringMethod.Medium:
      const deltaLinear = highScore / highIndex;
      for (let index = 1; index < highIndex; index++) {
        levels[index].score = round(index * deltaLinear);
      }
      break;
    case AutoScoringMethod.Slow:
      const secondScore = highScore / 3;
      levels[1].score = round(secondScore);
      if (highIndex < 3) return;
      const deltaHard = secondScore / (highIndex - 1);
      for (let index = 2; index < highIndex; index++) {
        levels[index].score = round(index * deltaHard);
      }
      break;
    case AutoScoringMethod.Fast:
    default:
      const penultimateScore = (highScore * 2) / 3;
      levels[highIndex - 1].score = round(penultimateScore);
      if (highIndex < 3) return;
      const delta = penultimateScore / (highIndex - 1);
      for (let index = 1; index < highIndex - 1; index++) {
        levels[index].score = round(index * delta);
      }
      break;
  }
};
