import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import MenuIcon from "@material-ui/icons/Menu";
import * as React from "react";

interface MainNavigationMenuIconProps {
  onClick?: () => void;
  edge?: "start" | "end" | false;
}

export const MainNavigationMenuIcon: React.FunctionComponent<MainNavigationMenuIconProps> = (
  props
) => {
  return (
    <Tooltip title="Navigation menu" aria-label="Navigation menu">
      <IconButton
        style={{ paddingTop: 0, paddingBottom: 0 }}
        edge={props.edge ?? false}
        color="inherit"
        aria-label="menu"
        aria-controls="menu-appbar"
        onClick={props.onClick}
      >
        <MenuIcon />
      </IconButton>
    </Tooltip>
  );
};
