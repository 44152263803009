import {
  ApiRequest,
  ValidatedApiResponse,
} from "../../../domain/serverContract";
import { makeCacheValidatedRequest } from "./makeCacheValidatedRequest";
import { deleteAbstract } from "./manageGameAbstracts";

export interface DeleteGameRequest extends ApiRequest {
  id: string;
}

export interface DeleteGameResponse extends ValidatedApiResponse {}

export async function deleteGame(
  request: DeleteGameRequest
): Promise<DeleteGameResponse> {
  return makeCacheValidatedRequest<DeleteGameResponse>(
    request,
    "/api/delete_game"
  ).then((response) => {
    deleteAbstract(request.id, response.timestamp);
    return response;
  });
}
