import { IconButton, InputAdornment, TextField } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/HighlightOff";
import * as React from "react";
import { useEffect, useRef } from "react";
import { FmForm, FmFormRenderProps } from "../../../formManager/FmForm";
import { useFormToolbar } from "../../../formManager/useFormToolbar";
import { openAddWordsDialog } from "../games/spell/components/AddWordsDialog";
import { VirtualizedWordList } from "../games/spell/components/VirtualizedWordList";
import { isWordInDictionary } from "../games/spell/wordFinder";
import { useFreshState } from "../../../hooks/useFreshState";
import {
  saveUserGameSettings,
  userGameSettings,
} from "../requests/manageUserGameSettings";
import {
  intersection,
  makeSeparatedStringFromList,
  union,
} from "../../../utilities";
import { SecondaryButton, SubtleButton } from "../../../components/buttons";
import { FormToolbarEditorButtons } from "./FormToolbarEditorButtons";
import PageIntro from "../../../components/PageIntro";
import { RowContainer } from "../../../components/RowContainer";
import SettingDescription from "../../../components/SettingDescription";
import SettingsContainer from "../../../components/SettingsContainer";
import { TellMeMore } from "../../../components/TellMeMore";
import { ToolbarTitle } from "../../../components/ToolbarTitle";
import { notifySuccess } from "../../../components/NotificationManager";

export const validateWords = (
  words: string[],
  theseWords: string[],
  theseName: string,
  otherWords: string[],
  otherName: string
) => {
  const alreadyFound = intersection(words, theseWords);
  if (alreadyFound.length > 0) {
    return (
      "These words are duplicates of words already in your " +
      theseName +
      " words list: " +
      makeSeparatedStringFromList(alreadyFound)
    );
  }
  const hidden = intersection(words, otherWords);
  if (hidden.length > 0) {
    return (
      "These words are duplicates of words already in your " +
      otherName +
      " words list, and therefore would cause them to be ignored: " +
      makeSeparatedStringFromList(hidden)
    );
  }
  if (theseName == "extra") {
    const inDictionary = words.filter((word) => {
      return isWordInDictionary(word);
    });
    if (inDictionary.length > 0) {
      return (
        "These words are already in the dictionary, and cannot be added to your extra words list: " +
        makeSeparatedStringFromList(inDictionary)
      );
    }
  }
};

export interface FormData {
  fmFormDataVersion: number; // required by FmForm
  extraWords: string[]; // need for validation when adding words
  hiddenWords: string[];
}

export interface HiddenWordsPageProps {
  setToolbar: (element: JSX.Element) => void;
}
export const HiddenWordsPage = (props: HiddenWordsPageProps) => {
  const handleSubmit = async (
    fmFormRenderProps: FmFormRenderProps<FormData>
  ) => {
    const formData = fmFormRenderProps.formData;
    return saveUserGameSettings((userGameSettings) => {
      userGameSettings.hiddenWords = formData.hiddenWords;
    });
  };

  return (
    <FmForm
      fetch={{
        handler: async () => {
          const settings = await userGameSettings();
          return {
            fmFormDataVersion: undefined,
            extraWords: settings.extraWords,
            hiddenWords: settings.hiddenWords,
          };
        },
      }}
      onSubmit={handleSubmit}
    >
      {(fmFormRenderProps) => {
        return (
          <RenderedFormChild
            fmFormRenderProps={fmFormRenderProps}
            setToolbar={props.setToolbar}
          />
        );
      }}
    </FmForm>
  );
};

const RenderedFormChild = (props: {
  fmFormRenderProps: FmFormRenderProps<FormData>;
  setToolbar: (element: JSX.Element) => void;
}) => {
  const fmFormRenderProps = props.fmFormRenderProps;
  const hiddenWords = fmFormRenderProps.formData.hiddenWords;
  const [getFilter, setFilter] = useFreshState(() => "");
  const ref = useRef(null);
  useEffect(() => {
    const inputElement: HTMLInputElement = ref.current;
    if (inputElement) inputElement.focus();
  }, []);
  const filteredWords = fmFormRenderProps.formData.hiddenWords
    .filter((word) => word.indexOf(getFilter()) >= 0)
    .sort();

  useFormToolbar(() => {
    props.setToolbar(
      <RowContainer>
        <ToolbarTitle>Settings: Hidden Words</ToolbarTitle>
        <FormToolbarEditorButtons fmFormRenderProps={fmFormRenderProps} />
      </RowContainer>
    );
  });

  return (
    <SettingsContainer>
      <PageIntro>
        The words in this list will be hidden when finding game words in the
        built-in dictionary. You can manually add hidden words to individual
        games.
        <TellMeMore>
          The built-in dictionary contains many obscure or technical words that
          may not be appropriate for your audience. When searching, a word is
          found only if that word is
          <ul>
            <li>
              in the built-in dictionary and is not excluded by virtue of being
              on the built-in sensitive word list
            </li>
            <li>OR is in your extra words list (on this page)</li>
            <li>AND is not in your hidden words list.</li>
          </ul>
          You won't be able to add words that are in your extra words list
          because that would cause those extra words to be ignored. First remove
          them from your extra words list, then add them to your hidden words
          list.
        </TellMeMore>
        <p>
          To share with others:
          <SubtleButton
            onClick={() => {
              navigator.clipboard.writeText(
                makeSeparatedStringFromList(filteredWords)
              );
              notifySuccess("Copied");
            }}
          >
            Copy words to clipboard
          </SubtleButton>
        </p>
      </PageIntro>
      <RowContainer>
        <SecondaryButton
          onClick={() =>
            openAddWordsDialog({
              title: "Add Hidden Words",
              submitHandler: (words) => {
                const errorMessage = validateWords(
                  words,
                  [], //  don't send hidden words -- prevents rejection for dups
                  "hidden",
                  fmFormRenderProps.formData.extraWords,
                  "extra"
                );
                if (errorMessage) return Promise.reject(errorMessage);
                fmFormRenderProps.setFormData((draftFormData) => {
                  draftFormData.hiddenWords = union(hiddenWords, words);
                  draftFormData.hiddenWords.sort((a, b) =>
                    a < b ? -1 : a > b ? 1 : 0
                  );
                });
                return Promise.resolve();
              },
            })
          }
        >
          Add Words
        </SecondaryButton>

        <SecondaryButton
          disabled={filteredWords.length === 0}
          onClick={() => {
            fmFormRenderProps.setFormData((draftFormData) => {
              filteredWords.forEach((filteredWord) => {
                const index = draftFormData.hiddenWords.indexOf(filteredWord);
                draftFormData.hiddenWords.splice(index, 1);
              });
            });
          }}
        >
          Delete {filteredWords.length} Word
          {`${filteredWords.length !== 1 ? "s" : ""} Below`}
        </SecondaryButton>
      </RowContainer>
      <SettingDescription>
        As you type into the word filter, you will see only matching words. If
        you use the DELETE button it will delete only the matching words.
      </SettingDescription>
      <div style={{ width: "20rem" }}>
        <TextField
          inputRef={ref}
          variant="outlined"
          value={getFilter()}
          label="Word Filter"
          placeholder="Type to search for words below"
          margin="normal"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  size="small"
                  onClick={() => {
                    setFilter("");
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </InputAdornment>
            ),
            style: { maxWidth: "20rem" },
          }}
          onChange={(event) => {
            const ftext: string = event.currentTarget.value;
            setFilter(ftext);
          }}
        />
      </div>

      <div style={{ width: "15rem", flexGrow: 1 }}>
        <VirtualizedWordList
          words={filteredWords}
          removeWord={(word) => {
            // So we also need to update form data -- which is also what dirties the page.
            fmFormRenderProps.setFormData((draftFormData) => {
              const delIndex = draftFormData.hiddenWords.indexOf(word);
              draftFormData.hiddenWords.splice(delIndex, 1);
            });
          }}
        />
      </div>
    </SettingsContainer>
  );
};
