import {
  ApiRequest,
  ValidatedApiResponse,
} from "../../../domain/serverContract";
import { makeCacheValidatedRequest } from "./makeCacheValidatedRequest";
import { insertAbstract } from "./manageGameAbstracts";
import { GameType } from "../../../domain/types";

export interface CreateGameRequest extends ApiRequest {
  name: string;
  templateGameRef?: string;
  gameType: GameType;
}

export interface CreateGameResponse extends ValidatedApiResponse {
  id: string;
  title: string;
  subtitle: string;
  description: string;
}

export async function createGame(
  request: CreateGameRequest
): Promise<CreateGameResponse> {
  return makeCacheValidatedRequest<CreateGameResponse>(
    request,
    "/api/create_game"
  ).then((response) => {
    // Create abstract
    insertAbstract({
      gameType: request.gameType,
      gameId: response.id,
      timestamp: response.timestamp,
      name: request.name,
      title: response.title,
      subtitle: response.subtitle,
      description: response.description,
    });
    return response as CreateGameResponse;
  });
}
