import * as React from "react";
import { ColumnContainer } from "../../../../../components/ColumnContainer";
import Hider from "../../../../../components/Hider";
import SettingDescription from "../../../../../components/SettingDescription";
import SettingSubheader from "../../../../../components/SettingSubheader";
import { TellMeMore } from "../../../../../components/TellMeMore";
import { Tip } from "../../../../../components/Tip";
import { FmTextField } from "../../../../../formManager/FmField";
import { sum } from "../../../../../utilities";
import {
  getCanReuseLetters,
  getMinLength,
  getPangramCount,
} from "../../../../spell/components/SpellGameComponent";
import { SpellRulesComponent } from "../../../../spell/components/SpellRulesComponent";
import { SpellPlaySpec } from "../spellTypes";
import { FormData } from "./SpellEditPage";

export const SpellEditRulesPrologueTab = (props: {
  rulesPrologue: string;
  isActive: boolean;
  playSpec: SpellPlaySpec;
}) => {
  return (
    <Hider hidden={!props.isActive}>
      <ColumnContainer center>
        <Tip>
          Most rules are generated automatically. Read them below before writing
          a prologue.
        </Tip>
        <SettingDescription>
          The rules prologue will be the first information displayed when the
          user sees the rules. It might briefly describe the theme of the game,
          and special rules that are not generated automatically. For example,
          consider stating whether or not there are any proper nouns.
          <TellMeMore>
            Automatically generated rules include:
            <ul>
              <li>whether or not letters are re-used</li>
              <li>the length of the shortest word</li>
              <li>the number of pangrams</li>
              <li>the total number of words and points</li>
              <li>the points required to reach each level</li>
            </ul>
          </TellMeMore>
        </SettingDescription>
        <div>
          <FmTextField<FormData>
            name="rulesPrologue"
            width="70vw"
            textFieldProps={{ multiline: true, rows: 6 }}
          />
        </div>

        <SettingSubheader>Rules</SettingSubheader>
        <SettingDescription>
          Below are the rules, inluding the prologue (if any) above.
        </SettingDescription>
        <SpellRulesComponent
          playSpec={props.playSpec}
          gameInfo={{
            maxPoints: sum(
              props.playSpec.answers.map((answer) => answer.score)
            ),
            highestLevel:
              props.playSpec.levels[props.playSpec.levels.length - 1],
            pangramCount: getPangramCount(props.playSpec),
            minLength: getMinLength(props.playSpec),
            canReuseLetters: getCanReuseLetters(props.playSpec),
          }}
        />
      </ColumnContainer>
    </Hider>
  );
};
