import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import HomeIcon from "@material-ui/icons/Home";
import GamesIcon from "@material-ui/icons/List";
import SubscriptionIcon from "@material-ui/icons/MonetizationOn";
import ProfileIcon from "@material-ui/icons/Person";
import SettingsIcon from "@material-ui/icons/Settings";
import { auth as firebaseauth } from "firebase/app";
import "firebase/auth";
import * as React from "react";
import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { useFreshState } from "../../../hooks/useFreshState";
import { useUserProfile } from "../requests/manageUserProfile";
import { SubtleButton } from "../../../components/buttons";
import { DialogManagerContext } from "../../../components/dialogTools/DialogManager";
import { openHelpDialog } from "../../../components/HelpDialog";
import Hider from "../../../components/Hider";
import { MainNavigationMenuIcon } from "../../../components/MainNavigationMenuIcon";
import { UserContext } from "./RouterTop";
import { openUserInfoDialog } from "./UserInfoDialog";
import { openVersionDialog } from "../../../components/VersionDialog";

export interface TopAppBarProps {
  setToggleNavMenu: (f: () => void) => void;
  isAdmin: boolean;
}
const drawerWidth = 240;
const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    title: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 10,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      zIndex: theme.zIndex.drawer + 9,
    },
    drawerPaper: {
      width: drawerWidth,
      zIndex: theme.zIndex.drawer + 9,
      // position: "static",
    },
    drawerContainer: {
      overflow: "auto",
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
    // Loads information about the app bar, including app bar height
    toolbar: theme.mixins.toolbar,
  });
});

export const TopAppBar: React.FunctionComponent<TopAppBarProps> = (props) => {
  const [getOpenDrawer, setOpenDrawer] = useFreshState(() => false);
  const [ignoreExternalClickOnce, setIgnoreExternalClickOnce] = useState(false);
  const classes = useStyles();
  const toggleDrawer = () => {
    if (getOpenDrawer() === false) {
      setIgnoreExternalClickOnce(true);
      setOpenDrawer(true);
    }
  };

  useState(() => props.setToggleNavMenu(toggleDrawer));
  const authUserInfo = useContext(UserContext);
  const history = useHistory();
  const dialogMethods = useContext(DialogManagerContext);
  const up = useUserProfile();
  const initials = up.initials;
  return (
    <>
      <AppBar className={classes.appBar} position="fixed">
        <Toolbar>
          <MainNavigationMenuIcon onClick={toggleDrawer} edge="start" />
          <Typography variant="h6" className={classes.title}>
            <div
              onClick={() => history.push("/home")}
              style={{ cursor: "pointer" }}
            >
              Cortex Author
            </div>
          </Typography>
          <SubtleButton color="inherit" onClick={() => openHelpDialog({})}>
            Help
          </SubtleButton>
          {firebaseauth().currentUser ? (
            <Button
              color="inherit"
              onClick={() => openUserInfoDialog({ authUserInfo })}
            >
              <Avatar alt={authUserInfo.displayName}>{initials}</Avatar>
            </Button>
          ) : (
            <SubtleButton
              color="inherit"
              onClick={() => history.push("/login")}
            >
              Login
            </SubtleButton>
          )}
        </Toolbar>
      </AppBar>
      <ClickAwayListener
        onClickAway={() => {
          if (getOpenDrawer() === false) return;
          if (ignoreExternalClickOnce) {
            setIgnoreExternalClickOnce(false);
          } else {
            setOpenDrawer(false);
          }
        }}
      >
        <Drawer
          className={classes.drawer}
          variant="persistent"
          classes={{
            paper: classes.drawerPaper,
          }}
          open={getOpenDrawer()}
        >
          <Toolbar />
          <div
            className={classes.drawerContainer}
            onClick={() => setOpenDrawer(false)}
            onKeyDown={() => setOpenDrawer(false)}
          >
            <List>
              <ListItem
                button
                onClick={() => {
                  history.push("/home");
                }}
              >
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
              </ListItem>
              <ListItem
                button
                onClick={() => {
                  history.push("/games");
                }}
              >
                <ListItemIcon>
                  <GamesIcon />
                </ListItemIcon>
                <ListItemText primary="Games" />
              </ListItem>
              <ListItem
                button
                onClick={() => {
                  history.push("/settings");
                }}
              >
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Settings" />
              </ListItem>
            </List>
            <Divider />
            <List>
              <ListItem button onClick={() => history.push("/subscription")}>
                <ListItemIcon>
                  <SubscriptionIcon />
                </ListItemIcon>
                <ListItemText primary="Subscription" />
              </ListItem>
              <ListItem button onClick={() => history.push("/profile")}>
                <ListItemIcon>
                  <ProfileIcon />
                </ListItemIcon>
                <ListItemText primary="Profile" />
              </ListItem>
              <ListItem
                button
                onClick={() => {
                  openVersionDialog();
                }}
              >
                <ListItemIcon></ListItemIcon>
                <ListItemText primary="Version" />
              </ListItem>
            </List>
            <ListItem button onClick={() => history.push("/privacy")}>
              <ListItemText primary="Privacy" />
            </ListItem>
            <ListItem button onClick={() => history.push("/terms")}>
              <ListItemText primary="Terms" />
            </ListItem>
            <Divider />
            <Hider hidden={!props.isAdmin}>
              <List>
                <ListItem
                  button
                  onClick={() => {
                    history.push("/admin");
                  }}
                >
                  <ListItemText primary="Admin" />
                </ListItem>
              </List>
            </Hider>
          </div>
        </Drawer>
      </ClickAwayListener>
      <div className={classes.toolbar} />
    </>
  );
};
