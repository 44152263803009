import * as React from "react";
import { ColumnContainer } from "../../../../components/ColumnContainer";
import { ConfirmationDialogBase } from "../../../../components/dialogTools/ConfirmationDialogBase";
import {
  Dialog,
  getDialogMethods,
  makeDialog,
} from "../../../../components/dialogTools/DialogManager";
import { notifyError } from "../../../../components/NotificationManager";
import SettingDescription from "../../../../components/SettingDescription";
import { FmTextField } from "../../../../formManager/FmField";
import { FmForm } from "../../../../formManager/FmForm";

interface FormData {
  fmFormDataVersion: number; // required by FmForm
  confirmationText: string;
}

export interface ConfirmImpactfulActionDialogProps {
  confirmationText: string;
  dialogTitleAndOkayText: string;
  dialogContentRenderer: (confirmationText: string) => string | JSX.Element;
}

export let ConfirmImpactfulActionDialogInternal: Dialog<
  void,
  ConfirmImpactfulActionDialogProps
>;

export const openConfirmImpactfulActionDialog = (
  props: ConfirmImpactfulActionDialogProps
) => {
  if (!ConfirmImpactfulActionDialogInternal) {
    ConfirmImpactfulActionDialogInternal = makeConfirmImpactfulActionDialogComponent();
  }
  return getDialogMethods().open(ConfirmImpactfulActionDialogInternal, props);
};

export const makeConfirmImpactfulActionDialogComponent = () => {
  return makeDialog<void, ConfirmImpactfulActionDialogProps>({
    componentRenderer: (dialogRenderProps) => {
      const props = dialogRenderProps.props;
      return (
        <FmForm<FormData>
          suppressPrompt
          name="ConfirmImpactfulActionDialog"
          suppressSpinner
          fetch={{
            handler: () =>
              Promise.resolve({
                fmFormDataVersion: undefined,
                confirmationText: "",
              }),
          }}
          onSubmit={() => {
            return Promise.resolve();
          }}
        >
          {(fmProps) => (
            <ConfirmationDialogBase
              onClose={(isOkay) => {
                if (isOkay) {
                  fmProps
                    .submit()
                    .then((response: Date) => {
                      dialogRenderProps.close(true);
                      return response;
                    })
                    .catch((reason) => notifyError(reason));
                } else {
                  dialogRenderProps.close(false);
                }
              }}
              open={true}
              title={props.dialogTitleAndOkayText}
              okayText={props.dialogTitleAndOkayText}
            >
              <ColumnContainer>
                <SettingDescription>
                  {props.dialogContentRenderer(props.confirmationText)}
                </SettingDescription>
                <FmTextField name="confirmationText"></FmTextField>
              </ColumnContainer>
            </ConfirmationDialogBase>
          )}
        </FmForm>
      );
    },
  });
};
