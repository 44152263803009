import { ApiResponseHeader } from "../../../../domain/serverContract";
import { makeRequestWithAuthentication } from "../../../../http/authenticated";

export interface DeleteUserRequest {
  tuid: string;
}

export interface DeleteUserResponse extends ApiResponseHeader {}

export async function deleteUser(
  request: DeleteUserRequest
): Promise<DeleteUserResponse> {
  return makeRequestWithAuthentication(request, "/api/admin/delete_user");
}
