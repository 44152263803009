import { useContext, useEffect } from "react";
import { FmFormContext } from "./FmForm";

export const useFormToolbar = (setFormToolbar: () => void) => {
  const fmFormContext = useContext(FmFormContext);
  useEffect(() => {
    setFormToolbar();
  }, [
    fmFormContext.isSubmitting,
    fmFormContext.formData,
    fmFormContext.isDirty,
  ]);
};
