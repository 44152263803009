import * as React from "react";
import { FixedSizeList as List } from "react-window";
import DeleteIcon from "@material-ui/icons/HighlightOff";
import { IconButton, makeStyles, Theme, createStyles } from "@material-ui/core";
import AutoSizer from "react-virtualized-auto-sizer";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    title: {
      flexGrow: 1,
    },
    divider: {
      width: "100%",
      marginBottom: "0.25rem",
    },
    rulesPrologue: {
      width: "70%",
      padding: "0.5rem",
    },
    bonus: {
      marginLeft: "0.5rem",
      marginRight: "0.5rem",
    },
    tabName: {
      marginBottom: "1rem",
    },
    info: {
      padding: "0.5rem",
      margin: "0.75rem",
    },
  });
});

export interface VirtualizedWordListProps {
  words: string[];
  removeWord: (words: string) => void;
}

const rowRenderer = (args: {
  index: number;
  style: React.CSSProperties;
  words: string[];
  removeWord: (words: string) => void;
}) => {
  return (
    <div style={args.style}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          backgroundColor: "lightgray",
          borderRadius: "5px",
        }}
      >
        <div style={{ marginLeft: "1rem" }}>{args.words[args.index]}</div>
        <IconButton
          size="small"
          style={{ marginLeft: "auto" }}
          onClick={() => args.removeWord(args.words[args.index])}
        >
          <DeleteIcon />
        </IconButton>
      </div>
    </div>
  );
};

const getItemKey = (index: number, data: string[]) => {
  return data[index];
};

export const VirtualizedWordList = (props: VirtualizedWordListProps) => {
  const { words } = props;
  const rowFunc = (args: { index: number; style: React.CSSProperties }) =>
    rowRenderer({
      index: args.index,
      style: args.style,
      words,
      removeWord: props.removeWord,
    });
  return (
    <AutoSizer>
      {({ height, width }) => {
        return (
          <List
            height={height}
            itemCount={words.length}
            itemSize={35}
            width={width}
            itemKey={getItemKey}
            itemData={words}
          >
            {rowFunc}
          </List>
        );
      }}
    </AutoSizer>
  );
};
