import * as React from "react";
import SettingsContainer from "../../../components/SettingsContainer";
import { PageContainer } from "../../../components/PageContainer";
import Title from "../../../components/Title";
import SettingHeader from "../../../components/SettingHeader";
import SettingDescription from "../../../components/SettingDescription";

export const Privacy = () => {
  return (
    <PageContainer>
      <Title>Privacy Policy</Title>
      <SettingsContainer>
        This privacy policy describes what we do with your data when you use
        Cortex Author ("the App"). It is effective as of 13 August 2020.
        Questions about this policy should be emailed to privacy @
        cortexplay.com.
        <ul>
          <li>
            We ask for your name, email address, and initials for the following
            purposes:
            <ul>
              <li>
                We ask for your email address in order to keep track of your
                authored games for you, and to communicate with you about your
                account. We may also let you know about changes to the App, and
                we may send you marketing email.
              </li>
              <li>
                We ask for your name and initials in order to address you
                politely.
              </li>
            </ul>
          </li>
          <li>
            We do not share your email address, name, or initials -- or any
            other information that might be personally identifiable -- with any
            other organization.
          </li>
          <li>
            We collect information that helps us provide a reliable service,
            such as the type of device and browser you use, and the IP address
            from which you access the App.
          </li>
          <li>
            We do collect information that helps us improve the quality of the
            App, such as which features you use.
          </li>
        </ul>
      </SettingsContainer>
      <SettingHeader>Data</SettingHeader>
      <SettingDescription>
        The App data is processed on your devices and on the Google Cloud in the
        United States. The Google Cloud is the only data processor. If you
        cancel your subscription, then we may retain your data for a short
        period of time (a few weeks or less) in case you decided to reactivate
        your subscription. After that period of time, we will purge all of your
        data except for data related to subscription payments and offline
        backups.
      </SettingDescription>
    </PageContainer>
  );
};
