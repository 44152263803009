import { makeRequestWithAuthentication } from "../../../http/authenticated";
import { ApiRequest, ApiResponse } from "../../../domain/serverContract";

export interface GetSubscriptionRequest extends ApiRequest {}

export interface GetSubscriptionResponse extends ApiResponse {
  expiry: Date; // undefined if no expiration date
}
export async function getSubscription(
  request: GetSubscriptionRequest
): Promise<GetSubscriptionResponse> {
  return makeRequestWithAuthentication<GetSubscriptionResponse>(
    request,
    "/api/get_subscription"
  );
}
