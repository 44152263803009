import Grid from "@material-ui/core/Grid";
import * as React from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { FmForm, FmFormRenderProps } from "../../../formManager/FmForm";
import {
  userGameSettings,
  saveUserGameSettings,
} from "../requests/manageUserGameSettings";
import { useFormToolbar } from "../../../formManager/useFormToolbar";
import ToolbarTitle from "../../../components/ToolbarTitle";
import { FormToolbarEditorButtons } from "./FormToolbarEditorButtons";
import SettingsContainer from "../../../components/SettingsContainer";
import SettingHeader from "../../../components/SettingHeader";
import SettingDescription from "../../../components/SettingDescription";
import Emphasize from "../../../components/Emphasize";
import { TellMeMore } from "../../../components/TellMeMore";
import { FmSwitchField } from "../../../formManager/FmField";

interface FormData {
  fmFormDataVersion: number; // required by FmForm
  includeSensitiveWords: boolean;
}

export interface SensitiveWordsPageProps {
  setToolbar: (element: JSX.Element) => void;
}
export const SensitiveWordsPage = (props: SensitiveWordsPageProps) => {
  const [templateName, setTemplateName] = useState(undefined);
  return (
    <FmForm
      fetch={{
        handler: async () => {
          const settings = await userGameSettings();
          return {
            fmFormDataVersion: undefined,
            includeSensitiveWords: settings.includeSensitiveWords,
          };
        },
      }}
      onSubmit={async (fmFormRenderProps: FmFormRenderProps<FormData>) => {
        const formData = fmFormRenderProps.formData;
        return saveUserGameSettings((userGameSettings) => {
          userGameSettings.includeSensitiveWords =
            formData.includeSensitiveWords;
        });
      }}
    >
      {(fmFormRenderProps) => {
        return (
          <RenderedFormChild
            fmFormRenderProps={fmFormRenderProps}
            templateName={templateName}
            setToolbar={props.setToolbar}
          />
        );
      }}
    </FmForm>
  );
};

const RenderedFormChild = (props: {
  fmFormRenderProps: FmFormRenderProps<FormData>;
  setToolbar: (element: JSX.Element) => void;
  templateName: string;
}) => {
  const { fmFormRenderProps, templateName, setToolbar } = props;
  const formData = fmFormRenderProps.formData;
  const history = useHistory();
  useFormToolbar(() => {
    setToolbar(
      <Grid container alignItems="center">
        <ToolbarTitle>Settings: Sensitive Words</ToolbarTitle>
        <FormToolbarEditorButtons fmFormRenderProps={fmFormRenderProps} />
      </Grid>
    );
  });

  return (
    <SettingsContainer>
      <SettingHeader>Sensitive Words</SettingHeader>
      <SettingDescription>
        A list of sensitive words is built into this game, along with a
        dictionary. Unless you include sensitive words, these sensitive words
        will not be found when you find words.
        <Emphasize>
          Regardless of this setting, it is YOUR responsibility to determine
          which words are appropriate for your games.
        </Emphasize>
        Even if this setting is off, any word in your extra words list will
        still be found. And you may enter sensitive words manually when creating
        games.
        <TellMeMore>
          We may modify the list of sensitive words at any time. Our intention
          is to try to add words that are overtly obscene, sexist, or racist.
          Generally, words that have meanings which are both sensitive and not
          sensitive are NOT placed on the sensitive words list.
        </TellMeMore>
      </SettingDescription>
      <FmSwitchField name="includeSensitiveWords" />
    </SettingsContainer>
  );
};
