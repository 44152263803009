import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import { red } from "@material-ui/core/colors";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { MildGuidingButton } from "../../../components/buttons";
import { LibraryIcon } from "../../../components/icons/LibraryIcon";
import { RowContainer } from "../../../components/RowContainer";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    minHeight: 175,
    margin: "1rem",
  },
  header: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  media: {
    height: "100%",
    backgroundSize: "contain",
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export function HomeLibraryGamesCard() {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Card className={classes.root}>
      <CardHeader
        className={classes.header}
        avatar={
          <IconButton
            aria-label="games"
            onClick={() => history.push("/games/library")}
          >
            <LibraryIcon fontSize="large" />
          </IconButton>
        }
        title="Library Games"
      />
      <CardActions>
        <RowContainer center>
          <MildGuidingButton onClick={() => history.push("/games/library")}>
            See Library Games
          </MildGuidingButton>
        </RowContainer>
      </CardActions>
      <Divider variant="middle" style={{ marginTop: "1rem" }} />
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
          The library has many games available for you to quickly import,
          customize, and use.
        </Typography>
      </CardContent>
    </Card>
  );
}
