import {
  ApiRequest,
  ValidatedApiResponse,
} from "../../../domain/serverContract";
import { GameSpec } from "../../../domain/types";
import { makeCacheValidatedRequest } from "./makeCacheValidatedRequest";
import { updateAbstract } from "./manageGameAbstracts";

export interface SaveGameRequest extends ApiRequest {
  id: string;
  gameSpec: GameSpec;
  name: string;
}

export interface SaveGameResponse extends ValidatedApiResponse {
  id: string;
}

export async function saveGame(
  request: SaveGameRequest
): Promise<SaveGameResponse> {
  return makeCacheValidatedRequest<SaveGameResponse>(
    request,
    "/api/save_game"
  ).then((response) => {
    updateAbstract(request.id, (abstract) => {
      abstract.modified = response.timestamp;
      if (request.name) {
        // Name is changed only if supplied in request.
        abstract.name = request.name;
      }
      abstract.title = request.gameSpec.playSpec.title;
      abstract.subtitle = request.gameSpec.playSpec.subtitle;
      abstract.description = request.gameSpec.authorSpec.description;
    });
    return response;
  });
}
