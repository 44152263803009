import Box from "@material-ui/core/Box";
import * as React from "react";
import { FmFormRenderProps } from "../../../formManager/FmForm";
import { PrimaryButton } from "../../../components/buttons";
import UndoIcon from "@material-ui/icons/Undo";
import RedoIcon from "@material-ui/icons/Redo";
import { Tooltip } from "@material-ui/core";

export const FormToolbarEditorButtons = (props: {
  fmFormRenderProps: FmFormRenderProps<unknown>;
}) => {
  const fmFormRenderProps = props.fmFormRenderProps;
  return (
    <>
      <PrimaryButton
        type="submit"
        onClick={() => fmFormRenderProps.submit()}
        disabled={
          fmFormRenderProps.isSubmitting || fmFormRenderProps.isDirty !== true
        }
      >
        Save
      </PrimaryButton>
      <Box marginLeft={0.5} />
      <Tooltip title="Undo">
        <div>
          <PrimaryButton
            onClick={() => fmFormRenderProps.undo()}
            disabled={
              fmFormRenderProps.isSubmitting ||
              !fmFormRenderProps.isUndoAvailable
            }
          >
            <UndoIcon />
          </PrimaryButton>
        </div>
      </Tooltip>
      <Box marginLeft={0.5} />
      <Tooltip title="Redo">
        <div>
          <PrimaryButton
            onClick={() => fmFormRenderProps.redo()}
            disabled={
              fmFormRenderProps.isSubmitting ||
              !fmFormRenderProps.isRedoAvailable
            }
          >
            <RedoIcon />
          </PrimaryButton>{" "}
        </div>
      </Tooltip>
    </>
  );
};
