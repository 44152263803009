import { makeRequestWithAuthentication } from "../../../http/authenticated";
import {
  GameAbstract,
  GameAbstractsRecord,
  ValidatedApiResponse,
} from "../../../domain/serverContract";
import { GameType } from "../../../domain/types";

export interface GetGameAbstractsRecordRequest {}

export interface GetGameAbstractsRecordResponse extends ValidatedApiResponse {
  gameAbstractsRecord: GameAbstractsRecord;
}

async function getGameAbstractsRecord(): Promise<
  GetGameAbstractsRecordResponse
> {
  return makeRequestWithAuthentication({}, "/api/get_game_abstracts_record");
}

// versionChangeHandler returns the new version
interface versionChangeHandler {
  (version: string): void;
}

let registrationId = -1;
const registrations: Record<number, versionChangeHandler> = Object.create(null);
const unregister = (regId: number) => delete registrations[regId];
export const registerTimestampChangeHandler = (
  handler: versionChangeHandler
) => {
  registrations[++registrationId] = handler;
  return () => unregister(registrationId);
};

let gameAbstractsRecordCache: GameAbstractsRecord;
export const bustGameAbstractsRecordCache = () => {
  promiseCache = undefined;
};

export const getAbstractsVersion = () => gameAbstractsRecordCache?.version;

export const updateAbstractsVersion = (version: string) => {
  gameAbstractsRecordCache.version = version;
};

export const notifyHandlers = () => {
  Object.values(registrations).forEach((handler) =>
    handler(gameAbstractsRecordCache.version)
  );
};

let promiseCache: Promise<GameAbstractsRecord>;

export const gameAbstractsRecord = async () => {
  if (promiseCache) return promiseCache;

  promiseCache = getGameAbstractsRecord()
    .then((response) => {
      if (response.gameAbstractsRecord.gameAbstracts == null) {
        response.gameAbstractsRecord.gameAbstracts = {};
      }
      // Denormalize game ID from map key
      response.gameAbstractsRecord.gameAbstracts &&
        Object.keys(response.gameAbstractsRecord.gameAbstracts).map((key) => {
          response.gameAbstractsRecord.gameAbstracts[key].id = key;
        });
      gameAbstractsRecordCache = response.gameAbstractsRecord;
      gameAbstractsRecordCache.version = response.version;
      // The cache is capture via closure, so when we update the cache we update the promise result.
      return gameAbstractsRecordCache;
    })
    .catch((error) => {
      promiseCache = undefined;
      throw error;
    });
  return promiseCache;
};

//
// Shadow database operations
//

export const insertAbstract = (args: {
  gameType: GameType;
  gameId: string;
  name: string;
  title: string;
  subtitle: string;
  description: string;
  timestamp: Date;
}) => {
  const abstract: GameAbstract = {
    trashed: false,
    deleted: false,
    gameType: args.gameType,
    id: args.gameId,
    name: args.name,
    title: args.title,
    subtitle: args.subtitle,
    description: args.description,
    created: args.timestamp,
    modified: args.timestamp,
    published: undefined,
    plays: undefined,
    template: false,
  };
  gameAbstractsRecordCache.gameAbstracts[args.gameId] = abstract;
  // promiseCache = Promise.resolve(gameAbstractsRecordCache);
  notifyHandlers();
};

export const updateAbstract = (
  gameId: string,
  mutator: (gameAbstract: GameAbstract) => void
) => {
  const abstract = gameAbstractsRecordCache.gameAbstracts[gameId];
  if (abstract == null) throw new Error("No abstract for gameId " + gameId);
  const { created } = abstract;
  mutator(abstract);
  if (abstract.modified == null) throw new Error("modified cannot be null");
  if (abstract.created !== created) {
    throw new Error("created cannot be changed");
  }
  //promiseCache = Promise.resolve(gameAbstractsRecordCache);
  notifyHandlers();
};

export const trashAbstract = (gameId: string, timestamp: Date) => {
  const abstract = gameAbstractsRecordCache.gameAbstracts[gameId];
  if (abstract == null) throw new Error("No abstract for gameId " + gameId);
  if (timestamp == null) throw new Error("timestamp cannot be null");
  if (timestamp < abstract.modified) {
    throw new Error("timestamp must be later than previous modified time");
  }
  abstract.trashed = true;
  abstract.modified = timestamp;
  //promiseCache = Promise.resolve(gameAbstractsRecordCache);
  notifyHandlers();
};

export const untrashAbstract = (
  gameId: string,
  timestamp: Date,
  name: string
) => {
  const abstract = gameAbstractsRecordCache.gameAbstracts[gameId];
  if (abstract == null) throw new Error("No abstract for gameId " + gameId);
  if (timestamp == null) throw new Error("timestamp cannot be null");
  if (timestamp < abstract.modified) {
    throw new Error("timestamp must be later than previous modified time");
  }
  abstract.trashed = false;
  abstract.modified = timestamp;
  abstract.name = name;
  //promiseCache = Promise.resolve(gameAbstractsRecordCache);
  notifyHandlers();
};

export const deleteAbstract = (gameId: string, timestamp: Date) => {
  const abstract = gameAbstractsRecordCache.gameAbstracts[gameId];
  if (abstract == null) throw new Error("No abstract for gameId " + gameId);
  if (timestamp == null) throw new Error("timestamp cannot be null");
  if (timestamp < abstract.modified) {
    throw new Error("timestamp must be later than previous modified time");
  }
  abstract.deleted = true;
  //promiseCache = Promise.resolve(gameAbstractsRecordCache);
  notifyHandlers();
};

export const deleteTrashedAbstracts = () => {
  const abstracts = gameAbstractsRecordCache.gameAbstracts;
  Object.values(abstracts)
    .filter((abstract) => abstract.trashed)
    .forEach((abstract) => (abstract.deleted = true));
  //promiseCache = Promise.resolve(gameAbstractsRecordCache);
  notifyHandlers();
};

// finds untrashed games only
const findGameAbstractByName = (name: string) =>
  Object.values(gameAbstractsRecordCache.gameAbstracts).find(
    (abstract) => abstract.name === name && abstract.trashed !== true
  );

export const getUniqueName = (baseName: string) => {
  let name = baseName;
  let index = 0;
  while (true) {
    if (!findGameAbstractByName(name)) return name;
    index++;
    name = baseName + "-undeleted-" + index;
  }
};
