import Grid from "@material-ui/core/Grid";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { ConfirmationDialogBase } from "../../../../../components/dialogTools/ConfirmationDialogBase";
import { makeDialog } from "../../../../../components/dialogTools/DialogManager";
import { notifyError } from "../../../../../components/NotificationManager";
import SettingDescription from "../../../../../components/SettingDescription";
import { FmForm, FmFormRenderProps } from "../../../../../formManager/FmForm";
import { emptyTrash, EmptyTrashResponse } from "../../../requests/emptyTrash";
import { gameAbstractsRecord } from "../../../requests/manageGameAbstracts";

interface FormData {
  fmFormDataVersion: number; // required by FmForm
  trashCount: number;
}
export interface EmptyTrashDialogProps {}

export const makeEmptyTrashDialog = () =>
  makeDialog<unknown, EmptyTrashDialogProps>({
    name: "EmptyTrashDialog",
    componentRenderer: (dialogRenderProps) => {
      const handleSubmit = async (
        fmFormRenderProps: FmFormRenderProps<FormData>
      ) => {
        const formData = fmFormRenderProps.formData;
        return emptyTrash({ gameType: "s" });
      };

      const history = useHistory();
      return (
        <FmForm
          name="EmptyTrashDialog"
          fetch={{
            handler: async () => {
              const record = await gameAbstractsRecord();
              return {
                fmFormDataVersion: undefined,
                trashCount: Object.keys(record.gameAbstracts).length,
              };
            },
          }}
          onSubmit={handleSubmit}
        >
          {(fmProps) => {
            return (
              <ConfirmationDialogBase
                onClose={(isOkay) => {
                  if (isOkay) {
                    // Try to create the game
                    return fmProps
                      .submit()
                      .then((response: EmptyTrashResponse) => {
                        dialogRenderProps.close(true, undefined);
                        return response;
                      })
                      .catch((error: Error) => {
                        notifyError(error.message);
                      });
                  } else {
                    dialogRenderProps.close(false, undefined);
                  }
                }}
                open={true}
                title={"Empty Trash"}
                okayText="Permanently Empty Trash"
              >
                <Grid container direction="column" alignItems="center">
                  <SettingDescription>
                    There are {fmProps.formData.trashCount} games in the trash.
                    If you empty the trash, then the games will be permanently
                    deleted. In addition, they will be "unpublished", which
                    means that they will be unplayable and all shared progress
                    will be deleted.
                  </SettingDescription>
                </Grid>
              </ConfirmationDialogBase>
            );
          }}
        </FmForm>
      );
    },
  });
