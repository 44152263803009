import { Toolbar } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import NavigateBefore from "@material-ui/icons/NavigateBefore";
import NavigateNext from "@material-ui/icons/NavigateNext";
import * as React from "react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  MildGuidingButton,
  StrongGuidingButton,
} from "../../../../../components/buttons";
import { ColumnContainer } from "../../../../../components/ColumnContainer";
import { FormToolbarEditorButtons } from "../../../components/FormToolbarEditorButtons";
import Hider from "../../../../../components/Hider";
import { RowContainer } from "../../../../../components/RowContainer";
import { Title } from "../../../../../components/Title";
import ToolbarTitle from "../../../../../components/ToolbarTitle";
import { GameAbstract } from "../../../../../domain/serverContract";
import { FmForm, FmFormRenderProps } from "../../../../../formManager/FmForm";
import { useFormToolbar } from "../../../../../formManager/useFormToolbar";
import { getGame } from "../../../requests/getGame";
import { saveGame } from "../../../requests/saveGame";
import { max, pickout, round, serialId, sum } from "../../../../../utilities";
import { EditNameGameTab } from "../../shared/components/EditNameGameTab";
import { EditPublishTab } from "../../shared/components/EditPublishTab";
import { CommonFormData, FormLevel } from "../../shared/editorTypes";
import {
  Answer,
  SpellAuthorSpec,
  SpellPlaySpec,
  SpellRecord,
} from "../spellTypes";
import { isPangram } from "../wordFinder";
import { SpellEditPageChooseWordsTab } from "./SpellEditPageChooseWordsTab";
import { SpellEditRulesPrologueTab } from "./SpellEditRulesPrologueTab";
import { SpellEditScoringTab } from "./SpellEditScoringTab";
import { AutoScoringMethod, GameType } from "../../../../../domain/types";
import {
  clearLevelScoresCache,
  deriveLevelScores,
} from "../../shared/deriveLevelScores";
import { FormToolbarPublishButton } from "../../../components/FormToolbarPublishButton";

const GAMETYPE: GameType = "s";

interface FoundWord {
  word: string;
  isSpecial: boolean;
}

export interface FormData extends CommonFormData {
  letters: string;
  requiredLetters: string;
  answers: Answer[];
  pangramBonus: number;
  longestWordBonus: number;
  specialWordBonus: number;
  minimumWordLength: number;
  foundWords: FoundWord[];
}

const tabNames = [
  "Name Game",
  "Choose Words",
  "Create Scoring (optional)",
  "Write Rules (optional)",
  "Publish",
];

const tabNavNames = [
  "Name Game",
  "Choose Words",
  "Create Scoring",
  "Write Rules",
  "Publish",
];
export interface SpellEditPageProps {
  setToolbar: (element: JSX.Element) => void;
  gameAbstract: GameAbstract;
}

// Build gamePlaySpec from formdata
const buildPlaySpec = (formData: FormData) => {
  const spellPlaySpec: SpellPlaySpec = {
    answerKey: formData.answerKey,
    answers: formData.answers,
    levels: formData.levels,
    optionalLetters: pickout(formData.requiredLetters, formData.letters),
    requiredLetters: formData.requiredLetters,
    rulesPrologue: formData.rulesPrologue,
    subtitle: formData.subtitle,
    title: formData.title,
  };
  return spellPlaySpec;
};

export const SpellEditPage = (props: SpellEditPageProps) => {
  const history = useHistory();
  const handleSubmit = async (
    fmFormRenderProps: FmFormRenderProps<FormData>
  ) => {
    const formData = fmFormRenderProps.formData;
    if (formData.isPublished && formData.foundWords.length === 0) {
      throw new Error("You cannot save a published game with no words.");
    }
    const spellPlaySpec = buildPlaySpec(formData);
    const spellAuthorSpec: SpellAuthorSpec = {
      longestWordBonus: formData.longestWordBonus,
      specialWordBonus: formData.specialWordBonus,
      pangramBonus: formData.pangramBonus,
      minimumWordLength: formData.minimumWordLength,
      autoScoring: formData.autoScoring,
      autoScoringMethod: formData.autoScoringMethod,
      description: formData.description,
    };
    return saveGame({
      id: formData.id,
      gameSpec: { playSpec: spellPlaySpec, authorSpec: spellAuthorSpec },
      name:
        formData.name === fmFormRenderProps.cleanFormData.name
          ? undefined
          : formData.name,
    });
  };

  const getGameFromAbstract = async (
    gameAbstract: GameAbstract
  ): Promise<FormData> => {
    const gameId = gameAbstract.id;
    return getGame({ id: gameId }).then((result) => {
      // blow away memo cache
      memokey_deriveAnswers = undefined;
      clearLevelScoresCache();

      const gameRecord = result.gameRecord as SpellRecord;
      const { draft } = gameRecord;
      const name = gameAbstract.name;
      let initialLevels: FormLevel[] = [
        { name: "Novice", score: 0, id: serialId() },
        { name: "Spectacular", score: 1, id: serialId() },
      ];

      const commonFormData: CommonFormData = {
        fmFormDataVersion: 0,
        id: gameId,
        name,
        answerKey: draft.playSpec.answerKey ?? "",
        levels:
          draft.playSpec.levels?.map((level) => {
            return {
              name: level.name,
              score: level.score ?? 0,
              id: serialId(),
            };
          }) ?? initialLevels,
        rulesPrologue: draft.playSpec.rulesPrologue ?? "",
        subtitle: draft.playSpec.subtitle ?? "",
        title: draft.playSpec.title ?? "",
        autoScoring: draft.authorSpec.autoScoring ?? true,
        autoScoringMethod:
          draft.authorSpec.autoScoringMethod ?? AutoScoringMethod.Fast,
        description: draft.authorSpec.description ?? "",
        isTemplateGame: gameAbstract.template,
        isPublished: !!gameAbstract.published,
      };

      const formData: FormData = {
        ...commonFormData,
        requiredLetters: draft.playSpec.requiredLetters ?? "",
        letters:
          (draft.playSpec.requiredLetters ?? "") +
          (draft.playSpec.optionalLetters ?? ""),
        longestWordBonus: draft.authorSpec.longestWordBonus ?? 5,
        pangramBonus: draft.authorSpec.pangramBonus ?? 5,
        specialWordBonus: draft.authorSpec.specialWordBonus ?? 10,
        minimumWordLength: draft.authorSpec.minimumWordLength ?? 4,
        answers: draft.playSpec.answers,
        foundWords:
          draft.playSpec.answers?.map((answer) => {
            return { word: answer.word, isSpecial: answer.isSpecial };
          }) ?? [],
      };

      const highScore = formData.answers
        ? sum(formData.answers.map((answer) => answer.score))
        : 0;
      deriveLevelScores({
        levels: formData.levels,
        highScore,
        autoScoring: formData.autoScoring,
        autoScoringMethod: formData.autoScoringMethod,
      });

      const {
        foundWords,
        letters,
        pangramBonus,
        specialWordBonus,
        longestWordBonus,
      } = formData;
      deriveAnswers(formData, letters, {
        foundWords,
        pangramBonus,
        specialWordBonus,
        longestWordBonus,
      });

      return formData;
    });
  };
  return (
    <FmForm
      name="SpellEditPage"
      fetch={{ handler: () => getGameFromAbstract(props.gameAbstract) }}
      onSubmit={handleSubmit}
    >
      {(fmFormRenderProps) => {
        return (
          <>
            <RenderedFormChild
              fmFormRenderProps={fmFormRenderProps}
              setToolbar={props.setToolbar}
            />
          </>
        );
      }}
    </FmForm>
  );
};

const RenderedFormChild = (props: {
  fmFormRenderProps: FmFormRenderProps<FormData>;
  setToolbar: (element: JSX.Element) => void;
}) => {
  const fmFormRenderProps = props.fmFormRenderProps;
  const { formData, setFormData } = fmFormRenderProps;
  const [displayTabIndex, setDisplayTabIndex] = useState(0);
  useEffect(() => {
    // Do all derivations in the same place for now
    // If they were to independently update formData, then both updates would be based on the same initial
    // state of formData, and the second change would clobber the first.
    fmFormRenderProps.setDerivedFormData((draftFormData) => {
      deriveLevelScores({
        levels: draftFormData.levels,
        highScore: formData.answers
          ? sum(formData.answers.map((answer) => answer.score))
          : 0,
        autoScoring: formData.autoScoring,
        autoScoringMethod: formData.autoScoringMethod,
      });

      const {
        foundWords,
        letters,
        pangramBonus,
        specialWordBonus,
        longestWordBonus,
      } = formData;
      deriveAnswers(draftFormData, letters, {
        foundWords,
        pangramBonus,
        specialWordBonus,
        longestWordBonus,
      });
    });
  });

  useFormToolbar(() => {
    props.setToolbar(
      <Toolbar>
        <ToolbarTitle>
          <ColumnContainer>
            <span>Edit Spell</span>
            <span style={{ fontSize: "60%" }}> {formData.name}</span>
          </ColumnContainer>
        </ToolbarTitle>
        <FormToolbarPublishButton
          fmFormRenderProps={fmFormRenderProps}
          gameId={formData.id}
          gameType={GAMETYPE}
        />
        <FormToolbarEditorButtons fmFormRenderProps={fmFormRenderProps} />
      </Toolbar>
    );
  });
  return (
    <Container>
      <RowContainer>
        <div style={{ marginRight: "auto" }}>
          <Hider hidden={displayTabIndex === 0}>
            <MildGuidingButton
              aria-label="previous"
              onClick={() =>
                setDisplayTabIndex((index) => (index === 0 ? 0 : index - 1))
              }
            >
              <NavigateBefore />
              {displayTabIndex === 0 ? "" : tabNavNames[displayTabIndex - 1]}
            </MildGuidingButton>
          </Hider>
        </div>
        <div style={{ justifySelf: "center", marginLeft: "0.5rem" }}>
          <Hider hidden={displayTabIndex === tabNames.length - 1}>
            <StrongGuidingButton
              aria-label="next"
              onClick={() =>
                setDisplayTabIndex((index) =>
                  index > tabNames.length - 1 ? tabNames.length - 1 : index + 1
                )
              }
            >
              {displayTabIndex === tabNames.length - 1
                ? ""
                : tabNavNames[displayTabIndex + 1]}
              <NavigateNext />
            </StrongGuidingButton>
          </Hider>
        </div>
      </RowContainer>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Title>{tabNames[displayTabIndex]}</Title>
      </div>

      <EditNameGameTab
        isActive={0 === displayTabIndex}
        formData={formData}
        gameType={GAMETYPE}
        showAnswerKey
      />

      <SpellEditPageChooseWordsTab
        isActive={1 === displayTabIndex}
        formData={formData}
        setFormData={setFormData}
      />

      <SpellEditScoringTab
        isActive={2 === displayTabIndex}
        formData={formData}
      />

      <SpellEditRulesPrologueTab
        isActive={3 === displayTabIndex}
        rulesPrologue={formData.rulesPrologue}
        playSpec={buildPlaySpec(formData)}
      />

      <EditPublishTab
        isActive={4 === displayTabIndex}
        isDirty={fmFormRenderProps.isDirty}
        isSubmitting={fmFormRenderProps.isSubmitting}
        gameId={formData.id}
        gameName={formData.name}
        formData={formData}
        setIsPublished={() =>
          fmFormRenderProps.setDerivedFormData((draftDerivedFormData) => {
            draftDerivedFormData.isPublished = true;
          })
        }
        gameType={GAMETYPE}
      />
    </Container>
  );
};

// Derive functions
// These functions are used to modify FormData in response to changes in other FormData elements.
// They should be used when data is loaded to directly set derived data.
// This keeps the dirty flag from being set at the start.
// Use "memoization" to avoid mutating the state when nothing has changed.
// In this case, memoization means remembering if we've already mutated the data.
// Cannot rely in props to useEffect, since useEffect will always run at least once.
// We clear the memo cache whenever we fetch new data.
// Otherwise, we might not mutate it with the derived values.
// 2020-06-26 As of now we don't depended on checking unmutated formData to test for dirty.
// We user version numbers.
// So we could allow derived data to mutate after the fetched data is loaded.
// Leave for now in case we want to revert.
// The memoization is useful for performance.

// This does the auto scoring
// It mutates levels, so it can be used to mutate form data inside produce.

// Derive answers
let memokey_deriveAnswers: string;
const deriveAnswers = (
  formData: FormData, // this is mutated, and is not a dependency
  letters: string, // changing letters clears foundWords, so not needed as a dependency
  dependencies: {
    foundWords: FoundWord[];
    pangramBonus: number;
    specialWordBonus: number;
    longestWordBonus: number;
  }
) => {
  const memokey = JSON.stringify({
    dependencies,
  });
  if (memokey === memokey_deriveAnswers) return;
  memokey_deriveAnswers = memokey;
  const {
    foundWords,
    longestWordBonus,
    pangramBonus,
    specialWordBonus,
  } = dependencies;
  const greatestLength = max(foundWords.map((item) => item.word.length));
  const allLetters = [...([...(letters ?? [])] ?? [])];
  formData.answers = foundWords.map((foundWord) => {
    const { word, isSpecial } = foundWord;
    let s = word.length;
    s += isPangram(word, allLetters) ? pangramBonus : 0;
    s += isSpecial ? specialWordBonus : 0;
    s += word.length === greatestLength ? longestWordBonus : 0;
    return { word: foundWord.word, score: s, isSpecial };
  });
};
