import { Divider } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { SubtleButton } from "../../../components/buttons";
import { ColumnContainer } from "../../../components/ColumnContainer";
import {
  Dialog,
  getDialogMethods,
  makeDialog,
} from "../../../components/dialogTools/DialogManager";
import { InfoDialogBase } from "../../../components/dialogTools/InfoDialog";
import { PageContainer } from "../../../components/PageContainer";
import { useUserProfile } from "../requests/manageUserProfile";
import { AuthUserInfo } from "./RouterTop";

export interface UserInfoDialogProps {
  authUserInfo: AuthUserInfo;
}

export let userInfoDialogInternal: Dialog<string[], UserInfoDialogProps>;

export const openUserInfoDialog = (props: UserInfoDialogProps) => {
  if (!userInfoDialogInternal) {
    userInfoDialogInternal = makeUserInfoDialog();
  }
  return getDialogMethods().open(userInfoDialogInternal, props);
};

export const makeUserInfoDialog = () => {
  return makeDialog<string[], UserInfoDialogProps>({
    componentRenderer: (dialogRenderProps) => {
      const history = useHistory();
      const userProfileData = useUserProfile();
      return (
        <InfoDialogBase open onClose={dialogRenderProps.close} title="">
          <PageContainer>
            <ColumnContainer center>
              <Avatar alt={userProfileData.displayName}>
                {userProfileData.initials}
              </Avatar>
              <div>{dialogRenderProps.props.authUserInfo.email}</div>
              <div>{userProfileData.displayName}</div>
              <div>{userProfileData.initials}</div>
            </ColumnContainer>
            <Divider variant="middle" />
            <ColumnContainer>
              <SubtleButton
                onClick={() => {
                  history.push("/profile");
                  dialogRenderProps.close(true, undefined);
                }}
              >
                Edit Profile
              </SubtleButton>
              <SubtleButton
                onClick={() => {
                  history.push("/changeemail");
                  dialogRenderProps.close(true, undefined);
                }}
              >
                Change email
              </SubtleButton>
              <SubtleButton
                onClick={() => {
                  history.push("/changepassword");
                  dialogRenderProps.close(true, undefined);
                }}
              >
                Change password
              </SubtleButton>
              <SubtleButton
                onClick={() => {
                  history.push("/logoff");
                  dialogRenderProps.close(true, undefined);
                }}
              >
                Logoff
              </SubtleButton>
            </ColumnContainer>{" "}
          </PageContainer>
        </InfoDialogBase>
      );
    },
  });
};
