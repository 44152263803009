import Paper from "@material-ui/core/Paper";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import * as React from "react";

export interface TipProps {
  children: JSX.Element;
}

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    tip: {
      margin: "0.25rem",
      padding: "0.25rem",
      background: theme.palette.secondary.light,
    },
  });
});

export const Tip: React.FunctionComponent = (props: TipProps) => {
  const classes = useStyles();
  return <Paper className={classes.tip}>Tip: {props.children}</Paper>;
};
