import Button from "@material-ui/core/Button";
import ImportIcon from "@material-ui/icons/CloudDownload";
import * as React from "react";
import Tooltip from "@material-ui/core/Tooltip";

interface ImportGameButtonProps {
  onClick?: () => void;
}

export const ImportGameButton: React.FunctionComponent<ImportGameButtonProps> = (
  props
) => {
  return (
    <Tooltip
      title="Import from another author"
      aria-label="Import from another author"
    >
      <Button
        color="inherit"
        startIcon={<ImportIcon />}
        onClick={props.onClick}
      >
        Import Game
      </Button>
    </Tooltip>
  );
};
