import * as React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    paper: {
      margin: "0.5rem",
      padding: "0.5rem",
      fontSize: theme.typography.body1.fontSize,
    },
  });
});
export interface InfoProps {}

export const Info: React.FunctionComponent<InfoProps> = (props) => {
  const classes = useStyles();
  return <Paper className={classes.paper}>{props.children}</Paper>;
};
