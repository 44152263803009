import { ApiResponseHeader } from "../../../domain/serverContract";
import { makeCacheValidatedRequest } from "./makeCacheValidatedRequest";
import { updateAbstract } from "./manageGameAbstracts";

export interface TemplateGameRequest {
  id: string;
  template: boolean;
}

export interface TemplateGameResponse extends ApiResponseHeader {}

// templateGame makes this game the template for its game type (or clears)
export async function templateGame(request: TemplateGameRequest) {
  return makeCacheValidatedRequest(request, "/api/template_game").then(
    (response) => {
      updateAbstract(request.id, (abstract) => {
        abstract.template = request.template;
      });
      return response;
    }
  );
}
