import MoreVertIcon from "@material-ui/icons/MoreVert";
import MaterialTable from "material-table";
import * as React from "react";
import { useEffect } from "react";
import { SubtleButton } from "../../../components/buttons";
import { useHelp } from "../../../components/HelpDialog";
import SettingDescription from "../../../components/SettingDescription";
import SettingsContainer from "../../../components/SettingsContainer";
import SettingSubheader from "../../../components/SettingSubheader";
import { getGameUrl } from "../../../domain/gameUrl";
import { GameAbstract } from "../../../domain/serverContract";
import {
  getGameTypeIcon,
  getGameTypeLabel,
  GameType,
} from "../../../domain/types";
import { FmForm, FmFormRenderProps } from "../../../formManager/FmForm";
import { makeEmptyTrashDialog } from "../games/shared/components/makeEmptyTrashDialog";
import {
  getAbstractsVersion,
  registerTimestampChangeHandler,
} from "../requests/manageGameAbstracts";
import { libraryAbstractsRecord } from "../requests/manageLibraryAbstracts";
import { openAddGameDialog } from "./AddGameDialog";
import Tooltip from "@material-ui/core/Tooltip";
import { FormControl, makeStyles, Select, MenuItem } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: "6rem",
    margin: "0.5rem",
  },
}));

export interface LibraryPageProps {}

type GameTypeFilter = GameType | "any";

interface FormData {
  fmFormDataVersion: number; // required by FmForm
  gameAbstractsVersion: string;
  gameAbstracts: GameAbstract[];
  gameTypeFilter: GameTypeFilter;
}

const fetchHandler = async (): Promise<FormData> => {
  const record = await libraryAbstractsRecord();
  const gameAbstracts = record.gameAbstracts
    ? Object.values(record.gameAbstracts)
    : [];
  return {
    fmFormDataVersion: undefined,
    gameAbstractsVersion: getAbstractsVersion(),
    gameTypeFilter: "any",
    // Copy abstracts.
    // immer will make the props read only when setting form data.
    // managerGameAbstract doesn't use immer to mutate, so it's updates would fail.
    gameAbstracts: gameAbstracts.map((abstract) => {
      return { ...abstract };
    }),
  };
};

export const LibraryPage: React.FunctionComponent<LibraryPageProps> = () => {
  useHelp("LibraryPage", <MyHelp />, true);
  return (
    <FmForm
      name="LibraryPage"
      suppressPrompt
      fetch={{
        handler: fetchHandler,
        trigger: () => getAbstractsVersion(),
      }}
      onSubmit={() => Promise.resolve()}
    >
      {(fmFormRenderProps) => {
        return <RenderedFormChild fmFormRenderProps={fmFormRenderProps} />;
      }}
    </FmForm>
  );
};

const RenderedFormChild = (props: {
  fmFormRenderProps: FmFormRenderProps<FormData>;
}) => {
  const classes = useStyles();
  const fmFormRenderProps = props.fmFormRenderProps;
  const { formData, setFormData } = fmFormRenderProps;
  useEffect(() => {
    return registerTimestampChangeHandler((version) => {
      setFormData((draft) => {
        draft.gameAbstractsVersion = version;
      });
    });
  }, []);
  useEffect(() => {
    makeEmptyTrashDialog();
    return;
  }, []);
  const selectGameType = (
    <FormControl className={classes.formControl}>
      <Select
        labelId="gameTypeLabel"
        placeholder="Game Type"
        value={formData.gameTypeFilter}
        onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
          setFormData((draft) => {
            draft.gameTypeFilter = event.target.value as GameTypeFilter;
          });
        }}
      >
        <MenuItem value={"any"}>Any Game</MenuItem>
        <MenuItem value={"s"}>Spell</MenuItem>
        <MenuItem value={"c"}>Compute</MenuItem>
        <MenuItem value={"t"}>Twist</MenuItem>
      </Select>
    </FormControl>
  );
  const tableData = formData.gameAbstracts
    .filter(
      (abstract) =>
        formData.gameTypeFilter === "any" ||
        formData.gameTypeFilter === abstract.gameType
    )
    .map((abstract) =>
      Object.assign(Object.create(null) as GameAbstract, abstract)
    );
  return (
    <MaterialTable
      title={"Library Games"}
      columns={[
        {
          title: "Actions",
          field: "actions",
          width: "6rem",
          render: (rowData) => {
            return (
              <div style={{ display: "flex", alignContent: "center" }}>
                <SubtleButton
                  onClick={async () => {
                    const url = await getGameUrl(
                      rowData.gameType,
                      rowData.id,
                      rowData.name
                    );
                    window.open(url);
                  }}
                >
                  Play
                </SubtleButton>
                <SubtleButton
                  onClick={() => {
                    openAddGameDialog({
                      gameType: rowData.gameType,
                      templateGameRef: rowData.id, // use this game as the template
                      title: "Import Game from Library",
                      gameName: rowData.name,
                    });
                  }}
                >
                  Import
                </SubtleButton>
              </div>
            );
          },
        },
        {
          title: "Type",
          field: "gameType",
          cellStyle: {
            wordBreak: "normal",
          },
          render: (rowData) => {
            return (
              <Tooltip title={getGameTypeLabel(rowData.gameType)}>
                {getGameTypeIcon(rowData.gameType)}
              </Tooltip>
            );
          },
          customFilterAndSearch: (searchText: string, rowData) => {
            return match(searchText, rowData);
          },
        },
        {
          title: "Title",
          field: "title",
          cellStyle: {
            wordBreak: "normal",
          },
        },
        {
          title: "Subtitle",
          field: "subtitle",
          cellStyle: {
            wordBreak: "normal",
          },
        },
        {
          title: "Description",
          field: "description",
          cellStyle: {
            wordBreak: "normal",
          },
        },
        {
          title: "Published",
          field: "published",
          type: "datetime",
          defaultSort: "desc",
          customSort: (a1, a2) => {
            return (
              new Date(a1.published).getTime() -
              new Date(a2.published).getTime()
            );
          },
        },
      ]}
      data={tableData}
      options={{
        search: true,
        sorting: true,
      }}
      localization={{
        body: {
          emptyDataSourceMessage: "No games in the library",
        },
      }}
      actions={[
        {
          icon: () => selectGameType,
          isFreeAction: true,
          onClick: () => {},
        },
      ]}
    />
  );
};

const match = (searchText: string, gameAbstract: GameAbstract) => {
  const tokens = searchText.split(" ");
  for (let tokenIndex = 0; tokenIndex < tokens.length; tokenIndex++) {
    const token = tokens[tokenIndex].toLowerCase();
    if (token.length === 0) continue;

    if (gameAbstract.name.toLowerCase().includes(token)) continue;
    if (gameAbstract.title.toLowerCase().includes(token)) continue;
    if (gameAbstract.subtitle?.toLowerCase().includes(token)) continue;
    if (gameAbstract.description?.toLowerCase().includes(token)) continue;

    if (
      (gameAbstract.published
        ? new Date(gameAbstract.published).toLocaleString().toLowerCase()
        : "unpublished"
      ).indexOf(token) >= 0
    )
      continue;

    // If we get here, the token didn't match
    return false;
  }
  return true;
};

const MyHelp = () => {
  return (
    <SettingsContainer>
      <SettingSubheader>Find a Game</SettingSubheader>
      <SettingDescription>
        To find a game, either click a column title to sort or type search text
        in the search box. By default, the most recently published games are on
        top, which makes it easy to find recently added games.
      </SettingDescription>
      <SettingSubheader>Play Game</SettingSubheader>
      <SettingDescription>
        To try a game before importing it, click the PLAY button. The game will
        open in another browser tab, where you can play it.
      </SettingDescription>
      <SettingSubheader>Import Game</SettingSubheader>
      <SettingDescription>
        To import a game, click the IMPORT button. The game will open in the
        editor so that you can change it, and it will appear on your games page.
      </SettingDescription>
    </SettingsContainer>
  );
};
