import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import FilterListIcon from "@material-ui/icons/FilterList";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import RefreshIcon from "@material-ui/icons/Refresh";
import { format } from "date-fns";
import { Draft } from "immer";
import MaterialTable from "material-table";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { busyPromise } from "../../../../components/BusySpinner";
import { notifyError } from "../../../../components/NotificationManager";
import { FmForm } from "../../../../formManager/FmForm";
import { PopupMenu } from "../../../../components/PopupMenu";
import { User } from "../domain/User";
import { deleteUser } from "../requests/deleteUser";
import { deleteUserGameData } from "../requests/deleteUserGameData";
import { disableUser } from "../requests/disableUser";
import { Filter, getUsers, isClear } from "../requests/getUsers";
import { setAdminLevel } from "../requests/setAdminLevel";
import { setExpiry } from "../requests/setExpiry";
import { openConfirmImpactfulActionDialog } from "./ConfirmImpactfulActionDialog";
import { openFilterUsersDialog } from "./FilterUsersDialog";
import { openSetExpiryDialog } from "./SetExpiryDialog";

export interface AdminUsersPageProps {}

const MoreActions = (props: {
  uid: string;
  admin: boolean;
  disabled: boolean;
  expiry: Date;
  email: string;
}) => {
  return (
    <Grid container alignItems="center" justify="center">
      <PopupMenu
        menuElements={(pmProps) => {
          return [
            <MenuItem
              key="noadmin"
              onClick={async () => {
                setAdminLevel({
                  tuid: props.uid,
                  admin: 0,
                }).catch((error) => notifyError(error));
                pmProps.close();
              }}
            >
              Remove Admin
            </MenuItem>,
            <MenuItem
              key="admin"
              onClick={async () => {
                setAdminLevel({
                  tuid: props.uid,
                  admin: 1,
                }).catch((error) => notifyError(error));
                pmProps.close();
              }}
            >
              Make Admin
            </MenuItem>,
            <MenuItem
              key="superadmin"
              onClick={async () => {
                setAdminLevel({
                  tuid: props.uid,
                  admin: 2,
                }).catch((error) => notifyError(error));
                pmProps.close();
              }}
            >
              Make Super Admin
            </MenuItem>,
            <MenuItem
              key="disableuser"
              onClick={async () => {
                disableUser({
                  tuid: props.uid,
                  disabled: !props.disabled,
                }).catch((error) => notifyError(error));
                pmProps.close();
              }}
            >
              {props.disabled ? "Enable user" : "Disable user"}
            </MenuItem>,
            <MenuItem
              key="expiry"
              onClick={async () => {
                const dr = await openSetExpiryDialog({
                  expiry: props.expiry,
                });
                if (!dr.isOkay) return;
                setExpiry({
                  tuid: props.uid,
                  expiry: dr.result,
                }).catch((error) => notifyError(error));
                pmProps.close();
              }}
            >
              {"Set Expiration Date"}
            </MenuItem>,
            <MenuItem
              key="DeleteUserGameData"
              onClick={async () => {
                const dr = await openConfirmImpactfulActionDialog({
                  dialogTitleAndOkayText: "Delete User Game Data",
                  confirmationText: "Delete User Game Data",
                  dialogContentRenderer: (confirmationText: string) => (
                    <div>
                      All user game data for {props.email} will be deleted. The
                      user's profile and subscription data will be retained.
                      This will fail unless the user's subscription is expired.
                      You must type <b>{confirmationText}</b> in the field below
                      to complete this action.
                    </div>
                  ),
                });
                if (!dr.isOkay) return;
                deleteUserGameData({
                  tuid: props.uid,
                }).catch((error) => notifyError(error));
                pmProps.close();
              }}
            >
              {"Delete User Game Data"}
            </MenuItem>,
            <MenuItem
              key="DeleteUser"
              onClick={async () => {
                const dr = await openConfirmImpactfulActionDialog({
                  dialogTitleAndOkayText: "Delete User",
                  confirmationText: "Delete User",
                  dialogContentRenderer: (confirmationText: string) => (
                    <div>
                      The user {props.email} will be deleted. This will fail
                      unless the user's subscription is expired, the user is
                      diabled, and the user has no game data. You must type{" "}
                      <b>{confirmationText}</b> in the field below to complete
                      this action.
                    </div>
                  ),
                });
                if (!dr.isOkay) return;
                deleteUser({
                  tuid: props.uid,
                }).catch((error) => notifyError(error));
                pmProps.close();
              }}
            >
              {"Delete User"}
            </MenuItem>,
          ];
        }}
      >
        {() => (
          <IconButton size="small">
            <MoreVertIcon fontSize="small" />
          </IconButton>
        )}
      </PopupMenu>
    </Grid>
  );
};

interface FormData {
  fmFormDataVersion: number; // required by FmForm
  users: User[];
  incomplete: boolean;
  filter: Filter;
}

const fetchHandler = async (): Promise<FormData> => {
  return {
    fmFormDataVersion: 0,
    users: [],
    incomplete: false,
    filter: undefined,
  };
};

export const AdminUsersPage: React.FunctionComponent<AdminUsersPageProps> = () => {
  return (
    <FmForm
      name="AdminUsersPage"
      suppressPrompt
      fetch={{
        handler: fetchHandler,
      }}
      onSubmit={() => Promise.resolve()}
    >
      {(fmFormRenderProps) => {
        return (
          <RenderedFormChild
            setFormData={fmFormRenderProps.setFormData}
            formData={fmFormRenderProps.formData}
            submit={fmFormRenderProps.submit}
            isDirty={fmFormRenderProps.isDirty}
          />
        );
      }}
    </FmForm>
  );
};

const RenderedFormChild = (props: {
  setFormData: (
    mutator: (formData: Draft<FormData>) => FormData | void
  ) => FormData;
  formData: FormData;
  submit: () => Promise<unknown>;
  isDirty: boolean;
}) => {
  const { formData, setFormData } = props;
  const history = useHistory();

  const runSearch = async (filter: Filter) => {
    const r = await busyPromise(
      getUsers({ filter }).catch((error) => {
        notifyError(error.message);
        throw error;
      })
    );
    setFormData((draft) => {
      draft.users = r.users ?? [];
    });
    if (r.incomplete) {
      notifyError(
        "The first 100 matching users were returned. There are more. Refine your search filter."
      );
    }
    props.submit(); // clears dirty flag
  };

  // Clone because
  // * immer makes data immutable
  // * table tries to add attributes (bummer)
  const tableData = formData.users.map((user) => {
    return {
      uid: user.uid,
      displayName: user.displayName,
      email: user.email,
      emailVerified: user.emailVerified,
      admin: user.admin,
      disabled: user.disabled,
      expiry: user.expiry,
    };
  });
  return (
    <MaterialTable
      title={"Users"}
      columns={[
        {
          title: "Actions",
          field: "actions",
          width: "6rem",
          render: (rowData) => {
            return (
              <div style={{ display: "flex", alignContent: "center" }}>
                <MoreActions
                  uid={rowData.uid}
                  admin={rowData.admin}
                  disabled={rowData.disabled}
                  expiry={rowData.expiry}
                  email={rowData.email}
                />
              </div>
            );
          },
        },
        {
          title: "UID",
          field: "uid",
          cellStyle: {
            wordBreak: "break-word",
          },
        },
        {
          title: "Email",
          field: "email",
          cellStyle: {
            wordBreak: "break-word",
          },
        },
        {
          title: "Email Verified",
          field: "emailVerified",
          cellStyle: {
            wordBreak: "break-word",
          },
        },
        {
          title: "Display Name",
          field: "displayName",
        },
        {
          title: "Admin",
          field: "admin",
        },
        {
          title: "Disabled",
          field: "disabled",
        },
        {
          title: "Expiry",
          field: "expiry",
          render: (rowData) =>
            rowData.expiry
              ? format(new Date(rowData.expiry), "P")
              : rowData.expiry,
        },
      ]}
      data={tableData}
      options={{
        search: true,
        sorting: true,
      }}
      actions={[
        {
          icon: () => (
            <FilterListIcon
              color={isClear(formData.filter) ? "inherit" : "primary"}
            />
          ),
          tooltip: isClear(formData.filter) ? "Set filter" : "Change filter",
          isFreeAction: true,
          onClick: async () => {
            const dr = await openFilterUsersDialog({
              filter: formData.filter,
            });
            if (!dr.isOkay) return;
            setFormData((draftFormData) => {
              // Save the filter we just used
              draftFormData.filter = dr.result;
            });
          },
        },
        {
          icon: () => (
            <RefreshIcon color={props.isDirty ? "primary" : "inherit"} />
          ),
          tooltip: props.isDirty ? "Refresh - filter changed" : "Refresh",
          isFreeAction: true,
          onClick: () => {
            runSearch(formData.filter);
          },
        },
      ]}
    />
  );
};
