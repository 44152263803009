import CircularProgress from "@material-ui/core/CircularProgress";
import MuiDialog from "@material-ui/core/Dialog";
import * as React from "react";
import { delay } from "../utilities";
import {
  getDialogMethods,
  makeDialog,
  Dialog,
} from "./dialogTools/DialogManager";

export interface BusySpinnerProps {
  promise: Promise<unknown>;
}

const Div: React.FunctionComponent = (props) => {
  return <div>{props.children}</div>;
};

const makeBusySpinnerComponent = () => {
  const dialog = makeDialog<unknown, BusySpinnerProps>({
    name: "BusySpinner",
    componentRenderer: (props) => {
      if (props.props.promise === undefined) {
        alert("please supply a promise to BusySpinner");
      }
      // Delay so user does not see flash for fast operations
      delay(props.props.promise, 250).finally(() => {
        props.props.promise
          .then((result) => {
            return props.close(true, result);
          })
          .catch((error) => {
            props.close(true, props.props.promise);
            throw error;
          });
      });
      return (
        <MuiDialog
          PaperComponent={Div}
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth="xs"
          aria-labelledby="busy-dialog"
          open={true}
        >
          <CircularProgress />
        </MuiDialog>
      );
    },
  });
  return dialog;
};

let busySpinnerInternal: Dialog<unknown, BusySpinnerProps>;
const openBusySpinner = <T,>(props: BusySpinnerProps) => {
  if (!busySpinnerInternal) {
    busySpinnerInternal = makeBusySpinnerComponent();
  }
  return getDialogMethods().open(
    busySpinnerInternal as Dialog<T, BusySpinnerProps>,
    props
  );
};

// busyPromise returns the inner promise, which makes it convenient to call.
// Issue: the user is forced to wait a small bit so that the spinner doesn't flash.
export const busyPromise = <T,>(promise: Promise<T>) => {
  const dialogMethods = getDialogMethods();
  return openBusySpinner({ promise }).then((r) => {
    return r.result as T;
  });
};
