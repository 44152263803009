import * as React from "react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { NotFound } from "../../../components/NotFound";
import { notifyError } from "../../../components/NotificationManager";
import { GameAbstract } from "../../../domain/serverContract";
import { ComputeEditPage } from "../games/compute/components/ComputeEditPage";
import { SpellEditPage } from "../games/spell/components/SpellEditPage";
import { gameAbstractsRecord } from "../requests/manageGameAbstracts";
import { TwistEditPage } from "../games/twist/components/TwistEditPage";

export interface EditPageProps {
  setToolbar: (element: JSX.Element) => void;
}
export const EditPage = (props: EditPageProps) => {
  const history = useHistory();
  const [gameAbstract, setGameAbstract] = useState<GameAbstract>(undefined);

  const getGameFromPath = async () => {
    // Get id from path
    // */games/edit/<name>
    const tokens = history.location.pathname.split("/");
    const gameId = tokens[tokens.length - 1];
    const gameAbstractsRec = await gameAbstractsRecord();
    const gameAbstract = gameAbstractsRec.gameAbstracts[gameId];
    if (!gameAbstract) {
      // null means not found; undefined means don't know yet
      setGameAbstract(null);
    } else {
      setGameAbstract(gameAbstract);
    }
  };
  useEffect(() => {
    getGameFromPath();
  }, []);
  if (gameAbstract === undefined) {
    return null;
  }
  if (gameAbstract === null) {
    return (
      <NotFound>Game not found. Perhaps it was permanently deleted.</NotFound>
    );
  }
  switch (gameAbstract.gameType) {
    case "c":
      return (
        <ComputeEditPage
          gameAbstract={gameAbstract}
          setToolbar={props.setToolbar}
        />
      );
    case "s":
      return (
        <SpellEditPage
          gameAbstract={gameAbstract}
          setToolbar={props.setToolbar}
        />
      );
    case "t":
      return (
        <TwistEditPage
          gameAbstract={gameAbstract}
          setToolbar={props.setToolbar}
        />
      );
    default:
      console.error(`Invalid game type: ${gameAbstract.gameType} `);
      notifyError(`Invalid game type: ${gameAbstract.gameType} `);
      break;
  }
  return <NotFound />;
};
