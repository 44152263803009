import { ApiResponseHeader } from "../../../domain/serverContract";
import { makeRequest } from "../../../http/http";

export interface GetVersionRequest {}

export interface GetVersionResponse extends ApiResponseHeader {
  versionItems: string[];
}

export async function getVersion(
  request: GetVersionRequest
): Promise<GetVersionResponse> {
  return makeRequest(request, "/api/get_version");
}
