import EditIcon from "@material-ui/icons/Edit";
import { Draft } from "immer";
import * as React from "react";
import { ColumnContainer } from "../../../../../components/ColumnContainer";
import Emphasize from "../../../../../components/Emphasize";
import { useHelp } from "../../../../../components/HelpDialog";
import Hider from "../../../../../components/Hider";
import SettingDescription from "../../../../../components/SettingDescription";
import SettingHeader from "../../../../../components/SettingHeader";
import SettingsContainer from "../../../../../components/SettingsContainer";
import SettingSubheader from "../../../../../components/SettingSubheader";
import { FmSwitchField } from "../../../../../formManager/FmField";
import { TwistChallengeTable } from "./TwistChallengeTable";
import { FormData } from "./TwistEditPage";
import { PrimaryButton } from "../../../../../components/buttons";
import { openBulkAddChallengesDialog } from "./BulkAddChallengesDialog";
import { Challenge } from "../../../../../domain/twist_types";
import { shuffleString } from "../../../../../utilities";
import {
  makeDefaultFixedString,
  getMinimalFillin,
} from "./EditChallengeDialog";

const TwistEditChallengesTabInternal = (props: {
  formData: FormData;
  setFormData: (
    mutator: (formData: Draft<FormData>) => FormData | void
  ) => FormData;
  isActive: boolean;
}) => {
  useHelp("TwistEditChallengesTab", <MyHelp />, props.isActive);
  const { formData, setFormData } = props;
  return (
    <Hider hidden={!props.isActive}>
      <ColumnContainer center>
        <FmSwitchField name="caseSensitive" />
        <TwistChallengeTable challenges={formData.challenges} />
        <PrimaryButton
          onClick={() => {
            openBulkAddChallengesDialog({
              currentChallenges: formData.challenges,
              title: "Bulk Add Challenges",
            }).then((response) => {
              if (!response.isOkay) return;
              const newChallenges = response.result.map((bulkExport) => {
                const fixed = makeDefaultFixedString(bulkExport.word);
                const newChallenge: Challenge = {
                  solution: bulkExport.word,
                  clue: bulkExport.clue,
                  fillin: shuffleString(
                    getMinimalFillin(bulkExport.word, fixed)
                  ),
                  fixed,
                };
                return newChallenge;
              });
              setFormData((draftFormData) => {
                draftFormData.challenges = [
                  ...draftFormData.challenges,
                  ...newChallenges,
                ];
              });
            });
          }}
        >
          Bulk Add Challenges
        </PrimaryButton>
      </ColumnContainer>
    </Hider>
  );
};

export const TwistEditChallengesTab = React.memo(
  TwistEditChallengesTabInternal,
  () => {
    return false;
  }
);

const MyHelp = () => {
  return (
    <SettingsContainer>
      <SettingHeader>Chose Challenges</SettingHeader>
      <SettingSubheader>Case Sensitive</SettingSubheader>
      <SettingDescription>
        If enabled, then answers must be the same case as the solutions. If
        disabled, then case doesn't matter.
      </SettingDescription>

      <SettingSubheader>Challenges</SettingSubheader>
      <SettingDescription>
        Add a challenge by pressing the ADD CHALLENGE button. The Modify
        Challenge dialog will open. See below for help with the Modify Challenge
        dialog.
      </SettingDescription>
      <SettingDescription>
        Challenges will be presented in the order displayed on the Choose
        Challenges page. Reorder the challenges by dragging them into new
        positions.
      </SettingDescription>

      <SettingSubheader>Modifying a Challenge</SettingSubheader>
      <SettingDescription>
        To modify a challenge, click the edit icon: <EditIcon />. The Modify
        Challenge dialog will open. In the dialog, you can enter or modify the
        following:
        <ul>
          <li>
            <Emphasize>Solution:</Emphasize> The solution is the solution to the
            challenge.
          </li>
          <li>
            <Emphasize>Pre-filled letters:</Emphasize> To make a challenge
            easier, pre-fill some of the letters. Type an asterisk (*) to
            represent a "blank" position which the player must fill. Type a
            letter in the solution to cause that letter to be pre-filled in the
            answer. Type a space as a short-cut to typing a letter in the
            solution.
          </li>
          <li>
            <Emphasize>Fill-in Letters:</Emphasize> The fill-in letters are the
            letters the play must choose from to solve the challenge. You can
            provide extra letters, unused in the solution, to make the challenge
            more difficult.
          </li>
          <li>
            <Emphasize>Clue:</Emphasize> The clue is presented along with the
            challenge. It is optional.
          </li>
        </ul>
      </SettingDescription>
    </SettingsContainer>
  );
};
