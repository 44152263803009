import { Divider } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import { Draft } from "immer";
import * as React from "react";
import { ColumnContainer } from "../../../../../components/ColumnContainer";
import Emphasize from "../../../../../components/Emphasize";
import { useHelp } from "../../../../../components/HelpDialog";
import Hider from "../../../../../components/Hider";
import { RowContainer } from "../../../../../components/RowContainer";
import SettingDescription from "../../../../../components/SettingDescription";
import SettingHeader from "../../../../../components/SettingHeader";
import SettingsContainer from "../../../../../components/SettingsContainer";
import SettingSubheader from "../../../../../components/SettingSubheader";
import { TellMeMore } from "../../../../../components/TellMeMore";
import {
  getOpChar,
  NewOperationDomain,
  Op,
  OperationDomain,
} from "../../../../../domain/compute_types";
import {
  FmNumberField,
  FmSwitchField,
} from "../../../../../formManager/FmField";
import { FormData } from "./ComputeEditPage";

const useStyles = makeStyles(() => {
  return createStyles({
    divider: {
      width: "100%",
      marginBottom: "0.25rem",
    },
    text: {
      margin: "8px 1rem 8px 1rem",
    },
  });
});

// const delegator = <T,>(v: T, setter: (v: T) => void) => {
//   return [v, setter];
// };

const ComputeEditChallengesTabInternal = (props: {
  formData: FormData;
  setFormData: (
    mutator: (formData: Draft<FormData>) => FormData | void
  ) => FormData;
  isActive: boolean;
}) => {
  useHelp("ComputeEditChallengesTab", <MyHelp />, props.isActive);
  const { formData, setFormData } = props;
  return (
    <Hider hidden={!props.isActive}>
      <ColumnContainer center>
        <OpWidget
          op={Op.Add}
          opEnabled={!!formData.add}
          domain={formData.add}
          enableDomain={(enabled: boolean) =>
            setFormData((draftFormData) => {
              if (!enabled) {
                draftFormData.add = undefined;
                return;
              }
              draftFormData.add = NewOperationDomain();
            })
          }
          setDomain={(mutator: (domain: OperationDomain) => void) => {
            setFormData((draftFormData) => {
              mutator(draftFormData.add);
            });
          }}
        />

        <OpWidget
          op={Op.Sub}
          opEnabled={!!formData.sub}
          domain={formData.sub}
          enableDomain={(enabled: boolean) =>
            setFormData((draftFormData) => {
              if (!enabled) {
                draftFormData.sub = undefined;
                return;
              }
              draftFormData.sub = NewOperationDomain();
            })
          }
          setDomain={(mutator: (domain: OperationDomain) => void) => {
            setFormData((draftFormData) => {
              mutator(draftFormData.sub);
            });
          }}
        />

        <OpWidget
          op={Op.Mul}
          opEnabled={!!formData.mul}
          domain={formData.mul}
          enableDomain={(enabled: boolean) =>
            setFormData((draftFormData) => {
              if (!enabled) {
                draftFormData.mul = undefined;
                return;
              }
              draftFormData.mul = NewOperationDomain();
            })
          }
          setDomain={(mutator: (domain: OperationDomain) => void) => {
            setFormData((draftFormData) => {
              mutator(draftFormData.mul);
            });
          }}
        />

        <OpWidget
          op={Op.Div}
          opEnabled={!!formData.div}
          domain={formData.div}
          enableDomain={(enabled: boolean) =>
            setFormData((draftFormData) => {
              if (!enabled) {
                draftFormData.div = undefined;
                return;
              }
              draftFormData.div = NewOperationDomain();
            })
          }
          setDomain={(mutator: (domain: OperationDomain) => void) => {
            setFormData((draftFormData) => {
              mutator(draftFormData.div);
            });
          }}
        />

        <Divider variant="middle" style={{ width: "100%" }} />

        <RowContainer center>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <FmNumberField<FormData>
              name="timeLimit"
              labelText="Time Limit (seconds)"
              placeholderText="No limit"
              maxLength={3}
              width="7rem"
              maxValue={999}
              minValue={0}
            />
            <TellMeMore icon>
              This is the amount of time allowed per game. Zero means no time
              limit.
            </TellMeMore>
          </div>

          <div
            style={{ display: "flex", flexWrap: "wrap", marginLeft: "1rem" }}
          >
            <FmNumberField<FormData>
              name="challengeCount"
              labelText="Number of Challenges"
              maxLength={3}
              width="7rem"
              maxValue={999}
              minValue={1}
            />
            <TellMeMore icon>
              This is the greatest number of challenges that will be presented
              within the time limit.
            </TellMeMore>
          </div>
        </RowContainer>

        <div style={{ display: "flex", flexWrap: "wrap", marginLeft: "1rem" }}>
          <FmSwitchField name="mustSolve" />
          <TellMeMore icon>
            If enabled, then the player must solve each challenge correctly
            before being presented with the next challenge.
          </TellMeMore>{" "}
        </div>
      </ColumnContainer>
    </Hider>
  );
};

export const ComputeEditChallengesTab = React.memo(
  ComputeEditChallengesTabInternal,
  () => {
    return false;
  }
);

const OpWidget = (props: {
  op: Op;
  opEnabled: boolean;
  domain: OperationDomain;
  enableDomain: (enabled: boolean) => void;
  setDomain: (mutator: (domain: OperationDomain) => void) => void;
}) => {
  return (
    <RowContainer center>
      <div style={{ minWidth: "5rem", marginRight: "auto" }}>
        <FormControlLabel
          control={
            <Switch
              checked={props.opEnabled}
              color="primary"
              onChange={(event) => {
                props.enableDomain(event.currentTarget.checked);
              }}
            />
          }
          label={
            <span style={{ fontSize: "150%", width: "1rem" }}>
              {getOpChar(props.op)}
            </span>
          }
        />
      </div>
      <RowContainer center width={"auto"}>
        <Hider hidden={!props.opEnabled} unmount>
          <RowContainer center width={"auto"}>
            <OperandRangeField
              rangeText={props.domain?.lhs}
              labelText="Left Operand Value List"
              setRangeText={(rangeText: string) => {
                props.setDomain((draft) => {
                  draft.lhs = rangeText;
                });
              }}
            />
            <span style={{ fontSize: "150%", minWidth: "1rem" }}>
              {getOpChar(props.op)}
            </span>
            <OperandRangeField
              rangeText={props.domain?.rhs}
              labelText="Right Operand Value List"
              setRangeText={(rangeText: string) => {
                props.setDomain((draft) => {
                  draft.rhs = rangeText;
                });
              }}
            />
          </RowContainer>
        </Hider>
      </RowContainer>
      <div style={{ marginLeft: "auto" }}></div>
    </RowContainer>
  );
};

const OperandRangeField = (props: {
  rangeText: string;
  labelText: string;
  setRangeText: (rangeText: string) => void;
}) => {
  const classes = useStyles();
  return (
    <TextField
      className={classes.text}
      variant="outlined"
      value={props.rangeText ?? ""}
      label={props.labelText}
      placeholder={"example: 1-10"}
      margin="dense"
      InputLabelProps={{
        shrink: true,
      }}
      onChange={(event) => {
        props.setRangeText(event.currentTarget.value);
      }}
    />
  );
};

const MyHelp = () => {
  return (
    <SettingsContainer>
      <SettingHeader>Chose Challenges</SettingHeader>
      <SettingSubheader>Operations</SettingSubheader>
      <SettingDescription>
        Enable between 1 and 4 operations:
        <ul>
          <li>Addition</li>
          <li>Subtraction</li>
          <li>Multiplication</li>
          <li>Division</li>
        </ul>
      </SettingDescription>
      <SettingDescription>
        For each operation you enable, you must specify the operands on the left
        side and right side. These are the Left Operand Value List and the Right
        Operand Value List. You can specify a range, such as "1-10", or a list,
        such as "2, 4, 6, 8". Or you may specify both ranges and lists (commas
        are optional). Examples:
        <ul>
          <li>
            <Emphasize>1-10</Emphasize>Integers from 1 through 10
          </li>
          <li>
            <Emphasize>1 2 3 4 5 6 7 8 9 10</Emphasize>Integers from 1 through
            10
          </li>
          <li>
            <Emphasize>1-10 15 20 25</Emphasize>Integers from 1 through 10 and
            multiples of 5 from 15 through 25
          </li>
        </ul>
      </SettingDescription>

      <SettingSubheader>Challenges</SettingSubheader>
      <SettingDescription>
        Cortex will build an internal list of all of the possible challenges
        (problems) that result in a non-negative integer. It will present those
        challenges in a random order, until either the time limit is exceeded or
        all challenges are answered. A challenge won't be repeated in the same
        game unless the number of possible challenges is less that the number of
        challenges configured for the game.
      </SettingDescription>
      <SettingDescription>
        The operations with more possible challenges will tend to be presented
        more often because of the method of random selection.
      </SettingDescription>

      <SettingSubheader>Time Limit</SettingSubheader>
      <SettingDescription>
        The game ends when the time limit is reached. If the time limit is set
        to zero, then the game will not end based on elapsed time.
      </SettingDescription>

      <SettingSubheader>Number of Challenges</SettingSubheader>
      <SettingDescription>
        This is the greatest number of challenges that will be presented within
        the time limit. If there is no time limit (that is, the time limit is
        set to zero), then the game ends only when the specified number of
        challenges have been presented.
      </SettingDescription>
    </SettingsContainer>
  );
};
