import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  makeStyles,
} from "@material-ui/core";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { ColumnContainer } from "../../../components/ColumnContainer";
import { ConfirmationDialogBase } from "../../../components/dialogTools/ConfirmationDialogBase";
import {
  Dialog,
  getDialogMethods,
  makeDialog,
} from "../../../components/dialogTools/DialogManager";
import { notifyError } from "../../../components/NotificationManager";
import SettingDescription from "../../../components/SettingDescription";
import { TellMeMore } from "../../../components/TellMeMore";
import { FmTextField } from "../../../formManager/FmField";
import { FmForm, FmFormRenderProps } from "../../../formManager/FmForm";
import { createGame, CreateGameResponse } from "../requests/createGame";
import { GameType } from "../../../domain/types";
const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: "15rem",
    margin: "1rem",
  },
}));
interface FormData {
  fmFormDataVersion: number; // required by FmForm
  name: string;
  gameType: GameType;
}
export interface AddGameDialogProps {
  title: string;
  // The gameRef can be a game ID or a progress ID.
  templateGameRef?: string;
  gameType?: GameType;
  gameName?: string;
}

let addGameDialogInternal: Dialog<string[], AddGameDialogProps>;

export const openAddGameDialog = (props: AddGameDialogProps) => {
  if (!addGameDialogInternal) {
    addGameDialogInternal = makeAddGameDialog();
  }
  return getDialogMethods().open(addGameDialogInternal, props);
};

const makeAddGameDialog = () =>
  makeDialog<unknown, AddGameDialogProps>({
    name: "AddGameDialog",
    componentRenderer: (dialogRenderProps) => {
      const handleSubmit = async (
        fmFormRenderProps: FmFormRenderProps<FormData>
      ) => {
        const formData = fmFormRenderProps.formData;
        return createGame({
          name: formData.name,
          templateGameRef: dialogRenderProps.props?.templateGameRef,
          gameType: formData.gameType,
        });
      };

      const history = useHistory();
      const classes = useStyles();
      return (
        <FmForm
          name="AddGameDialog"
          fetch={{
            handler: () =>
              Promise.resolve({
                name: dialogRenderProps.props.gameName ?? "",
                fmFormDataVersion: undefined,
                gameType: dialogRenderProps.props.gameType ?? "t",
              }),
          }}
          onSubmit={handleSubmit}
        >
          {(fmProps) => {
            return (
              <ConfirmationDialogBase
                onClose={(isOkay) => {
                  if (isOkay) {
                    // Create the game
                    return fmProps
                      .submit()
                      .then((response: CreateGameResponse) => {
                        dialogRenderProps.close(true, undefined);
                        history.push(`/games/edit/${response.id}`);
                        return response;
                      })
                      .catch((error: Error) => {
                        notifyError(error.message);
                      });
                  } else {
                    dialogRenderProps.close(false, undefined);
                  }
                }}
                open={true}
                title={dialogRenderProps.props.title}
                okayText="Create Game"
              >
                <ColumnContainer center>
                  {dialogRenderProps.props.gameType == null && (
                    <FormControl className={classes.formControl}>
                      <InputLabel id="gameTypeLabel">Game Type</InputLabel>
                      <Select
                        labelId="gameTypeLabel"
                        value={fmProps.formData.gameType}
                        onChange={(
                          event: React.ChangeEvent<{ value: unknown }>
                        ) => {
                          fmProps.setFormData((draft) => {
                            draft.gameType = event.target.value as GameType;
                          });
                        }}
                      >
                        <MenuItem value={"s"}>Spell</MenuItem>
                        <MenuItem value={"c"}>Compute</MenuItem>
                        <MenuItem value={"t"}>Twist</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                  <SettingDescription>
                    Enter the name of your new game (no spaces), then press
                    Create Game. Once you add the game, you can customize it.
                    The name must be unique.
                    <TellMeMore>
                      The name must consist entirely of letters, digits,
                      underscores, and dashes. This allows the name to be easily
                      read in URLs, so that you can identify the game to which a
                      URL refers.
                    </TellMeMore>
                  </SettingDescription>
                  <FmTextField<FormData>
                    name="name"
                    maxLength={20}
                    initialFocus
                    textFieldProps={{
                      InputProps: {
                        spellCheck: false,
                      },
                    }}
                  />
                </ColumnContainer>
              </ConfirmationDialogBase>
            );
          }}
        </FmForm>
      );
    },
  });
