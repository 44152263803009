import RadioButtonUncheckedOutlined from "@material-ui/icons/RadioButtonUncheckedOutlined";
import * as React from "react";
import Button from "@material-ui/core/Button";

export interface LetterButtonComponentProps {
  letter: string;
  isRequired?: boolean;
  onClick?: (letter: string) => void;
}
const LetterButtonComponentInternal = (props: LetterButtonComponentProps) => {
  const { isRequired, letter, onClick } = props;
  return (
    <Button
      variant="contained"
      color={isRequired ? "secondary" : "primary"}
      onClick={() => {
        onClick && onClick(letter);
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>{letter}</div>
        {isRequired && (
          <div
            style={{
              position: "absolute",
              zIndex: 10,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <RadioButtonUncheckedOutlined style={{ fontSize: "2rem" }} />
          </div>
        )}
      </div>
    </Button>
  );
};

export const LetterButtonComponent = React.memo(
  LetterButtonComponentInternal,
  (prevProps, nextProps) => {
    if (prevProps.isRequired !== nextProps.isRequired) return false;
    if (prevProps.letter !== nextProps.letter) return false;
    if (prevProps.onClick !== nextProps.onClick) return false;
    return true;
  }
);
