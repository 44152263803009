import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import * as React from "react";
import { ConfirmationDialogBase } from "../../../../../components/dialogTools/ConfirmationDialogBase";
import {
  Dialog,
  getDialogMethods,
  makeDialog,
} from "../../../../../components/dialogTools/DialogManager";
import { notifyError } from "../../../../../components/NotificationManager";
import SettingDescription from "../../../../../components/SettingDescription";
import { FmTextField } from "../../../../../formManager/FmField";
import { FmForm, FmFormRenderProps } from "../../../../../formManager/FmForm";
import {
  findDuplicates,
  makeSeparatedStringFromList,
} from "../../../../../utilities";

interface FormData {
  fmFormDataVersion: number; // required by FmForm
  words: string;
}

export interface AddWordsDialogProps {
  submitHandler: (words: string[]) => Promise<void>;
  title: string;
}

export let getWordsDialogInternal: Dialog<string[], AddWordsDialogProps>;

export const openAddWordsDialog = (props: AddWordsDialogProps) => {
  if (!getWordsDialogInternal) {
    getWordsDialogInternal = makeAddWordsDialogComponent();
  }
  return getDialogMethods().open(getWordsDialogInternal, props);
};

export const makeAddWordsDialogComponent = () => {
  return makeDialog<string[], AddWordsDialogProps>({
    componentRenderer: (dialogRenderProps) => {
      const { submitHandler } = dialogRenderProps.props;
      const fmFormSubmitHandler = (
        fmFormRenderProps: FmFormRenderProps<FormData>
      ) => {
        const formData = fmFormRenderProps.formData;
        const words = formData.words.trim().replace(/\s+/g, " ").split(" ");
        if (words.length === 0 || words[0] === "") {
          return Promise.reject("There are no words to add.");
        }
        const dups = findDuplicates(words);
        if (dups.length > 0) {
          return Promise.reject(
            "These words were entered more than once in your list: " +
              makeSeparatedStringFromList(dups)
          );
        }
        return submitHandler(words);
      };

      return (
        <FmForm
          suppressPrompt
          name="AddWordsDialog"
          suppressSpinner
          fetch={{
            handler: () =>
              Promise.resolve({ words: "", fmFormDataVersion: undefined }),
          }}
          onSubmit={fmFormSubmitHandler}
        >
          {(fmProps) => (
            <ConfirmationDialogBase
              onClose={(isOkay) => {
                if (isOkay) {
                  fmProps
                    .submit()
                    .then((response: string[]) => {
                      dialogRenderProps.close(true, response);
                      return response;
                    })
                    .catch((reason) => notifyError(reason));
                } else {
                  dialogRenderProps.close(false, undefined);
                }
              }}
              open={true}
              title={dialogRenderProps.props.title}
              okayText={dialogRenderProps.props.title}
            >
              <Grid container direction="column" alignItems="center">
                <SettingDescription>
                  Enter one or more words, separated by spaces or new lines.
                </SettingDescription>
                <Box m={0.5} />
                <Grid container direction="column" alignContent="center">
                  <FmTextField<FormData>
                    name="words"
                    width="100%"
                    textFieldProps={{ multiline: true, rows: 6 }}
                    forceToLowerCase
                    initialFocus
                  />
                </Grid>
              </Grid>
            </ConfirmationDialogBase>
          )}
        </FmForm>
      );
    },
  });
};
