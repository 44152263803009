import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import PangramIcon from "@material-ui/icons/Category";
import SpecialIcon from "@material-ui/icons/Stars";
import LongestIcon from "@material-ui/icons/TextRotationNone";
import UndoIcon from "@material-ui/icons/Undo";
import { Draft } from "immer";
import * as React from "react";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  SecondaryButton,
  SubtleButton,
} from "../../../../../components/buttons";
import { ColumnContainer } from "../../../../../components/ColumnContainer";
import { useHelp } from "../../../../../components/HelpDialog";
import Hider from "../../../../../components/Hider";
import { notifyError } from "../../../../../components/NotificationManager";
import { RowContainer } from "../../../../../components/RowContainer";
import SettingDescription from "../../../../../components/SettingDescription";
import SettingHeader from "../../../../../components/SettingHeader";
import SettingsContainer from "../../../../../components/SettingsContainer";
import SettingSubheader from "../../../../../components/SettingSubheader";
import { TellMeMore } from "../../../../../components/TellMeMore";
import { Tip } from "../../../../../components/Tip";
import { FmNumberField, FmTextField } from "../../../../../formManager/FmField";
import { pickout, shuffle, shuffleString } from "../../../../../utilities";
import { findWords } from "../wordFinder";
import { LetterButtonComponent } from "./LetterButtonComponent";
import { LettersComponent } from "./LettersComponent";
import { FormData } from "./SpellEditPage";
import { GearIcon, WordList } from "./WordList";
import { openReorderLettersDialog } from "./ReorderLettersDialog";

const MAX_WORD_COUNT = 200;

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    divider: {
      width: "100%",
      marginBottom: "0.25rem",
    },
  });
});

const SpellEditPageChooseWordsTabInternal = (props: {
  formData: FormData;
  setFormData: (
    mutator: (formData: Draft<FormData>) => FormData | void
  ) => FormData;
  isActive: boolean;
}) => {
  useHelp("SpellEditPageChooseWordsTab", <MyHelp />, props.isActive);
  const { formData, setFormData } = props;
  const classes = useStyles();
  const foundWordsLengthRef = useRef(0);
  const [
    showDisappearingLettersHint,
    setShowDisappearingLettersHint,
  ] = useState(false);
  useEffect(() => {
    if (formData.foundWords.length === 0 && foundWordsLengthRef.current > 0) {
      foundWordsLengthRef.current = formData.foundWords.length;
      setShowDisappearingLettersHint(true);
    } else if (formData.foundWords.length > 0) {
      setShowDisappearingLettersHint(false);
      foundWordsLengthRef.current = formData.foundWords.length;
    }
  }, [formData.foundWords.length]);

  const wordListWords = useMemo(
    () =>
      formData.foundWords
        .map((word) => {
          return { word: word.word, isSpecial: word.isSpecial };
        })
        .sort((a, b) => (a.word < b.word ? -1 : a.word > b.word ? 1 : 0)),
    [formData.foundWords]
  );
  const wordListAllLetters = useMemo(() => [...(formData.letters ?? [])], [
    formData.letters,
  ]);
  const wordListActions = useMemo(() => {
    return {
      addWords: (words: string[]) => {
        setFormData((draftFormData) => {
          draftFormData.foundWords = [
            ...draftFormData.foundWords,
            ...words.map((word) => {
              return { word, isSpecial: false };
            }),
          ];
        });
      },
      remove: (word: string) => {
        const index = formData.foundWords
          .map((foundWord) => foundWord.word)
          .indexOf(word);
        if (index >= 0) {
          setFormData((draftFormData) => {
            draftFormData.foundWords.splice(index, 1);
          });
        }
      },
      toggleSpecial: (word: string) => {
        const index = formData.foundWords
          .map((foundWord) => foundWord.word)
          .indexOf(word);
        if (index >= 0) {
          setFormData((draftFormData) => {
            draftFormData.foundWords[index].isSpecial = !draftFormData
              .foundWords[index].isSpecial;
          });
        }
      },
    };
  }, [formData.foundWords]);

  return (
    <Hider hidden={!props.isActive}>
      <ColumnContainer center>
        <RowContainer center>
          <FmTextField<FormData>
            name="letters"
            labelText="Letters (3 to 10 different)"
            maxLength={10}
            forceToLowerCase
            width="15rem"
            textFieldProps={{
              placeholder: "Type letters to get started",

              InputProps: {
                spellCheck: false,
              },
            }}
            onChange={(args) => {
              let { draftFormData, fieldData, oldFieldData } = args;
              // Eliminate duplicate letters
              const deduped = [...(fieldData ?? [])]
                .reduce((resultLetters, letter) => {
                  if (
                    resultLetters.find(
                      (resultLetter) => resultLetter === letter
                    )
                  )
                    return resultLetters;
                  resultLetters.push(letter);
                  return resultLetters;
                }, [] as string[])
                .join("");
              if (deduped !== fieldData) {
                fieldData = deduped;
              }
              // Remove letters from requiredLetters, if necessary
              const rl =
                [...(formData.requiredLetters ?? [])]
                  .reduce((result, letter) => {
                    const foundIndex = fieldData?.indexOf(letter) ?? -1;
                    if (foundIndex >= 0) result.push(letter);
                    return result;
                  }, [] as string[])
                  ?.join("") ?? "";

              draftFormData.requiredLetters = rl;
              draftFormData.letters = deduped;
              if (
                oldFieldData !== deduped &&
                draftFormData.foundWords.length > 0
              )
                // only clear if not already cleared -- otherwise this can trigger renders of components looking for changes in foundWords
                draftFormData.foundWords = [];
            }}
          />
          <SubtleButton
            onClick={() =>
              setFormData((draft) => {
                draft.letters = shuffleString(draft.letters);
              })
            }
          >
            Shuffle
          </SubtleButton>
          <SubtleButton
            onClick={async () => {
              const r = await openReorderLettersDialog({
                letters: formData.letters,
              });
              if (!r.isOkay) return;
              setFormData((draft) => {
                draft.letters = r.result;
              });
            }}
          >
            Set Order
          </SubtleButton>
        </RowContainer>
        <Hider hidden={formData.letters.length > 0}>
          <TellMeMore color="primary" buttonText="What do I do next?">
            Type at least 3 letters in the box above. They will be the letters
            used to form words.
          </TellMeMore>
        </Hider>
        <Hider hidden={formData.letters.length === 0}>
          <ColumnContainer center>
            <RowContainer center>
              <span>Click letters below to make them required:</span>
              <TellMeMore>
                <ColumnContainer center>
                  <RowContainer>
                    <span style={{ marginRight: "0.5rem" }}>
                      Required letters look like
                    </span>
                    <LetterButtonComponent letter="R" isRequired />
                  </RowContainer>
                  <Box m={1} />
                  <RowContainer>
                    <span style={{ marginRight: "0.5rem" }}>
                      Optional letters look like
                    </span>
                    <LetterButtonComponent letter="N" isRequired={false} />
                  </RowContainer>
                </ColumnContainer>
              </TellMeMore>
            </RowContainer>
            <RowContainer center>
              <LettersComponent
                letters={formData.letters}
                requiredLetters={formData.requiredLetters}
                onClick={(letter) => {
                  const index = formData.requiredLetters?.indexOf(letter) ?? -1;
                  if (index >= 0) {
                    setFormData((draftFormData) => {
                      draftFormData.foundWords = [];
                      draftFormData.requiredLetters =
                        draftFormData.requiredLetters.slice(0, index) +
                          draftFormData.requiredLetters.slice(index + 1) ?? "";
                    });
                  } else {
                    setFormData((draftFormData) => {
                      draftFormData.foundWords = [];
                      draftFormData.requiredLetters =
                        (draftFormData.requiredLetters ?? "") + letter;
                    });
                  }
                }}
              />
            </RowContainer>
            <FmNumberField<FormData>
              name="minimumWordLength"
              width="12rem"
              onChange={(args) => {
                args.draftFormData.foundWords = [];
              }}
            />
            <Divider className={classes.divider} variant="fullWidth" />
            <SecondaryButton
              aria-label="search"
              disabled={formData.letters.length < 3}
              onClick={async () => {
                const words = await findWords(
                  formData.requiredLetters,
                  [...(formData.letters ?? [])]
                    .filter(
                      (letter) => formData.requiredLetters.indexOf(letter) < 0
                    )
                    .join(""),
                  Number(formData.minimumWordLength),
                  MAX_WORD_COUNT
                );
                if (words.length > MAX_WORD_COUNT) {
                  // Too many
                  setFormData((draftFormData) => {
                    draftFormData.foundWords = [];
                  });
                  notifyError(
                    `There are too many matches (more than ${MAX_WORD_COUNT}). Please increase the minimum word length or add more required letters.`
                  );
                } else {
                  setFormData((draftFormData) => {
                    draftFormData.foundWords = words.map((word) => {
                      return { word, isSpecial: false };
                    });
                  });
                }
              }}
            >
              {formData.letters.length < 3
                ? "Search for Words (need 3 or more letters)"
                : "Search for Words"}
            </SecondaryButton>
            <Box marginTop={0.25} />
            <ColumnContainer center>
              <div>
                You have {formData.foundWords.length} word
                {formData.foundWords.length !== 1 ? "s" : ""}
              </div>
              <div>
                {formData.foundWords.length > 40 ? (
                  <Tip>
                    You may have too many words for a good game (more than 40).
                    Consider reducing the number of words by increasing the
                    minimum word length or making more letters required. Simply
                    removing words that players know may be frustrating for
                    them.
                  </Tip>
                ) : null}
              </div>
            </ColumnContainer>
            <Hider hidden={!showDisappearingLettersHint}>
              <TellMeMore
                color="primary"
                buttonText="Why did my words disappear?"
              >
                When you add or remove letters, change the minimum word length,
                or change which letters are required, then the words you
                previously found may not match the new rules, or the word list
                might be incomplete. So the words previously found are removed.
                If you want to recover them, press undo:{" "}
                <UndoIcon fontSize="small" />.
              </TellMeMore>
            </Hider>
            <WordList
              items={wordListWords}
              allLetters={wordListAllLetters}
              actions={wordListActions}
              minimumWordLength={formData.minimumWordLength}
              optionalLetters={pickout(
                formData.requiredLetters,
                formData.letters
              )}
              requiredLetters={formData.requiredLetters}
            />
          </ColumnContainer>
        </Hider>
      </ColumnContainer>
    </Hider>
  );
};

export const SpellEditPageChooseWordsTab = React.memo(
  SpellEditPageChooseWordsTabInternal,
  (prevProps, nextProps) => {
    if (prevProps.isActive !== nextProps.isActive) return false;
    if (prevProps.formData.foundWords !== nextProps.formData.foundWords)
      return false;
    if (prevProps.formData.letters !== nextProps.formData.letters) return false;
    if (
      prevProps.formData.requiredLetters !== nextProps.formData.requiredLetters
    )
      return false;
    if (
      prevProps.formData.minimumWordLength !==
      nextProps.formData.minimumWordLength
    )
      return false;
    return true;
  }
);

const MyHelp = () => {
  return (
    <SettingsContainer>
      <SettingHeader>Chose Words</SettingHeader>
      <SettingSubheader>Type Letters to Get Started</SettingSubheader>
      <SettingDescription>
        Type the letters to be used to form words. Once you type a letter you
        will see more choices.
      </SettingDescription>
      <SettingSubheader>Choose Required Letters</SettingSubheader>
      <SettingDescription>
        The letters you type will appear in blue buttons like this{" "}
        <LetterButtonComponent letter="N" />. Click on a letter to make it
        required. It will then appear in a yellow button, with a circle around
        the letter like this
        <LetterButtonComponent letter="R" isRequired />. Players must use all of
        the required letters in each correct answer.
      </SettingDescription>

      <SettingSubheader>Search for Words</SettingSubheader>
      <SettingDescription>
        Press SEARCH FOR WORDS and a suggested list of answers will be
        displayed.
      </SettingDescription>

      <SettingSubheader>Special, Pangram, and Longest Words</SettingSubheader>
      <SettingDescription>
        Each word in the list of answers may be followed by one or more the the
        following icons, which means they are eligible for extra points based on
        the scoring settings:
        <ul>
          <li>
            <PangramIcon fontSize="small" style={{ marginRight: "0.2rem" }} />
            Pangram. This word uses all of the letters (possibly more than once)
          </li>
          <li>
            <LongestIcon fontSize="small" style={{ marginRight: "0.2rem" }} />
            Longest. This word is as long or longer than any other answer word.
          </li>
          <li>
            <SpecialIcon fontSize="small" style={{ marginRight: "0.2rem" }} />
            Special. You made this word special. Click the gear icon{" "}
            <GearIcon /> to make a word special.
          </li>
        </ul>
      </SettingDescription>

      <SettingSubheader>Actions on Found Words</SettingSubheader>
      <SettingDescription>
        Click the gear icon <GearIcon /> to do one of the following:
        <ul>
          <li>
            Lookup Word. Opens another tab and runs a Google search on the word.
          </li>
          <li>Remove Word. Removes the word from the list of found words.</li>
          <li>
            Make Special. Marks this word as being special, which gives it extra
            points.
          </li>
          <li>
            Add to Hidden Words (cannot undo) and Remove. Removes the word from
            the list of found words, and prevents it from appearing when you
            search for words -- by adding the word to your hidden words list. If
            you press undo afterward, the word is added back to the list of
            found words, but remains on the hidden words list. If you wish to
            remove it from the hidden words list, you must do so on the Hidden
            Words page.
          </li>
          <li>
            Add to Extra Words (cannot undo). Adds the word to your extra words
            list. If you wish to remove it from the extra words list, you must
            do so on the Hidden Words page. Words in the extra words list appear
            when searching for words.
          </li>
        </ul>
      </SettingDescription>

      <SettingSubheader>Add Additional Words or Remove Words</SettingSubheader>
      <SettingDescription>
        Press ADD ADDITIONAL WORDS to add words that didn't appear when you
        pressed SEARCH FOR WORDS. Click the gear icon <GearIcon /> next to a
        word to remove it from the game, to add it to your list of extra words,
        or to add it to your list of hidden words.
      </SettingDescription>
    </SettingsContainer>
  );
};
