import { ApiResponseHeader } from "../../../domain/serverContract";
import { makeRequest } from "../../../http/http";

export interface RegisterNewUserRequest {
  email: string;
  displayName: string;
  recaptcha: string;
}

export interface RegisterNewuserResponse extends ApiResponseHeader {}

export async function registerNewUser(
  request: RegisterNewUserRequest
): Promise<RegisterNewuserResponse> {
  return makeRequest(request, "/api/register_new_user");
}
