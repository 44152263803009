import { FormControl, makeStyles, Select } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Switch from "@material-ui/core/Switch";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import MaterialTable from "material-table";
import * as React from "react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { busyPromise } from "../../../components/BusySpinner";
import { ColumnContainer } from "../../../components/ColumnContainer";
import { openConfirmationDialog } from "../../../components/ConfirmationDialog";
import { getDialogMethods } from "../../../components/dialogTools/DialogManager";
import { useHelp } from "../../../components/HelpDialog";
import {
  notifyError,
  notifySuccess,
} from "../../../components/NotificationManager";
import { PopupMenu } from "../../../components/PopupMenu";
import { RowContainer } from "../../../components/RowContainer";
import SettingDescription from "../../../components/SettingDescription";
import SettingsContainer from "../../../components/SettingsContainer";
import SettingSubheader from "../../../components/SettingSubheader";
import { TellMeMore } from "../../../components/TellMeMore";
import { getGameUrl } from "../../../domain/gameUrl";
import { GameAbstract } from "../../../domain/serverContract";
import {
  GameType,
  getGameTypeIcon,
  getGameTypeLabel,
} from "../../../domain/types";
import { FmForm, FmFormRenderProps } from "../../../formManager/FmForm";
import { makeEmptyTrashDialog } from "../games/shared/components/makeEmptyTrashDialog";
import { deleteGame } from "../requests/deleteGame";
import {
  gameAbstractsRecord,
  getAbstractsVersion,
  getUniqueName,
  registerTimestampChangeHandler,
} from "../requests/manageGameAbstracts";
import { publishGame } from "../requests/publishGame";
import { templateGame } from "../requests/templateGame";
import { trashGame } from "../requests/trashGame";
import { untrashGame } from "../requests/untrashGame";
import { openAddGameDialog } from "./AddGameDialog";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: "6rem",
    margin: "0.5rem",
  },
}));

export interface GamesPageProps {}

const tmm_outdated = "The game was modified after it was last published.";

const MoreActions = (props: {
  gameId: string;
  gameName: string;
  gameType: GameType;
  trashed: boolean;
  isPublished: boolean;
  isTemplate: boolean;
}) => {
  return (
    <Grid container alignItems="center" justify="center">
      <PopupMenu
        menuElements={(pmProps) => {
          return [
            <MenuItem
              key="play"
              onClick={async () => {
                if (!props.isPublished) {
                  notifyError("Publish the game before trying to play it.");
                  return;
                }
                const url = await getGameUrl(
                  props.gameType,
                  props.gameId,
                  props.gameName
                );
                pmProps.close();
                window.open(url);
              }}
            >
              Play
            </MenuItem>,
            <MenuItem
              key="copyurl"
              onClick={async () => {
                const url = await getGameUrl(
                  props.gameType,
                  props.gameId,
                  props.gameName
                );
                navigator.clipboard.writeText(url);
                notifySuccess("Copied");
                pmProps.close();
              }}
            >
              Copy Game URL to Clipboard
            </MenuItem>,
            <MenuItem
              key="clone"
              onClick={() => {
                openAddGameDialog({
                  templateGameRef: props.gameId, // use this game as the template
                  title: `Create Game (copy of ${props.gameName})`,
                });
                pmProps.close();
              }}
            >
              Clone and Edit
            </MenuItem>,
            <MenuItem
              key="maketemplate"
              onClick={async () => {
                busyPromise(
                  templateGame({
                    id: props.gameId,
                    template: !props.isTemplate,
                  }).catch((reason) => {
                    notifyError(reason.message);
                  })
                );
                pmProps.close();
              }}
            >
              {props.isTemplate ? "Clear Template" : "Make Template"}
            </MenuItem>,
            <MenuItem
              key="delete"
              onClick={async () => {
                if (props.trashed) {
                  const r = await openConfirmationDialog({
                    title: "Delete Game Permanently",
                    okayText: "Delete Permanently",
                    content: (
                      <div>
                        When you permanently delete a game from the trash, you
                        also unpublish the game so that it can no longer be
                        played. This action is not reversible. Press "Cancel" to
                        go back without making any chnages.
                      </div>
                    ),
                  });
                  if (r) {
                    busyPromise(
                      deleteGame({ id: props.gameId }).catch((error) => {
                        notifyError(error.message);
                      })
                    );
                  }
                  return;
                }
                busyPromise(
                  trashGame({ id: props.gameId }).catch((error) => {
                    notifyError(error.message);
                  })
                );
                pmProps.close();
              }}
            >
              {props.trashed ? "Delete Permanently" : "Delete"}
            </MenuItem>,
            props.trashed && (
              <MenuItem
                key="undelete"
                onClick={async () => {
                  pmProps.close();
                  if (!props.trashed) return;
                  busyPromise(
                    untrashGame({
                      id: props.gameId,
                      name: getUniqueName(props.gameName),
                    }).catch((error) => {
                      notifyError(error.message);
                    })
                  );
                }}
              >
                Undelete
              </MenuItem>
            ),
          ];
        }}
      >
        {(pmProps) => (
          <IconButton size="small">
            <MoreVertIcon fontSize="small" />
          </IconButton>
        )}
      </PopupMenu>
    </Grid>
  );
};

type GameTypeFilter = GameType | "any";

interface FormData {
  fmFormDataVersion: number; // required by FmForm
  gameAbstractsVersion: string;
  gameAbstracts: GameAbstract[];
  gameTypeFilter: GameTypeFilter;
}

const fetchHandler = async (): Promise<FormData> => {
  const record = await gameAbstractsRecord();
  const gameAbstracts = record.gameAbstracts
    ? Object.values(record.gameAbstracts)
    : [];
  return {
    fmFormDataVersion: undefined,
    gameAbstractsVersion: getAbstractsVersion(),
    gameTypeFilter: "any",
    // Copy abstracts.
    // immer will make the props read only when setting form data.
    // managerGameAbstract doesn't use immer to mutate, so it's updates would fail.
    gameAbstracts: gameAbstracts.map((abstract) => {
      return { ...abstract };
    }),
  };
};

export const GamesPage: React.FunctionComponent<GamesPageProps> = (props) => {
  useHelp("GamesPage", <MyHelp />, true);
  return (
    <FmForm
      name="GamesPage"
      suppressPrompt
      fetch={{
        handler: fetchHandler,
        trigger: () => getAbstractsVersion(),
      }}
      onSubmit={() => Promise.resolve()}
    >
      {(fmFormRenderProps) => {
        return <RenderedFormChild fmFormRenderProps={fmFormRenderProps} />;
      }}
    </FmForm>
  );
};

const RenderedFormChild = (props: {
  fmFormRenderProps: FmFormRenderProps<FormData>;
}) => {
  const classes = useStyles();
  const fmFormRenderProps = props.fmFormRenderProps;
  const { formData, setFormData } = fmFormRenderProps;
  const history = useHistory();
  const [showDeleted, setShowDeleted] = useState(false);
  useEffect(() => {
    return registerTimestampChangeHandler((version) => {
      setFormData((draft) => {
        draft.gameAbstractsVersion = version;
      });
    });
  }, []);
  useEffect(() => {
    makeEmptyTrashDialog();
    return;
  }, []);

  const publish = (rowData: GameAbstract) => {
    busyPromise(
      publishGame({ id: rowData.id, gameType: rowData.gameType }).catch(
        (error) => {
          notifyError(error.message);
        }
      )
    );
  };

  const numtrashed = formData.gameAbstracts.reduce((sum, abstract) => {
    if (abstract.trashed && abstract.deleted !== true) sum++;
    return sum;
  }, 0);

  const trashSwitch = (
    <FormControlLabel
      control={
        <Switch
          checked={showDeleted}
          name={name}
          color="primary"
          onChange={(event, checked) => {
            setShowDeleted(checked);
          }}
        />
      }
      label={`Show Trash (${numtrashed})`}
    />
  );
  const selectGameType = (
    <FormControl className={classes.formControl}>
      <Select
        labelId="gameTypeLabel"
        placeholder="Game Type"
        value={formData.gameTypeFilter}
        onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
          setFormData((draft) => {
            draft.gameTypeFilter = event.target.value as GameTypeFilter;
          });
        }}
      >
        <MenuItem value={"any"}>Any Game</MenuItem>
        <MenuItem value={"s"}>Spell</MenuItem>
        <MenuItem value={"c"}>Compute</MenuItem>
        <MenuItem value={"t"}>Twist</MenuItem>
      </Select>
    </FormControl>
  );
  const tableData = formData.gameAbstracts
    // Never show deletes. Show trashed only when asked.
    .filter(
      (abstract) =>
        abstract.deleted !== true &&
        (abstract.trashed ?? false) === showDeleted &&
        (formData.gameTypeFilter === "any" ||
          formData.gameTypeFilter === abstract.gameType)
    )
    .map((abstract) =>
      Object.assign(Object.create(null) as GameAbstract, abstract)
    );
  return (
    <MaterialTable
      title={`Games ${showDeleted ? " (showing trash)" : ""}`}
      columns={[
        {
          title: "Actions",
          field: "actions",
          width: "6rem",
          render: (rowData) => {
            return (
              <div style={{ display: "flex", alignContent: "center" }}>
                <IconButton
                  size="small"
                  onClick={() => {
                    history.push(`/games/edit/${rowData.id}`);
                  }}
                >
                  <EditIcon />
                </IconButton>
                <MoreActions
                  gameId={rowData.id}
                  gameName={rowData.name}
                  gameType={rowData.gameType}
                  trashed={rowData.trashed}
                  isPublished={rowData.published != null}
                  isTemplate={rowData.template}
                />
              </div>
            );
          },
        },
        {
          title: "Name",
          field: "name",
          cellStyle: {
            wordBreak: "normal",
          },
          render: (rowData) => {
            if (rowData.template) {
              return (
                <ColumnContainer>
                  {rowData.name}
                  <div>(template)</div>
                </ColumnContainer>
              );
            }
            return <div>{rowData.name}</div>;
          },
          customFilterAndSearch: (searchText: string, rowData) => {
            return match(searchText, rowData);
          },
        },
        {
          title: "Type",
          field: "gameType",
          cellStyle: {
            wordBreak: "normal",
          },
          render: (rowData) => {
            return (
              <Tooltip title={getGameTypeLabel(rowData.gameType)}>
                {getGameTypeIcon(rowData.gameType)}
              </Tooltip>
            );
          },
        },
        {
          title: "Title",
          field: "title",
          cellStyle: {
            wordBreak: "normal",
          },
        },
        {
          title: "Subtitle",
          field: "subtitle",
          cellStyle: {
            wordBreak: "normal",
          },
        },
        {
          title: "Description",
          field: "description",
          cellStyle: {
            wordBreak: "normal",
          },
        },
        {
          title: (
            <RowContainer>
              Players
              <TellMeMore icon>
                The number of players is approximate. One is added whenever a
                user loads a different game (of the same or different game type)
                on a different device. So a user will be counted more than once
                if they play on more than one device, or go back to play a
                previous game.
              </TellMeMore>
            </RowContainer>
          ),
          field: "plays",
        },
        {
          title: "Modified",
          field: "modified",
          type: "datetime",
          defaultSort: "desc",
          customSort: (a1, a2) => {
            return (
              new Date(a1.modified).getTime() - new Date(a2.modified).getTime()
            );
          },
        },
        {
          title: "Published",
          field: "published",
          type: "datetime",
          customSort: (a1, a2) => {
            if (a1.published === a2.published) return 0;
            if (a1.published == null) return 1;
            if (a2.published == null) return -1;
            return (
              new Date(a1.published).getTime() -
              new Date(a2.published).getTime()
            );
          },
          render: (rowData) => {
            if (rowData.published) {
              const t = new Date(rowData.published).toLocaleString();
              const mt = new Date(rowData.modified);
              const pt = new Date(rowData.published);
              if (mt <= pt) {
                return t;
              } else {
                return (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div>{t}</div>
                    <div>
                      <Button
                        size="small"
                        onClick={() => {
                          publish(rowData);
                        }}
                      >
                        Publish
                      </Button>
                      <TellMeMore icon message={tmm_outdated} />
                    </div>
                  </div>
                );
              }
            }
            return (
              <div>
                <div>Unpublished</div>
                <Button
                  size="small"
                  onClick={() => {
                    publish(rowData);
                  }}
                >
                  Publish
                </Button>
              </div>
            );
          },
        },
        {
          title: "Created",
          field: "created",
          type: "datetime",
          customSort: (a1, a2) => {
            return (
              new Date(a1.created).getTime() - new Date(a2.created).getTime()
            );
          },
        },
      ]}
      data={tableData}
      options={{
        search: true,
        sorting: true,
      }}
      localization={{
        body: {
          emptyDataSourceMessage: showDeleted
            ? "No games to display"
            : "No games to display (press Create Game to create one!)",
        },
      }}
      actions={[
        {
          icon: () => selectGameType,
          isFreeAction: true,
          onClick: () => {},
        },
        {
          icon: () => trashSwitch,
          tooltip: "Show Only Trash",
          isFreeAction: true,
          onClick: () => {},
        },
        {
          icon: "deleteForeverIcon",
          tooltip: "Empty Trash",
          isFreeAction: true,
          hidden: !showDeleted || numtrashed == 0,
          onClick: () => {
            getDialogMethods().openByName("EmptyTrashDialog");
          },
        },
      ]}
    />
  );
};

const match = (searchText: string, gameAbstract: GameAbstract) => {
  const tokens = searchText.split(" ");
  for (let tokenIndex = 0; tokenIndex < tokens.length; tokenIndex++) {
    const token = tokens[tokenIndex].toLowerCase();
    if (token.length === 0) continue;

    if (gameAbstract.name.toLowerCase().includes(token)) continue;
    if (gameAbstract.title.toLowerCase().includes(token)) continue;
    if (gameAbstract.subtitle?.toLowerCase().includes(token)) continue;
    if (gameAbstract.description?.toLowerCase().includes(token)) continue;

    // Lets user find templates by searching for "(template)"
    if (gameAbstract.template && token === "template") continue;

    if (
      new Date(gameAbstract.modified)
        .toLocaleString()
        .toLowerCase()
        .indexOf(token) >= 0
    )
      continue;

    if (
      (gameAbstract.published
        ? new Date(gameAbstract.published).toLocaleString().toLowerCase()
        : "unpublished"
      ).indexOf(token) >= 0
    )
      continue;

    if (
      new Date(gameAbstract.created)
        .toLocaleString()
        .toLowerCase()
        .indexOf(token) >= 0
    )
      continue;

    // If we get here, the token didn't match
    return false;
  }
  return true;
};

const VMenu = () => <MoreVertIcon fontSize="small" />;

const MyHelp = () => {
  return (
    <SettingsContainer>
      <SettingSubheader>Find a Game</SettingSubheader>
      <SettingDescription>
        To find a game, either click a column title to sort or type search text
        in the search box. By default, the most recently modified games are on
        top, which makes it easy to find recently modified games.
      </SettingDescription>
      <SettingSubheader>Edit a Game</SettingSubheader>
      <SettingDescription>
        To edit a game, click <EditIcon />. The game will open in the editor so
        that you can change it.
      </SettingDescription>
      <SettingSubheader>Publish a Game</SettingSubheader>
      <SettingDescription>
        If a game has not been published, or if it was modified after being
        publish, then a PUBLISH button appears in the Published column of the
        table. To publish the game, click PUBLISH. To get the game URL to share,
        click <VMenu /> and choose Copy Game URL to Clipboard.
      </SettingDescription>
      <SettingSubheader>How Much Has My Game Been Played?</SettingSubheader>
      <SettingDescription>
        Look at the number in the Players column. That's an estimate of the
        number of different people who played that game. Players will be counted
        more than once if they play the game on different devices or in
        different browsers.
      </SettingDescription>
      <SettingSubheader>Copy a Game</SettingSubheader>
      <SettingDescription>
        To copy a game, click <VMenu /> and choose Clone and Edit. The game will
        open in the editor so that you can change it.
      </SettingDescription>
      <SettingSubheader>Play a Game</SettingSubheader>
      <SettingDescription>
        To delete a game, click <VMenu /> and choose Play. The game will open in
        a new browser tab. You must publish a game to play it.
      </SettingDescription>
      <SettingSubheader>Delete a Game</SettingSubheader>
      <SettingDescription>
        To delete a game, click <VMenu /> and choose Delete. The game will be
        moved to the trash. You can restore games in the trash. Players may
        continue to play deleted games.
      </SettingDescription>
      <SettingSubheader>Restore a Deleted</SettingSubheader>
      <SettingDescription>
        Click SHOW TRASH to see the deleted games. To restore a game, click
        <VMenu /> and choose Undelete.
      </SettingDescription>
      <SettingSubheader>Permanently Delete a Game</SettingSubheader>
      <SettingDescription>
        Click SHOW TRASH to see the deleted games. Click
        <VMenu /> and choose Delete Permanently. The game and all share progress
        will be permanently deleted. There is no way to restore permanently
        deleted games. Once a game is permanently deleted, it can no longer be
        played. You can permanently deleted all games in the trash by emptying
        the trash <DeleteIcon />.
      </SettingDescription>
    </SettingsContainer>
  );
};
