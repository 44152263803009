import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import * as React from "react";
import { useEffect, useState } from "react";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import { GamesIcon } from "../../../components/icons/GamesIcon";
import { LibraryIcon } from "../../../components/icons/LibraryIcon";
import { notifyError } from "../../../components/NotificationManager";
import { Title } from "../../../components/Title";
import { openImportGameDialog } from "../games/shared/components/ImportGameDialog";
import { openAddGameDialog } from "./AddGameDialog";
import { CreateGameButton } from "./CreateGameButton";
import { EditPage } from "./EditPage";
import { GamesPage } from "./GamesPage";
import { ImportGameButton } from "./ImportGameButton";
import { LibraryPage } from "./LibraryPage";
import { TemplatesPage } from "./TemplatesPage";
import { TopicFrame } from "./TopicFrame";

const drawerWidth = 240;
const useStyles = makeStyles((theme: Theme) => {
  const c = createStyles({
    title: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
      // position: "static",
    },
    drawerContainer: {
      overflow: "auto",
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
    // Loads information about the app bar, including app bar height
    toolbar: theme.mixins.toolbar,
  });
  return c;
});

enum Page {
  Games,
  Templates,
  Library,
  Edit,
}

export interface GamesTopicProps {}

export const GamesTopic: React.FunctionComponent<GamesTopicProps> = (props) => {
  const classes = useStyles();
  const [editorToolbar, setEditorToolbar] = useState<JSX.Element>(null);
  const [templatesToolbar, setTemplatesToolbar] = useState<JSX.Element>(
    <Title>Templates</Title>
  );
  const history = useHistory();
  let { path } = useRouteMatch();

  const leftNav = (
    <List>
      <ListItem button onClick={() => history.push(`${path}/games`)}>
        <ListItemIcon>
          <GamesIcon />
        </ListItemIcon>
        <ListItemText primary="Games" />
      </ListItem>
      <ListItem button onClick={() => history.push(`${path}/templates`)}>
        <ListItemIcon>
          <AddIcon />
        </ListItemIcon>
        <ListItemText primary="Templates" />
      </ListItem>
      <ListItem button onClick={() => history.push(`${path}/library`)}>
        <ListItemIcon>
          <LibraryIcon />
        </ListItemIcon>
        <ListItemText primary="Library" />
      </ListItem>
    </List>
  );

  const gamesToolbar = (
    <Toolbar>
      <Typography variant="h6" className={classes.title}>
        Games
      </Typography>
      <CreateGameButton
        onClick={() => openAddGameDialog({ title: "Create Game" })}
      >
        Create Game
      </CreateGameButton>
      <ImportGameButton onClick={() => openImportGameDialog({})}>
        Import Game
      </ImportGameButton>
    </Toolbar>
  );

  const [page, setPage] = useState(Page.Games);

  // A path of /games/edit/* goes to the editor
  useEffect(() => {
    const locpath = history.location.pathname;
    switch (locpath) {
      case `${path}/games`:
      case `${path}`:
        setPage(Page.Games);
        break;
      case `${path}/templates`:
        setPage(Page.Templates);
        break;
      case `${path}/library`:
        setPage(Page.Library);
        break;
      default:
        if (locpath.startsWith(`${path}/edit`)) {
          setPage(Page.Edit);
        } else {
          notifyError("Could not find page.");
        }
        break;
    }
  }, [history.location.pathname]);
  return (
    <>
      <TopicFrame
        appIcon={
          <div
            onClick={() => {
              history.push(`${path}/games`);
            }}
          >
            <GamesIcon />
          </div>
        }
        appIconMenuText="Games"
        leftNav={leftNav}
        toolbar={
          page === Page.Games ? (
            gamesToolbar
          ) : page === Page.Templates ? (
            <Toolbar>{templatesToolbar}</Toolbar>
          ) : (
            editorToolbar
          )
        }
      >
        <Switch>
          <Route
            exact
            path={`${path}`}
            render={(routeProps) => {
              return (
                <>
                  <GamesPage />
                </>
              );
            }}
          />
          <Route
            path={`${path}/games`}
            render={(routeProps) => {
              return <GamesPage />;
            }}
          />
          <Route
            path={`${path}/templates`}
            render={(routeProps) => {
              return <TemplatesPage />;
            }}
          />
          <Route
            path={`${path}/library`}
            render={(routeProps) => {
              return <LibraryPage />;
            }}
          />
          <Route
            path={[`${path}/edit`]}
            render={(routeProps) => {
              return <EditPage setToolbar={setEditorToolbar} />;
            }}
          />
        </Switch>
      </TopicFrame>
    </>
  );
};
