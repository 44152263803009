import * as React from "react";
import { ColumnContainer } from "../../../../../components/ColumnContainer";
import { useHelp } from "../../../../../components/HelpDialog";
import Hider from "../../../../../components/Hider";
import SettingHeader from "../../../../../components/SettingHeader";
import { Tip } from "../../../../../components/Tip";
import { AutoScoringHelp } from "../../shared/components/AutoScoringHelp";
import { Ladder } from "../../shared/components/Ladder";
import { FormData } from "./TwistEditPage";

const TwistEditScoringTabInternal = (props: {
  formData: FormData;
  isActive: boolean;
}) => {
  useHelp("TwistEditScoringTab", <MyHelp />, props.isActive);
  const { formData } = props;
  return (
    <Hider hidden={!props.isActive}>
      <ColumnContainer center>
        <Tip>
          Many authors skip this step, since the automatically generated scoring
          is pretty good.
        </Tip>
        <Ladder
          levels={formData.levels}
          highScore={formData.challenges?.length}
          autoScoring={formData.autoScoring}
          autoScoringMethod={formData.autoScoringMethod}
        />
      </ColumnContainer>
    </Hider>
  );
};

export const TwistEditScoringTab = React.memo(
  TwistEditScoringTabInternal,
  (prevProps, nextProps) => {
    if (prevProps.isActive !== nextProps.isActive) return false;
    if (prevProps.formData.levels !== nextProps.formData.levels) return false;
    if (prevProps.formData.autoScoring !== nextProps.formData.autoScoring)
      return false;
    if (
      prevProps.formData.autoScoringMethod !==
      nextProps.formData.autoScoringMethod
    )
      return false;
    return true;
  }
);

const MyHelp = () => {
  return (
    <>
      <SettingHeader>Create Scoring</SettingHeader>
      <AutoScoringHelp />
    </>
  );
};
