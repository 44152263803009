import {
  ApiRequest,
  ValidatedApiResponse,
} from "../../../domain/serverContract";
import { makeCacheValidatedRequest } from "./makeCacheValidatedRequest";
import { untrashAbstract } from "./manageGameAbstracts";

export interface UntrashGameRequest extends ApiRequest {
  id: string;
  name: string;
}

export interface UntrashGameResponse extends ValidatedApiResponse {}

export async function untrashGame(
  request: UntrashGameRequest
): Promise<UntrashGameResponse> {
  return makeCacheValidatedRequest<UntrashGameResponse>(
    request,
    "/api/untrash_game"
  ).then((response) => {
    untrashAbstract(request.id, response.timestamp, request.name);
    return response;
  });
}
