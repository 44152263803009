import Grow from "@material-ui/core/Grow";
import IconButton from "@material-ui/core/IconButton";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import * as React from "react";
import { useRef } from "react";
import { RowContainer } from "../../../../../components/RowContainer";
import { Challenge } from "../../../../../domain/twist_types";
import { FmTextField } from "../../../../../formManager/FmField";
import { FmList, FmListRenderProps } from "../../../../../formManager/FmList";
import { serialId } from "../../../../../utilities";
import { openEditChallengeDialog } from "./EditChallengeDialog";
import { FormData } from "./TwistEditPage";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    tile: {
      border: "solid",
      marginBottom: "0.25rem",
      paddingLeft: "0.5rem",
      paddingRight: "0.5rem",
    },
    tileField: { margin: "0.25rem 10px 0.25rem 10px" },
  });
});

export interface FormChallenge extends Challenge {
  id: number;
}

export interface ChallengeTableProps {
  challenges: Challenge[];
}
let i = 0;
const ChallengeTableInternal = (props: ChallengeTableProps) => {
  const highestRenderedIdRef = useRef(-1);
  return (
    <FmList<FormData, FormChallenge>
      name="challenges"
      reorder={(
        draftFormData: FormData,
        originalIndex: number,
        newIndex: number
      ) => {
        const moving = draftFormData.challenges[originalIndex];
        draftFormData.challenges = [
          ...draftFormData.challenges.slice(0, originalIndex),
          ...draftFormData.challenges.slice(originalIndex + 1),
        ];
        draftFormData.challenges = [
          ...draftFormData.challenges.slice(0, newIndex),
          moving,
          ...draftFormData.challenges.slice(newIndex),
        ];
      }}
      showAddButton
      addButtonLabel="Add Challenge"
      minItems={0}
      itemAction={async (itemActionRenderProps) => {
        // add is the only action
        const { item, items, position, setFormData } = itemActionRenderProps;
        const r = await openEditChallengeDialog({
          challenge: { solution: "", fixed: "", fillin: "", clue: "" },
          solutions: props.challenges.map((c) => c.solution),
        });
        if (!r.isOkay) return;
        const c = r.result;
        const challenge: FormChallenge = {
          solution: c.solution,
          clue: c.clue,
          fixed: c.fixed,
          fillin: c.fillin,
          id: serialId(),
        };
        setFormData((draftFormData) => {
          draftFormData.challenges = [
            ...draftFormData.challenges.slice(0, position + 1),
            challenge,
            ...draftFormData.challenges.slice(position + 1),
          ];
        });
      }}
    >
      {(fmListRenderProps: FmListRenderProps<FormData, FormChallenge>) => {
        const highestRenderedId = highestRenderedIdRef.current;
        if (fmListRenderProps.value.id > highestRenderedId)
          highestRenderedIdRef.current = fmListRenderProps.value.id;
        return (
          <ChallengeTile
            challenge={fmListRenderProps.value}
            solutions={props.challenges.map((c) => c.solution)}
            setValue={fmListRenderProps.setFormDataValue}
            remove={fmListRenderProps.removeItem}
            addBelow={() => fmListRenderProps.itemAction("add")}
            highestRenderedId={highestRenderedId}
          />
        );
      }}
    </FmList>
  );
};

export const TwistChallengeTable = React.memo(
  ChallengeTableInternal,
  (prevProps, nextProps) => {
    if (prevProps.challenges !== nextProps.challenges) return false;
    return true;
  }
);

interface ChallengeTileProps {
  challenge: FormChallenge;
  setValue: (challenge: FormChallenge) => void;
  remove: () => void;
  addBelow: () => void;
  highestRenderedId: number;
  solutions: string[];
}
const ChallengeTile = (props: ChallengeTileProps) => {
  const {
    challenge,
    setValue,
    remove,
    addBelow,
    highestRenderedId,
    solutions,
  } = props;
  const classes = useStyles();
  const animate = challenge.id > highestRenderedId;
  return (
    <Grow
      key={challenge.id}
      in={true}
      style={{ transformOrigin: "0 0 0" }}
      {...(animate ? { timeout: 1000 } : {})}
    >
      <RowContainer className={classes.tile} center>
        <IconButton
          size="small"
          onClick={async () => {
            const r = await openEditChallengeDialog({ challenge, solutions });
            if (r.isOkay) {
              setValue({ ...r.result, id: challenge.id });
            }
          }}
        >
          <EditIcon />
        </IconButton>
        <FmTextField<FormData>
          className={classes.tileField}
          readOnly
          valueProxyFuncs={{
            get: () => challenge.solution,
            mutate: (value) => {},
          }}
          labelText="Solution"
        />

        <FmTextField<FormData>
          className={classes.tileField}
          readOnly
          valueProxyFuncs={{
            get: () => challenge.fixed,
            mutate: (value) => {},
          }}
          labelText="Pre-filled Letters"
        />
        <FmTextField<FormData>
          className={classes.tileField}
          readOnly
          valueProxyFuncs={{
            get: () => challenge.fillin,
            mutate: (value) => {},
          }}
          labelText="Fill-in Letters"
        />
        <FmTextField<FormData>
          valueProxyFuncs={{
            get: () => challenge.clue,
            mutate: (value) => {
              setValue({
                clue: value,
                fillin: challenge.fillin,
                fixed: challenge.fixed,
                id: challenge.id,
                solution: challenge.solution,
              });
            },
          }}
          labelText="Clue"
          maxLength={250}
        />

        <Tooltip title="Add challenge below" aria-label="add challenge below">
          <IconButton
            color="primary"
            aria-label="add challenge below"
            size="small"
            onClick={() => addBelow()}
          >
            <AddIcon
              fontSize="small"
              style={{
                marginLeft: ".5rem",
              }}
            />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete challenge" aria-label="delete challenge">
          <IconButton
            color="primary"
            aria-label="delete challenge"
            size="small"
            onClick={() => {
              remove();
            }}
          >
            <DeleteIcon
              fontSize="small"
              style={{
                marginLeft: ".5rem",
              }}
            />
          </IconButton>
        </Tooltip>
      </RowContainer>
    </Grow>
  );
};
