import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/AddBoxRounded";
import * as React from "react";

interface CreateGameButtonProps {
  onClick?: () => void;
}

export const CreateGameButton: React.FunctionComponent<CreateGameButtonProps> = (
  props
) => {
  return (
    <Button color="inherit" startIcon={<AddIcon />} onClick={props.onClick}>
      Create Game
    </Button>
  );
};
