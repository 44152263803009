import DateFnsUtils from "@date-io/date-fns";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { initializeApp } from "firebase/app";
import { enableMapSet, enablePatches } from "immer";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { DialogManager } from "../../components/dialogTools/DialogManager";
import { NotificationManager } from "../../components/NotificationManager";
import { RouterTop } from "./components/RouterTop";

enableMapSet();
const logoBlue = "#0055aa";
const logoYellow = "#FFCB05";
const logoFontSize = 110;
const logoFontName = "Assistant";

const theme = createMuiTheme({
  palette: {
    primary: {
      // light: "#fff",
      main: "#0055aa",
      // dark: "#000",
    },
    secondary: {
      main: "#FFCB05",
    },
  },
});

// Initialize Identity Platform
// CP_IDENTITY is defined in the start-up html file.
declare var CP_IDENTITY: string;
const identityTokens = CP_IDENTITY.split(",");
const config = {
  apiKey: identityTokens[0],
  authDomain: identityTokens[1],
};

initializeApp(config);
enablePatches();

ReactDOM.render(
  <>
    <CssBaseline />
    <NotificationManager />
    <MuiThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <BrowserRouter>
          <DialogManager>
            <RouterTop />
          </DialogManager>
        </BrowserRouter>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  </>,
  document.getElementById("main")
);
