import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import * as React from "react";
const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    container: {
      marginLeft: "1rem",
      marginRight: "1rem",
      display: "flex",
      flexDirection: "column",
      height: "100%",
    },
  });
});
interface SettingsContainerProps {
  horizontalAlignment?: "center" | "flex-start";
}

const SettingsContainer: React.FunctionComponent<SettingsContainerProps> = (
  props
) => {
  const classes = useStyles();
  return (
    <div
      className={classes.container}
      style={{ alignItems: props.horizontalAlignment ?? "flex-start" }}
    >
      {props.children}
    </div>
  );
};

export default SettingsContainer;
