import { AppError, AppErrorCode } from "../../../domain/AppError";
import {
  ApiRequest,
  ValidatedApiResponse,
} from "../../../domain/serverContract";
import { makeRequestWithAuthentication } from "../../../http/authenticated";
import {
  bustGameAbstractsRecordCache,
  gameAbstractsRecord,
  updateAbstractsVersion,
} from "./manageGameAbstracts";

// makeCacheValidatedRequest sends the abstract timestamp to the server as part of the request.
// It handles ErrAbstractNotCurrent with an appropriate error message,
// and it busts the cache.
export const makeCacheValidatedRequest = async <
  TResponse extends ValidatedApiResponse
>(
  request: ApiRequest,
  path: string
) => {
  const record = await gameAbstractsRecord();
  const vrequest = { ...request, version: record.version };
  return makeRequestWithAuthentication<TResponse>(vrequest, path)
    .then((response) => {
      updateAbstractsVersion(response.version);
      return response;
    })
    .catch((error) => {
      if (
        error instanceof AppError &&
        error.code === AppErrorCode[AppErrorCode.ErrAbstractNotCurrent]
      ) {
        // Need to re-fetch the abstracts
        bustGameAbstractsRecordCache();
        throw new AppError(
          [
            "Error: you may be making changes from different devices or browser tabs at the same time.",
            "Refresh the page to get current data, and then retry your changes.",
          ].join(" "),
          error.code,
          error.isExpected
        );
      }
      throw error;
    });
};
