import { Divider } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import * as React from "react";
import { useState } from "react";
import { SubtleButton } from "../buttons";

export interface InfoDialogProps {
  title: string;
}

export const InfoDialog: React.FunctionComponent<InfoDialogProps> = (props) => {
  const [open, setOpen] = useState(true);
  return (
    <InfoDialogBase
      onClose={() => setOpen(false)}
      open={open}
      title={props.title}
    >
      {props.children}
    </InfoDialogBase>
  );
};

export interface InfoDialogBaseProps {
  open: boolean;
  onClose: (isOkay: boolean, result: unknown) => void;
  title: string;
}

export const InfoDialogBase: React.FunctionComponent<InfoDialogBaseProps> = (
  props
) => {
  const { onClose, open, title } = props;

  const handleClose = () => {
    onClose(true, undefined);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="info-dialog-title"
      open={open}
      keepMounted
    >
      <DialogTitle id="simple-dialog-title">{title}</DialogTitle>
      <Divider variant="middle" />
      <DialogContent>{props.children}</DialogContent>
      <DialogActions>
        <SubtleButton onClick={() => handleClose()}>{"Ok"}</SubtleButton>
      </DialogActions>
    </Dialog>
  );
};
