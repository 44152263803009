import { makeRequestWithAuthentication } from "../../../../http/authenticated";
import { ApiResponseHeader } from "../../../../domain/serverContract";

export interface SetExpiryRequest {
  tuid: string;
  expiry: Date; // undefined value clears expiry
}

export interface SetExpiryResponse extends ApiResponseHeader {}

export async function setExpiry(
  request: SetExpiryRequest
): Promise<SetExpiryResponse> {
  return makeRequestWithAuthentication(request, "/api/admin/set_expiry");
}
