import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import * as React from "react";
import { ConfirmationDialogBase } from "../../../../../components/dialogTools/ConfirmationDialogBase";
import {
  Dialog,
  getDialogMethods,
  makeDialog,
} from "../../../../../components/dialogTools/DialogManager";
import { notifyError } from "../../../../../components/NotificationManager";
import SettingDescription from "../../../../../components/SettingDescription";
import { FmTextField } from "../../../../../formManager/FmField";
import { FmForm, FmFormRenderProps } from "../../../../../formManager/FmForm";
import {
  findDuplicates,
  intersection,
  makeSeparatedStringFromList,
} from "../../../../../utilities";
import { getWordsThatDoNotMatchCriteria } from "../wordFinder";

interface FormData {
  fmFormDataVersion: number; // required by FmForm
  words: string;
}

export interface AddGameWordsDialogProps {
  requiredLetters: string;
  optionalLetters: string;
  minimumWordLength: number;
  foundWords: string[];
}

export let getWordsDialogInternal: Dialog<string[], AddGameWordsDialogProps>;

export const openAddGameWordsDialog = (props: AddGameWordsDialogProps) => {
  if (!getWordsDialogInternal) {
    getWordsDialogInternal = makeAddGameWordsDialogComponent();
  }
  return getDialogMethods().open(getWordsDialogInternal, props);
};

export const makeAddGameWordsDialogComponent = () => {
  return makeDialog<string[], AddGameWordsDialogProps>({
    componentRenderer: (dialogRenderProps) => {
      const {
        minimumWordLength,
        optionalLetters,
        requiredLetters,
        foundWords,
      } = dialogRenderProps.props;
      const handleSubmit = async (
        fmFormRenderProps: FmFormRenderProps<FormData>
      ) => {
        const formData = fmFormRenderProps.formData;
        const words = formData.words.trim().replace(/\s+/g, " ").split(" ");
        if (words.length === 0 || words[0] === "")
          return Promise.reject("There are no words to add.");
        const mismatched = getWordsThatDoNotMatchCriteria(
          words,
          requiredLetters,
          optionalLetters,
          minimumWordLength
        );
        if (mismatched.length > 0) {
          return Promise.reject(
            "These words do not meet the game criteria: " +
              makeSeparatedStringFromList(mismatched)
          );
        }
        const alreadyFound = intersection(foundWords, words);
        if (alreadyFound.length > 0) {
          return Promise.reject(
            "These words are duplicates of words already found: " +
              makeSeparatedStringFromList(alreadyFound)
          );
        }

        const dups = findDuplicates(words);
        if (dups.length > 0) {
          return Promise.reject(
            "These words were entered more than once in your list: " +
              makeSeparatedStringFromList(dups)
          );
        }

        return Promise.resolve(words);
      };

      return (
        <FmForm
          name="GetWordsDialog"
          suppressSpinner
          fetch={{
            handler: () =>
              Promise.resolve({ words: "", fmFormDataVersion: undefined }),
          }}
          onSubmit={handleSubmit}
        >
          {(fmProps) => (
            <ConfirmationDialogBase
              onClose={(isOkay) => {
                if (isOkay) {
                  fmProps
                    .submit()
                    .then((response: string[]) => {
                      dialogRenderProps.close(true, response);
                      return response;
                    })
                    .catch((reason) => notifyError(reason));
                } else {
                  dialogRenderProps.close(false, undefined);
                }
              }}
              open={true}
              title={"Add Words to Game"}
              okayText={"Add Words to Game"}
            >
              <Grid container direction="column" alignItems="center">
                <SettingDescription>
                  Words you enter here will be added to this game, even if they
                  are on your hidden list.
                  <b>
                    Enter one or more words, separated by spaces or new lines.
                  </b>
                  If you search for more words or change the game criteria, then
                  you will lose the words you added, unless you press Undo.
                </SettingDescription>
                <Box m={0.5} />
                <Grid container direction="column" alignContent="center">
                  <FmTextField<FormData>
                    name="words"
                    width="100%"
                    forceToLowerCase
                    textFieldProps={{ multiline: true, rows: 6 }}
                    initialFocus
                  />
                </Grid>
              </Grid>
            </ConfirmationDialogBase>
          )}
        </FmForm>
      );
    },
  });
};
