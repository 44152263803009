import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import * as React from "react";
const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    title: {
      margin: "1rem 0 1rem 0",
    },
  });
});
export interface TitleProps {}

export const Title: React.FunctionComponent<TitleProps> = (props) => {
  const classes = useStyles();
  return (
    <Typography variant="h6" className={classes.title}>
      {props.children}
    </Typography>
  );
};

export default Title;
