import {
  ApiRequest,
  ValidatedApiResponse,
} from "../../../domain/serverContract";
import { makeCacheValidatedRequest } from "./makeCacheValidatedRequest";
import { deleteTrashedAbstracts } from "./manageGameAbstracts";
import { GameType } from "../../../domain/types";

export interface EmptyTrashRequest extends ApiRequest {}

export interface EmptyTrashResponse extends ValidatedApiResponse {}

export async function emptyTrash(
  request: EmptyTrashRequest
): Promise<EmptyTrashResponse> {
  return makeCacheValidatedRequest<EmptyTrashResponse>(
    request,
    "/api/empty_trash"
  ).then((response) => {
    deleteTrashedAbstracts();
    return response;
  });
}
