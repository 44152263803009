import { ApiResponseHeader } from "../../../domain/serverContract";
import { makeRequestWithAuthentication } from "../../../http/authenticated";

export interface CompleteRegistrationRequest {
  registrationToken: string;
  password: string;
}

export interface CompleteRegistrationResponse extends ApiResponseHeader {}

export async function completeRegistration(
  request: CompleteRegistrationRequest
): Promise<CompleteRegistrationResponse> {
  return makeRequestWithAuthentication(request, "/api/complete_registration");
}
