import {
  ApiResponse,
  GameAbstractsRecord,
} from "../../../domain/serverContract";
import { makeRequestWithAuthentication } from "../../../http/authenticated";

export interface GetLibraryAbstractsRecordRequest {}

export interface GetLibraryAbstractsRecordResponse extends ApiResponse {
  gameAbstractsRecord: GameAbstractsRecord;
}

async function getLibraryAbstractsRecord(): Promise<
  GetLibraryAbstractsRecordResponse
> {
  return makeRequestWithAuthentication({}, "/api/get_library_abstracts_record");
}

let libraryAbstractsRecordCache: GameAbstractsRecord;
let promiseCache: Promise<GameAbstractsRecord>;

export const libraryAbstractsRecord = async () => {
  if (promiseCache) return promiseCache;

  promiseCache = getLibraryAbstractsRecord()
    .then((response) => {
      if (response.gameAbstractsRecord.gameAbstracts == null) {
        response.gameAbstractsRecord.gameAbstracts = {};
      }
      // Denormalize game ID from map key
      response.gameAbstractsRecord.gameAbstracts &&
        Object.keys(response.gameAbstractsRecord.gameAbstracts).map((key) => {
          response.gameAbstractsRecord.gameAbstracts[key].id = key;
        });
      libraryAbstractsRecordCache = response.gameAbstractsRecord;
      // The cache is capture via closure, so when we update the cache we update the promise result.
      return libraryAbstractsRecordCache;
    })
    .catch((error) => {
      promiseCache = undefined;
      throw error;
    });
  return promiseCache;
};
