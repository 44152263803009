import { Toolbar } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import NavigateBefore from "@material-ui/icons/NavigateBefore";
import NavigateNext from "@material-ui/icons/NavigateNext";
import * as React from "react";
import { useEffect, useState } from "react";
import {
  MildGuidingButton,
  StrongGuidingButton,
} from "../../../../../components/buttons";
import { ColumnContainer } from "../../../../../components/ColumnContainer";
import { FormToolbarEditorButtons } from "../../../components/FormToolbarEditorButtons";
import Hider from "../../../../../components/Hider";
import { RowContainer } from "../../../../../components/RowContainer";
import { Title } from "../../../../../components/Title";
import ToolbarTitle from "../../../../../components/ToolbarTitle";
import { GameAbstract } from "../../../../../domain/serverContract";
import { FmForm, FmFormRenderProps } from "../../../../../formManager/FmForm";
import { useFormToolbar } from "../../../../../formManager/useFormToolbar";
import { getGame } from "../../../requests/getGame";
import { saveGame } from "../../../requests/saveGame";
import { round, serialId } from "../../../../../utilities";
import { EditNameGameTab } from "../../shared/components/EditNameGameTab";
import { EditPublishTab } from "../../shared/components/EditPublishTab";
import { CommonFormData, FormLevel } from "../../shared/editorTypes";

import { ComputeEditRulesPrologueTab } from "./ComputeEditRulesPrologueTab";
import { ComputeEditScoringTab } from "./ComputeEditScoringTab";
import { ComputeEditChallengesTab } from "./ComputeEditChallengesTab";
import {
  PlaySpec,
  AutoScoringMethod,
  GameType,
} from "../../../../../domain/types";
import {
  ComputeRecord,
  ComputeAuthorSpec,
  ComputePlaySpec,
  OperationDomain,
} from "../../../../../domain/compute_types";
import {
  clearLevelScoresCache,
  deriveLevelScores,
} from "../../shared/deriveLevelScores";
import { FormToolbarPublishButton } from "../../../components/FormToolbarPublishButton";

const GAMETYPE: GameType = "c";
export interface FormData extends CommonFormData {
  add: OperationDomain;
  sub: OperationDomain;
  mul: OperationDomain;
  div: OperationDomain;
  // TimeLimit is the number of seconds until the game ends.
  // If undefined, then there is no time limit.
  timeLimit: number;
  // ChallengeCount is the number of challenges in a game.
  challengeCount: number;
  // MustSolve true means each challenge must be solved before the next challenge is presented.
  mustSolve: boolean;
}

const tabNames = [
  "Name Game",
  "Choose Challenges",
  "Create Scoring (optional)",
  "Write Rules (optional)",
  "Publish",
];

const tabNavNames = [
  "Name Game",
  "Choose Challenges",
  "Create Scoring",
  "Write Rules",
  "Publish",
];

// Build gamePlaySpec from formdata
const buildPlaySpec = (formData: FormData) => {
  const playSpec: PlaySpec = {
    answerKey: formData.answerKey,
    levels: formData.levels,
    subtitle: formData.subtitle,
    title: formData.title,
    rulesPrologue: formData.rulesPrologue,
  };
  const computePlaySpec: ComputePlaySpec = {
    ...playSpec,
    challengeCount: formData.challengeCount,
    mustSolve: formData.mustSolve,
    timeLimit: formData.timeLimit,
    add: formData.add,
    sub: formData.sub,
    mul: formData.mul,
    div: formData.div,
  };
  return computePlaySpec;
};

export interface ComputeEditPageProps {
  setToolbar: (element: JSX.Element) => void;
  gameAbstract: GameAbstract;
}
export const ComputeEditPage = (props: ComputeEditPageProps) => {
  const handleSubmit = async (
    fmFormRenderProps: FmFormRenderProps<FormData>
  ) => {
    const formData = fmFormRenderProps.formData;
    const computePlaySpec = buildPlaySpec(formData);
    const authorSpec: ComputeAuthorSpec = {
      autoScoring: formData.autoScoring,
      autoScoringMethod: formData.autoScoringMethod,
      description: formData.description,
    };
    return saveGame({
      id: formData.id,
      gameSpec: { playSpec: computePlaySpec, authorSpec },
      name:
        formData.name === fmFormRenderProps.cleanFormData.name
          ? undefined
          : formData.name,
    });
  };

  const getGameFromAbstract = async (
    gameAbstract: GameAbstract
  ): Promise<FormData> => {
    const gameId = gameAbstract.id;
    return getGame({ id: gameId }).then((result) => {
      // blow away memo cache
      clearLevelScoresCache();

      const gameRecord = result.gameRecord as ComputeRecord;
      const { draft } = gameRecord;
      const name = gameAbstract.name;
      let initialLevels: FormLevel[] = [
        { name: "Novice", score: 0, id: serialId() },
        { name: "Spectacular", score: 1, id: serialId() },
      ];

      const commonFormData: CommonFormData = {
        fmFormDataVersion: 0,
        id: gameId,
        name,
        answerKey: draft.playSpec.answerKey ?? "",
        levels:
          draft.playSpec.levels?.map((level) => {
            return {
              name: level.name,
              score: level.score ?? 0,
              id: serialId(),
            };
          }) ?? initialLevels,
        rulesPrologue: draft.playSpec.rulesPrologue ?? "",
        subtitle: draft.playSpec.subtitle ?? "",
        title: draft.playSpec.title ?? "",
        autoScoring: draft.authorSpec.autoScoring ?? true,
        autoScoringMethod:
          draft.authorSpec.autoScoringMethod ?? AutoScoringMethod.Fast,
        description: draft.authorSpec.description ?? "",
        isTemplateGame: gameAbstract.template,
        isPublished: !!gameAbstract.published,
      };
      const formData: FormData = {
        ...commonFormData,
        challengeCount: draft.playSpec.challengeCount ?? 60,
        mustSolve: draft.playSpec.mustSolve ?? false,
        timeLimit: draft.playSpec.timeLimit ?? 60,
        add: draft.playSpec.add,
        sub: draft.playSpec.sub,
        mul: draft.playSpec.mul,
        div: draft.playSpec.div,
      };
      deriveLevelScores({
        levels: formData.levels,
        highScore: formData.challengeCount,
        autoScoring: formData.autoScoring,
        autoScoringMethod: formData.autoScoringMethod,
      });

      return formData;
    });
  };
  return (
    <FmForm
      name="ComputeEditPage"
      fetch={{ handler: () => getGameFromAbstract(props.gameAbstract) }}
      onSubmit={handleSubmit}
    >
      {(fmFormRenderProps) => {
        return (
          <>
            <RenderedFormChild
              fmFormRenderProps={fmFormRenderProps}
              setToolbar={props.setToolbar}
            />
          </>
        );
      }}
    </FmForm>
  );
};

const RenderedFormChild = (props: {
  fmFormRenderProps: FmFormRenderProps<FormData>;
  setToolbar: (element: JSX.Element) => void;
}) => {
  const fmFormRenderProps = props.fmFormRenderProps;
  const { formData, setFormData } = fmFormRenderProps;
  const [displayTabIndex, setDisplayTabIndex] = useState(0);
  useEffect(() => {
    // Do all derivations in the same place for now
    // If they were to independently update formData, then both updates would be based on the same initial
    // state of formData, and the second change would clobber the first.
    fmFormRenderProps.setDerivedFormData((draftFormData) => {
      deriveLevelScores({
        levels: draftFormData.levels,
        highScore: formData.challengeCount,
        autoScoring: formData.autoScoring,
        autoScoringMethod: formData.autoScoringMethod,
      });
    });
  });

  useFormToolbar(() => {
    props.setToolbar(
      <Toolbar>
        <ToolbarTitle>
          <ColumnContainer>
            <span>Edit Compute</span>
            <span style={{ fontSize: "60%" }}> {formData.name}</span>
          </ColumnContainer>
        </ToolbarTitle>
        <FormToolbarPublishButton
          fmFormRenderProps={fmFormRenderProps}
          gameId={formData.id}
          gameType={GAMETYPE}
        />
        <FormToolbarEditorButtons fmFormRenderProps={fmFormRenderProps} />
      </Toolbar>
    );
  });
  return (
    <Container>
      <RowContainer>
        <div style={{ marginRight: "auto" }}>
          <Hider hidden={displayTabIndex === 0}>
            <MildGuidingButton
              aria-label="previous"
              onClick={() =>
                setDisplayTabIndex((index) => (index === 0 ? 0 : index - 1))
              }
            >
              <NavigateBefore />
              {displayTabIndex === 0 ? "" : tabNavNames[displayTabIndex - 1]}
            </MildGuidingButton>
          </Hider>
        </div>
        <div style={{ justifySelf: "center", marginLeft: "0.5rem" }}>
          <Hider hidden={displayTabIndex === tabNames.length - 1}>
            <StrongGuidingButton
              aria-label="next"
              onClick={() =>
                setDisplayTabIndex((index) =>
                  index > tabNames.length - 1 ? tabNames.length - 1 : index + 1
                )
              }
            >
              {displayTabIndex === tabNames.length - 1
                ? ""
                : tabNavNames[displayTabIndex + 1]}
              <NavigateNext />
            </StrongGuidingButton>
          </Hider>
        </div>
      </RowContainer>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Title>{tabNames[displayTabIndex]}</Title>
      </div>

      <EditNameGameTab
        isActive={0 === displayTabIndex}
        formData={formData}
        gameType={GAMETYPE}
        showAnswerKey
      />

      <ComputeEditChallengesTab
        isActive={1 === displayTabIndex}
        formData={formData}
        setFormData={setFormData}
      />

      <ComputeEditScoringTab
        isActive={2 === displayTabIndex}
        formData={formData}
      />

      <ComputeEditRulesPrologueTab
        isActive={3 === displayTabIndex}
        rulesPrologue={formData.rulesPrologue}
        playSpec={buildPlaySpec(formData)}
      />

      <EditPublishTab
        isActive={4 === displayTabIndex}
        isDirty={fmFormRenderProps.isDirty}
        isSubmitting={fmFormRenderProps.isSubmitting}
        gameId={formData.id}
        gameName={formData.name}
        gameType={GAMETYPE}
        formData={formData}
        setIsPublished={() =>
          fmFormRenderProps.setDerivedFormData((draftDerivedFormData) => {
            draftDerivedFormData.isPublished = true;
          })
        }
      />
    </Container>
  );
};
