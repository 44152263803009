import AppBar from "@material-ui/core/AppBar";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Paper from "@material-ui/core/Paper";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import * as React from "react";
import { useState, useRef, useEffect } from "react";

const drawerWidth = 240;
const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    title: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    drawerOpen: {
      width: drawerWidth,

      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: theme.spacing(6) + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(7) + 1,
      },
    },
    drawerContainer: {
      overflowY: "auto",
      overFlowX: "hidden",
    },
    content: {
      padding: theme.spacing(3),
      marginLeft: theme.spacing(6) + 1,
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(7) + 1,
      },
    },
    toolbar: {
      // padding: theme.spacing(3),
      marginLeft: theme.spacing(6) + 1,
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(7) + 1,
      },
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
    // Loads information about the app bar, including app bar height
    toolbarShift: theme.mixins.toolbar,
  });
});

export interface TopicFrameProps {
  appIcon: JSX.Element;
  appIconMenuText: string;
  leftNav: JSX.Element;
  toolbar: JSX.Element;
}

export const TopicFrame: React.FunctionComponent<TopicFrameProps> = (props) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const classes = useStyles();

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const contentRef = useRef<HTMLDivElement>(null);
  const [contentTop, setContentTop] = useState(0);

  useEffect(() => {
    const element = contentRef.current;
    if (element) {
      const top = element.getBoundingClientRect().top;
      if (top !== contentTop) {
        setContentTop(top);
      }
    }
  });
  return (
    <>
      <div className={classes.toolbarShift} style={{ position: "relative" }}>
        <AppBar position="absolute" color="transparent" elevation={1}>
          <div className={classes.toolbar}>{props.toolbar}</div>
        </AppBar>
      </div>
      <ClickAwayListener
        onClickAway={() => {
          setOpenDrawer(false);
        }}
      >
        <Drawer
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: openDrawer,
            [classes.drawerClose]: !openDrawer,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: openDrawer,
              [classes.drawerClose]: !openDrawer,
            }),
          }}
          variant="persistent"
          open={true}
          onMouseEnter={() => setOpenDrawer(true)}
          onMouseLeave={() => setOpenDrawer(false)}
          onClick={() => setOpenDrawer(false)}
        >
          <div className={classes.toolbarShift} />
          <>
            <Paper elevation={1} square>
              <List>
                <ListItem button onClick={toggleDrawer}>
                  <ListItemIcon>{props.appIcon}</ListItemIcon>
                  <ListItemText primary={props.appIconMenuText} />
                </ListItem>
              </List>
            </Paper>
            {props.leftNav}
          </>
        </Drawer>
      </ClickAwayListener>
      <div
        className={classes.content}
        // overflow: "auto" causes this div to get the vertical scroll, which
        // keeps the topic's app bar on-screen.
        style={{ height: `calc(100vh - ${contentTop}px)`, overflowY: "auto" }}
        ref={contentRef}
      >
        {props.children}
      </div>
    </>
  );
};
