import { createStyles, makeStyles } from "@material-ui/core/styles";
import { format } from "date-fns";
import * as React from "react";
import { useContext } from "react";
import { PageContainer } from "../../../components/PageContainer";
import SettingDescription from "../../../components/SettingDescription";
import SettingHeader from "../../../components/SettingHeader";
import Title from "../../../components/Title";
import { FmForm } from "../../../formManager/FmForm";
import { getSubscription } from "../requests/getSubscription";
import { UserContext } from "./RouterTop";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
    },
    textField: {
      width: "40ch",
    },
  })
);

interface FormData {
  fmFormDataVersion: number; // required by FmForm
  expiry: Date;
}

export interface SubscriptionProps {}

export const Subscription = () => {
  const authUserInfo = useContext(UserContext);
  return (
    <FmForm
      fetch={{
        handler: async () => {
          const s = await getSubscription({});
          return {
            expiry: s.expiry,
            fmFormDataVersion: 0,
          };
        },
      }}
      onSubmit={() => Promise.resolve()}
    >
      {(fmProps) => (
        <PageContainer>
          <Title>Subscription for {authUserInfo.email}</Title>
          <SettingHeader>Expiration Date</SettingHeader>
          <SettingDescription>
            This is the last date on which you can use Cortex Author.
          </SettingDescription>
          {fmProps.formData.expiry
            ? format(fmProps.formData.expiry, "P")
            : "Your subscription has no expiration date."}
        </PageContainer>
      )}
    </FmForm>
  );
};
