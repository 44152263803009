import { IconButton, InputAdornment, TextField } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/HighlightOff";
import * as React from "react";
import { useEffect, useRef } from "react";
import { SecondaryButton, SubtleButton } from "../../../components/buttons";
import PageIntro from "../../../components/PageIntro";
import { RowContainer } from "../../../components/RowContainer";
import SettingDescription from "../../../components/SettingDescription";
import SettingsContainer from "../../../components/SettingsContainer";
import { TellMeMore } from "../../../components/TellMeMore";
import ToolbarTitle from "../../../components/ToolbarTitle";
import { FmForm, FmFormRenderProps } from "../../../formManager/FmForm";
import { useFormToolbar } from "../../../formManager/useFormToolbar";
import { useFreshState } from "../../../hooks/useFreshState";
import { makeSeparatedStringFromList, union } from "../../../utilities";
import { openAddWordsDialog } from "../games/spell/components/AddWordsDialog";
import { VirtualizedWordList } from "../games/spell/components/VirtualizedWordList";
import {
  saveUserGameSettings,
  userGameSettings,
} from "../requests/manageUserGameSettings";
import { FormToolbarEditorButtons } from "./FormToolbarEditorButtons";
import { validateWords } from "./HiddenWordsPage";
import { notifySuccess } from "../../../components/NotificationManager";

export interface FormData {
  fmFormDataVersion: number; // required by FmForm
  extraWords: string[];
  hiddenWords: string[]; // need for validation when adding words
}

export interface ExtraWordsPageProps {
  setToolbar: (element: JSX.Element) => void;
}
export const ExtraWordsPage = (props: ExtraWordsPageProps) => {
  const handleSubmit = async (
    fmFormRenderProps: FmFormRenderProps<FormData>
  ) => {
    const formData = fmFormRenderProps.formData;
    return saveUserGameSettings((userGameSettings) => {
      userGameSettings.extraWords = formData.extraWords;
    });
  };

  return (
    <FmForm
      fetch={{
        handler: async () => {
          const settings = await userGameSettings();
          return {
            fmFormDataVersion: undefined,
            extraWords: settings.extraWords,
            hiddenWords: settings.hiddenWords,
          };
        },
      }}
      onSubmit={handleSubmit}
    >
      {(fmFormRenderProps) => {
        return (
          <RenderedFormChild
            fmFormRenderProps={fmFormRenderProps}
            setToolbar={props.setToolbar}
          />
        );
      }}
    </FmForm>
  );
};

const RenderedFormChild = (props: {
  fmFormRenderProps: FmFormRenderProps<FormData>;
  setToolbar: (element: JSX.Element) => void;
}) => {
  const fmFormRenderProps = props.fmFormRenderProps;
  const extraWords = fmFormRenderProps.formData.extraWords;
  const [getFilter, setFilter] = useFreshState(() => "");
  const ref = useRef(null);
  useEffect(() => {
    const inputElement: HTMLInputElement = ref.current;
    if (inputElement) inputElement.focus();
  }, []);
  const filteredWords = fmFormRenderProps.formData.extraWords
    .filter((word) => word.indexOf(getFilter()) >= 0)
    .sort();

  useFormToolbar(() => {
    props.setToolbar(
      <RowContainer>
        <ToolbarTitle>Spell: Extra Words</ToolbarTitle>
        <FormToolbarEditorButtons fmFormRenderProps={fmFormRenderProps} />
      </RowContainer>
    );
  });

  return (
    <SettingsContainer>
      <PageIntro>
        The words in this list will be added to the built-in dictionary when
        finding game words.
        <TellMeMore>
          The built-in dictionary may not include recent or less-common words.
          When searching, a word is found only if that word is
          <ul>
            <li>
              in the built-in dictionary and is not excluded by virtue of being
              on the built-in sensitive word list
            </li>
            <li>OR is in your extra words list (on this page)</li>
            <li>AND is not in your hidden words list.</li>
          </ul>
          You won't be able to add words that are in your hidden words list
          because they would be ignored.
        </TellMeMore>
        <p>
          To share with others:
          <SubtleButton
            onClick={() => {
              navigator.clipboard.writeText(
                makeSeparatedStringFromList(filteredWords)
              );
              notifySuccess("Copied");
            }}
          >
            Copy words to clipboard
          </SubtleButton>
        </p>
      </PageIntro>
      <RowContainer>
        <SecondaryButton
          onClick={() =>
            openAddWordsDialog({
              title: "Add Extra Words",
              submitHandler: (words) => {
                const errorMessage = validateWords(
                  words,
                  [], //  don't send extra words -- prevents rejection for dups
                  "extra",
                  fmFormRenderProps.formData.hiddenWords,
                  "hidden"
                );
                if (errorMessage) return Promise.reject(errorMessage);
                fmFormRenderProps.setFormData((draftFormData) => {
                  draftFormData.extraWords = union(extraWords, words);
                  draftFormData.extraWords.sort((a, b) =>
                    a < b ? -1 : a > b ? 1 : 0
                  );
                });
                return Promise.resolve();
              },
            })
          }
        >
          Add Words
        </SecondaryButton>
        <SecondaryButton
          disabled={filteredWords.length === 0}
          onClick={() => {
            fmFormRenderProps.setFormData((draftFormData) => {
              filteredWords.forEach((filteredWord) => {
                const index = draftFormData.extraWords.indexOf(filteredWord);
                draftFormData.extraWords.splice(index, 1);
              });
            });
          }}
        >
          Delete {filteredWords.length} Word
          {`${filteredWords.length !== 1 ? "s" : ""} Below`}
        </SecondaryButton>
      </RowContainer>
      <SettingDescription>
        As you type into the word filter, you will see only matching words. If
        you use the DELETE button it will delete only the matching words.
      </SettingDescription>
      <div style={{ width: "20rem" }}>
        <TextField
          inputRef={ref}
          fullWidth
          variant="outlined"
          value={getFilter()}
          label="Word Filter"
          placeholder="Type to search for words below"
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  size="small"
                  onClick={() => {
                    setFilter("");
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </InputAdornment>
            ),
            style: { maxWidth: "20rem" },
          }}
          onChange={(event) => {
            const ftext: string = event.currentTarget.value;
            setFilter(ftext);
          }}
        />
      </div>

      <div style={{ width: "15rem", flexGrow: 1, marginTop: "1rem" }}>
        <VirtualizedWordList
          words={filteredWords}
          removeWord={(word) => {
            // So we also need to update form data -- which is also what dirties the page.
            fmFormRenderProps.setFormData((draftFormData) => {
              const delIndex = draftFormData.extraWords.indexOf(word);
              draftFormData.extraWords.splice(delIndex, 1);
            });
          }}
        />
      </div>
    </SettingsContainer>
  );
};
