import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import InfoIcon from "@material-ui/icons/Info";
import * as React from "react";
import { useState } from "react";
import { SubtleButton } from "./buttons";
import { Info } from "./Info";

export const TellMeMore: React.FunctionComponent<{
  icon?: boolean;
  message?: string | JSX.Element;
  buttonText?: string;
  color?: "primary" | "secondary" | "default" | "inherit";
}> = (props) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button
        color={props.color ?? "default"}
        style={{ paddingTop: 0, paddingBottom: 0, minWidth: 0 }}
        onClick={() => {
          setOpen(true);
        }}
      >
        {props.icon ? (
          <InfoIcon fontSize="small" />
        ) : props.buttonText ? (
          props.buttonText
        ) : (
          "tell me more"
        )}
      </Button>
      <Dialog
        onClose={() => setOpen(false)}
        aria-labelledby="tell-me-more"
        open={open}
      >
        <Info>{props.message ? props.message : props.children}</Info>
        <DialogActions>
          <SubtleButton onClick={() => setOpen(false)}>Ok</SubtleButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
