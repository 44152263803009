import * as React from "react";
import { useContext } from "react";
import { FmTextField } from "../../../formManager/FmField";
import { FmForm, FmFormRenderProps } from "../../../formManager/FmForm";
import { getUserProfile, saveUserProfile } from "../requests/manageUserProfile";
import { PrimaryButton } from "../../../components/buttons";
import { PageContainer } from "../../../components/PageContainer";
import { UserContext } from "./RouterTop";
import SettingDescription from "../../../components/SettingDescription";
import SettingHeader from "../../../components/SettingHeader";
import { Title } from "../../../components/Title";

interface FormData {
  fmFormDataVersion: number; // required by FmForm
  displayName: string;
  initials: string;
}

export interface ProfileProps {}

export const Profile = () => {
  const handleSubmit = async (
    fmFormRenderProps: FmFormRenderProps<FormData>
  ) => {
    const formData = fmFormRenderProps.formData;
    const response = await saveUserProfile((draftUserProfile) => {
      draftUserProfile.initials = formData.initials;
      draftUserProfile.displayName = formData.displayName;
    });
    fmFormRenderProps.setFormData((draftFormData) => {
      draftFormData.initials = response.userProfile.initials;
    });
  };
  const authUserInfo = useContext(UserContext);
  return (
    <FmForm
      fetch={{
        handler: async () => {
          const up = await getUserProfile();
          return {
            initials: up.initials ?? "",
            displayName: up.displayName ?? "",
            fmFormDataVersion: 0,
          };
        },
      }}
      onSubmit={handleSubmit}
    >
      {(fmProps) => (
        <PageContainer>
          <Title>Profile for {authUserInfo.email}</Title>
          <SettingHeader>Display Name</SettingHeader>
          <SettingDescription>
            The display name is used to address you within the authoring tool,
            and in emails.
          </SettingDescription>
          <FmTextField<FormData>
            name="displayName"
            width="30rem"
            initialFocus
            isRequired
            maxLength={50}
          />
          <SettingHeader>Initials</SettingHeader>
          <SettingDescription>
            The initials are used to identify you when there is not room for
            your display name.
          </SettingDescription>
          <FmTextField<FormData>
            name="initials"
            width="30rem"
            maxLength={2}
            isRequired
          />
          <PrimaryButton
            type="submit"
            onClick={() => fmProps.submit()}
            disabled={fmProps.isSubmitting || fmProps.isDirty !== true}
          >
            Save
          </PrimaryButton>
        </PageContainer>
      )}
    </FmForm>
  );
};
