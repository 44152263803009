import * as React from "react";
import SettingSubheader from "../../../../../components/SettingSubheader";
import SettingDescription from "../../../../../components/SettingDescription";

export const AutoScoringHelp = () => {
  return (
    <>
      <SettingSubheader>Auto Scoring</SettingSubheader>
      <SettingDescription>
        If auto scoring is disabled, you need to manually set the number of
        points for each level. If enabled, the score for each level is computed
        for you, according to the auto scoring method. The "faster" the method,
        the faster the player will reach higher achievement levels.
      </SettingDescription>
      <SettingSubheader>Auto Scoring Method Details</SettingSubheader>
      <SettingDescription>
        For all methods the lowest level is reached with zero points, and the
        highest is reached with the high score.
        <ul>
          <li>
            <b>Slow: </b>The second level score is 2/3 of the high score. The
            remaining levels (if any) are spread evenly between the second level
            and the penultimate level. Use this method when most players are
            expected to achieve a high score.
          </li>
          <li>
            <b>Medium: </b>The levelscare spread evenly between the lowest level
            and the highest level.
          </li>
          <li>
            <b>Fast: </b>The penultimate level score is 2/3 of the high score.
            The remaining levels (if any) are spread evenly between the lowest
            level and the penultimate level. Reaching the penultimate level is
            often worthy of high praise!
          </li>
        </ul>
      </SettingDescription>
    </>
  );
};
