import { createStyles, makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import { ColumnContainer } from "../../../../../components/ColumnContainer";
import { useHelp } from "../../../../../components/HelpDialog";
import Hider from "../../../../../components/Hider";
import { RowContainer } from "../../../../../components/RowContainer";
import SettingHeader from "../../../../../components/SettingHeader";
import { TellMeMore } from "../../../../../components/TellMeMore";
import { Tip } from "../../../../../components/Tip";
import { FmNumberField } from "../../../../../formManager/FmField";
import { sum } from "../../../../../utilities";
import { AutoScoringHelp } from "../../shared/components/AutoScoringHelp";
import { Ladder } from "../../shared/components/Ladder";
import { FormData } from "./SpellEditPage";

const useStyles = makeStyles(() => {
  return createStyles({
    bonus: {
      marginLeft: "0.5rem",
      marginRight: "0.5rem",
    },
  });
});

const BonusHelp = () => {
  return (
    <ul>
      <li>
        <b>Pangram bonus: </b>Any word that uses all of the letters is given
        this bonus.
      </li>
      <li>
        <b>Longest word bonus: </b>Any longest word is given this bonus.
      </li>
      <li>
        <b>Special word bonus: </b>Any special word is given this bonus. You
        make a word special by marking it as being special on the Choose Words
        tab.
      </li>
    </ul>
  );
};

const SpellEditScoringTabInternal = (props: {
  formData: FormData;
  isActive: boolean;
}) => {
  useHelp("SpellEditScoringTab", <MyHelp />, props.isActive);
  const { formData } = props;
  const classes = useStyles();
  return (
    <Hider hidden={!props.isActive}>
      <ColumnContainer center>
        <Tip>
          Many authors skip this step, since the automatically generated scoring
          is pretty good.
        </Tip>
        <RowContainer center>
          <FmNumberField<FormData>
            name="pangramBonus"
            placeholderText=""
            className={classes.bonus}
            width="6rem"
          />
          <FmNumberField<FormData>
            name="longestWordBonus"
            placeholderText=""
            className={classes.bonus}
            width="6rem"
          />
          <FmNumberField<FormData>
            name="specialWordBonus"
            placeholderText=""
            className={classes.bonus}
            width="6rem"
          />
        </RowContainer>
        <TellMeMore>
          <BonusHelp />
        </TellMeMore>
        <Ladder
          levels={formData.levels}
          highScore={
            formData.answers
              ? sum(formData.answers.map((answer) => answer.score))
              : 0
          }
          autoScoring={formData.autoScoring}
          autoScoringMethod={formData.autoScoringMethod}
        />
      </ColumnContainer>
    </Hider>
  );
};

export const SpellEditScoringTab = React.memo(
  SpellEditScoringTabInternal,
  (prevProps, nextProps) => {
    if (prevProps.isActive !== nextProps.isActive) return false;
    if (prevProps.formData.levels !== nextProps.formData.levels) return false;
    if (prevProps.formData.answers !== nextProps.formData.answers) return false;
    if (prevProps.formData.autoScoring !== nextProps.formData.autoScoring)
      return false;
    if (
      prevProps.formData.autoScoringMethod !==
      nextProps.formData.autoScoringMethod
    )
      return false;
    if (prevProps.formData.pangramBonus !== nextProps.formData.pangramBonus)
      return false;
    if (
      prevProps.formData.longestWordBonus !==
      nextProps.formData.longestWordBonus
    )
      return false;
    if (
      prevProps.formData.specialWordBonus !==
      nextProps.formData.specialWordBonus
    )
      return false;
    return true;
  }
);

const MyHelp = () => {
  return (
    <>
      <SettingHeader>Create Scoring</SettingHeader>
      <AutoScoringHelp />
      <BonusHelp />
    </>
  );
};
