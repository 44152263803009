import { ApiResponseHeader } from "../../../domain/serverContract";
import { makeCacheValidatedRequest } from "./makeCacheValidatedRequest";
import { updateAbstract } from "./manageGameAbstracts";
import { GameType } from "../../../domain/types";

export interface PublishGameRequest {
  id: string;
  gameType: GameType;
}

export interface PublishGameResponse extends ApiResponseHeader {}

export async function publishGame(
  request: PublishGameRequest
): Promise<PublishGameResponse> {
  return makeCacheValidatedRequest(request, "/api/publish_game").then(
    (response) => {
      updateAbstract(request.id, (abstract) => {
        abstract.published = response.timestamp;
      });
      return response;
    }
  );
}
