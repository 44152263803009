import { makeRequestWithAuthentication } from "../../../http/authenticated";
import {
  ApiRequest,
  ApiResponseHeader,
  UserGameSettings,
} from "../../../domain/serverContract";

let promiseCache: Promise<UserGameSettings>;

export const clearUserGameSettingsCache = () => {
  promiseCache = undefined;
};

export const userGameSettings = async () => {
  if (promiseCache) return promiseCache;
  promiseCache = getUserGameSettings()
    .then((response) => {
      const settings = response.userGameSettings ?? {
        extraWords: [],
        hiddenWords: [],
        includeSensitiveWords: false,
      };
      // Make it easier elsewhere
      settings.extraWords = settings.extraWords ?? [];
      settings.hiddenWords = settings.hiddenWords ?? [];
      settings.includeSensitiveWords = settings.includeSensitiveWords ?? false;
      return settings;
    })
    .catch((error) => {
      promiseCache = undefined;
      throw error;
    });
  return promiseCache;
};

export interface GetUserGameSettingsRequest extends ApiRequest {}

export interface GetUserGameSettingsResponse extends ApiResponseHeader {
  userGameSettings: UserGameSettings;
}

async function getUserGameSettings(): Promise<GetUserGameSettingsResponse> {
  return makeRequestWithAuthentication({}, "/api/get_user_game_settings");
}

export interface SaveUserGameSettingsRequest {
  userGameSettings: UserGameSettings;
}

export interface SaveUserGameSettingsResponse extends ApiResponseHeader {}

// Saves settings to the database
// Updates cache if save is successful
export async function saveUserGameSettings(
  mutator: (draftUserGameSettings: UserGameSettings) => void
): Promise<SaveUserGameSettingsResponse> {
  const settings = await promiseCache;
  const tempUserGameSettings = Object.assign({}, settings);
  mutator(tempUserGameSettings);
  const response = await makeRequestWithAuthentication<
    SaveUserGameSettingsResponse
  >({ userGameSettings: tempUserGameSettings }, "/api/save_user_game_settings");
  mutator(settings);
  return response;
}
