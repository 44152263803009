import Link from "@material-ui/core/Link";
import { auth as firebaseauth } from "firebase/app";
import "firebase/auth";
import * as React from "react";
import { useState } from "react";
import { notifyError } from "../../../components/NotificationManager";
import { PageContainer } from "../../../components/PageContainer";

interface LogoffProps {}

export const Logoff = (props: LogoffProps) => {
  const [loggedOff, setLoggedOff] = useState(false);
  const [logoffError, setLogoffError] = useState(false);
  const fa = firebaseauth();
  if (fa.currentUser) {
    if (logoffError) {
      return (
        <PageContainer center>
          Logoff failed. Click{" "}
          <Link
            onClick={() => {
              setLogoffError(false);
            }}
          >
            login
          </Link>{" "}
          to try to logoff again.
        </PageContainer>
      );
    }
    firebaseauth()
      .signOut()
      .then(() => {
        setLoggedOff(true);
      })
      .catch((reason) => {
        setLogoffError(true);
        notifyError("Logoff failed");
      });
    return <PageContainer center>Logoff in progress...</PageContainer>;
  }
  return (
    <PageContainer center>
      You are no longer logged in. Click <Link href={"/login"}>login</Link> to
      log in again.
    </PageContainer>
  );
};
