import { makeRequestWithAuthentication } from "../../../../http/authenticated";
import { ApiResponseHeader } from "../../../../domain/serverContract";

export interface SetAdminLevelRequest {
  tuid: string;
  admin: number;
}

export interface SetAdminLevelResponse extends ApiResponseHeader {}

export async function setAdminLevel(
  request: SetAdminLevelRequest
): Promise<SetAdminLevelResponse> {
  return makeRequestWithAuthentication(request, "/api/admin/set_admin_level");
}
