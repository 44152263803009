import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import { red } from "@material-ui/core/colors";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import * as React from "react";
import { StrongGuidingButton } from "../../../components/buttons";
import { TwistIcon } from "../../../components/icons/TwistIcon";
import { RowContainer } from "../../../components/RowContainer";
import { openAddGameDialog } from "./AddGameDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    minHeight: 410,
    margin: "1rem",
  },
  header: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  media: {
    height: "100%",
    backgroundSize: "contain",
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export function HomeTwistCard() {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardHeader
        className={classes.header}
        avatar={
          <IconButton
            aria-label="games"
            onClick={() =>
              openAddGameDialog({ title: "Create Twist Game", gameType: "t" })
            }
          >
            <TwistIcon fontSize="large" />
          </IconButton>
        }
        title="Create Twist Games"
        subheader="Solve spelling challenges with a twist."
      />
      <CardActions>
        <RowContainer center>
          <StrongGuidingButton
            onClick={() =>
              openAddGameDialog({ title: "Create Twist Game", gameType: "t" })
            }
          >
            Create Twist Game
          </StrongGuidingButton>
        </RowContainer>
      </CardActions>
      <div
        style={{
          height: 150,
          cursor: "pointer",
        }}
        onClick={() =>
          openAddGameDialog({ title: "Create Twist Game", gameType: "t" })
        }
      >
        <CardMedia
          className={classes.media}
          image="/images/TwistGameScreenshot.png"
          title="Twist App"
        />
      </div>
      <Divider variant="middle" style={{ marginTop: "1rem" }} />
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
          You choose the solutions, the scrambled and pre-filled letters for
          each solution, and determine how the game is scored.
        </Typography>
      </CardContent>
    </Card>
  );
}
