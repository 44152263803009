import {
  ApiRequest,
  ValidatedApiResponse,
} from "../../../domain/serverContract";
import { makeCacheValidatedRequest } from "./makeCacheValidatedRequest";
import { trashAbstract } from "./manageGameAbstracts";

export interface TrashGameRequest extends ApiRequest {
  id: string;
}

export interface TrashGameResponse extends ValidatedApiResponse {}

export async function trashGame(
  request: TrashGameRequest
): Promise<TrashGameResponse> {
  return makeCacheValidatedRequest<TrashGameResponse>(
    request,
    "/api/trash_game"
  ).then((response) => {
    trashAbstract(request.id, response.timestamp);
    return response;
  });
}
