import * as React from "react";
import { ColumnContainer } from "../../../components/ColumnContainer";
import Emphasize from "../../../components/Emphasize";
import { useHelp } from "../../../components/HelpDialog";
import { MainNavigationMenuIcon } from "../../../components/MainNavigationMenuIcon";
import { RowContainer } from "../../../components/RowContainer";
import SettingDescription from "../../../components/SettingDescription";
import SettingHeader from "../../../components/SettingHeader";
import SettingsContainer from "../../../components/SettingsContainer";
import { Title } from "../../../components/Title";
import { CreateGameButton } from "./CreateGameButton";
import { HomeComputeCard } from "./HomeComputeCard";
import { HomeLibraryGamesCard } from "./HomeLibraryGamesCard";
import { HomeSpellCard } from "./HomeSpellCard";
import { HomeTwistCard } from "./HomeTwistCard";
import { HomeYourGamesCard } from "./HomeYourGamesCard";

interface HomeProps {
  getToggleNavMenu: () => () => void;
}
export const Home = (props: HomeProps) => {
  useHelp("Home", <MyHelp />, true);
  return (
    <ColumnContainer center>
      <Title>Game Authoring Dashboard</Title>
      <RowContainer center>
        <HomeYourGamesCard />
        <HomeLibraryGamesCard />
        <HomeSpellCard />
        <HomeTwistCard />
        <HomeComputeCard />
      </RowContainer>
    </ColumnContainer>
  );
};

const MyHelp = () => {
  return (
    <SettingsContainer>
      <SettingHeader>Create a New Game</SettingHeader>
      <SettingDescription>
        Go to the game app for the game you want to create, and click
        <CreateGameButton />
        at the top. To go to a game app, open the navigation menu
        <MainNavigationMenuIcon />
        and click on the app name (for example, Spell).
      </SettingDescription>
      <SettingHeader>See Your Games</SettingHeader>
      <SettingDescription>
        Open the navigation menu
        <MainNavigationMenuIcon />
        and click on the app name (for example, Spell).
      </SettingDescription>
      <SettingHeader>Open the Navigation Menu</SettingHeader>
      <SettingDescription>
        Click
        <MainNavigationMenuIcon />
        to open and close the navigation menu on the left.
      </SettingDescription>
      <SettingHeader>Create a Template Game</SettingHeader>
      <SettingDescription>
        Each time you create a game, the new game will be a copy of your
        template game. To create a template, click the{" "}
        <Emphasize>Make This Game My Template</Emphasize> button when you are
        editing your game.
      </SettingDescription>
    </SettingsContainer>
  );
};
