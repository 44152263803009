import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Grow from "@material-ui/core/Grow";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import AddLevelIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import * as React from "react";
import { useRef } from "react";
import { notifyError } from "../../../../../components/NotificationManager";
import {
  FmFieldErrorCode,
  FmNumberField,
  FmSwitchField,
  FmDiscreteSliderField,
} from "../../../../../formManager/FmField";
import { FmList, FmListRenderProps } from "../../../../../formManager/FmList";
import { serialId } from "../../../../../utilities";
import { FormLevel } from "../editorTypes";
import { AutoScoringMethod } from "../../../../../domain/types";
import { RowContainer } from "../../../../../components/RowContainer";
import { ColumnContainer } from "../../../../../components/ColumnContainer";

const useStyles = makeStyles(() => {
  return createStyles({
    tile: {
      border: "solid",
      marginBottom: "0.25rem",
      paddingLeft: "0.5rem",
      paddingRight: "0.5rem",
    },
    nameField: { width: "12rem", marginRight: "0.5rem" },
    scoreField: { width: "4rem" },
    slider: {
      marginLeft: "2rem",
      width: "10rem",
    },
  });
});

export interface LadderProps {
  levels: FormLevel[];
  highScore: number;
  autoScoring: boolean;
  autoScoringMethod: AutoScoringMethod;
}
const LadderInternal = (props: LadderProps) => {
  const classes = useStyles();
  const { highScore, autoScoring } = props;
  const previousNameIndex = useRef(0);
  const highestRenderedIdRef = useRef(-1);
  return (
    <Paper>
      <ColumnContainer center>
        <Box style={{ marginRight: "auto", marginLeft: "0.25rem" }}>
          High Score {highScore}
        </Box>
        <RowContainer center>
          <FmSwitchField name="autoScoring" />
          <FmDiscreteSliderField
            name="autoScoringMethod"
            disabled={!autoScoring}
            className={classes.slider}
            width="12rem"
            marks={[
              { value: AutoScoringMethod.Slow, label: "Slow" },
              { value: AutoScoringMethod.Medium, label: "Medium" },
              { value: AutoScoringMethod.Fast, label: "Fast" },
            ]}
          />
        </RowContainer>
      </ColumnContainer>
      <FmList
        name="levels"
        minItems={1}
        itemAction={(itemActionRenderProps) => {
          // add is the only action
          const { item, items, position, setFormData } = itemActionRenderProps;
          // Get a unique level name
          const newNameBase = "New Level ";
          let nameCandidate: string;
          for (let i = previousNameIndex.current + 1; true; i++) {
            nameCandidate = newNameBase + i.toString();
            if (
              undefined === items.find((level) => level.name === nameCandidate)
            ) {
              previousNameIndex.current = i;
              break;
            }
          }
          const level: FormLevel = {
            name: nameCandidate,
            score: item.score + 1,
            id: serialId(),
          };
          setFormData((draftFormData) => {
            draftFormData.levels = [
              ...draftFormData.levels.slice(0, position + 1),
              level,
              ...draftFormData.levels.slice(position + 1),
            ];
          });
        }}
        sortComparer={(a, b) => a.score - b.score}
      >
        {(
          fmListRenderProps: FmListRenderProps<
            { ["levels"]: FormLevel[] },
            FormLevel
          >
        ) => {
          const values = fmListRenderProps.values;
          const valuesLength = values.length;
          const sortedIndex = fmListRenderProps.sortedIndex;
          const scoreAbove =
            sortedIndex <= 0 ? -1 : values[sortedIndex - 1].score;
          const scoreBelow =
            sortedIndex >= valuesLength - 1
              ? Number.MAX_VALUE
              : values[sortedIndex + 1].score;
          const highestRenderedId = highestRenderedIdRef.current;
          if (fmListRenderProps.value.id > highestRenderedId)
            highestRenderedIdRef.current = fmListRenderProps.value.id;
          return (
            <LevelTile
              level={fmListRenderProps.value}
              setValue={fmListRenderProps.setFormDataValue}
              remove={fmListRenderProps.removeItem}
              addBelow={() => fmListRenderProps.itemAction("add")}
              scoreBelow={scoreBelow}
              scoreAbove={scoreAbove}
              highestRenderedId={highestRenderedId}
              scoreDisabled={autoScoring || fmListRenderProps.sortedIndex === 0}
              deleteDisabled={fmListRenderProps.sortedIndex === 0}
              autoScoring={autoScoring}
              firstTile={fmListRenderProps.sortedIndex === 0}
              highScore={highScore}
            />
          );
        }}
      </FmList>
    </Paper>
  );
};

export const Ladder = React.memo(LadderInternal, (prevProps, nextProps) => {
  if (prevProps.levels !== nextProps.levels) return false;
  if (
    prevProps.highScore !== nextProps.highScore ||
    prevProps.autoScoring !== nextProps.autoScoring
  )
    return false;
  return true;
});

interface LevelTileProps {
  level: FormLevel;
  setValue: (level: FormLevel) => void;
  remove: () => void;
  addBelow: () => void;
  highestRenderedId: number;
  scoreAbove: number;
  scoreBelow: number;
  scoreDisabled: boolean;
  deleteDisabled: boolean;
  autoScoring: boolean;
  firstTile: boolean;
  highScore: number;
}
const LevelTile = (props: LevelTileProps) => {
  const {
    level,
    setValue,
    remove,
    addBelow,
    scoreAbove,
    scoreBelow,
    highestRenderedId,
    scoreDisabled,
    deleteDisabled,
    autoScoring,
    firstTile,
    highScore,
  } = props;
  const classes = useStyles();
  const animate = level.id > highestRenderedId;

  const customErrorMessagesMax = {
    [FmFieldErrorCode.OverMax]: `The score for a level may not be greater than the high score, which currently is ${highScore}.`,
  };

  return (
    <Grow
      key={level.id}
      in={true}
      style={{ transformOrigin: "0 0 0" }}
      {...(animate ? { timeout: 1000 } : {})}
    >
      <Grid className={classes.tile} container alignItems="center">
        <TextField
          className={classes.nameField}
          variant="outlined"
          value={level.name}
          label={"Level name"}
          placeholder={"Level name"}
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          type="text"
          onChange={(event) => {
            setValue({
              name: event.currentTarget.value,
              score: level.score,
              id: level.id,
            });
          }}
        />
        <div
          onClick={() => {
            if (!scoreDisabled) return;
            if (firstTile) {
              notifyError("The score of the first level must be zero.");
              return;
            }
            if (autoScoring) {
              notifyError(
                "Turn auto scoring off to manually change the score at each level."
              );
            }
          }}
        >
          <FmNumberField
            className={classes.scoreField}
            disabled={scoreDisabled}
            maxValue={highScore}
            customErrorMessages={customErrorMessagesMax}
            valueProxyFuncs={{
              get: () => level.score,
              mutate: (value) => {
                setValue({
                  score: value,
                  name: level.name,
                  id:
                    level.score < scoreAbove || level.score > scoreBelow
                      ? serialId()
                      : level.id,
                });
              },
            }}
            labelText={"Score"}
            placeholderText={"Score"}
          />
        </div>
        <Tooltip title="Add level below" aria-label="add level below">
          <IconButton
            color="primary"
            aria-label="add level below"
            size="small"
            onClick={() => addBelow()}
          >
            <AddLevelIcon
              fontSize="small"
              style={{
                marginLeft: ".5rem",
              }}
            />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete level" aria-label="delete level">
          <IconButton
            color="primary"
            aria-label="delete level"
            size="small"
            onClick={() => {
              if (deleteDisabled) {
                notifyError(
                  "You cannot delete the first level. Also, the first level's score must be zero."
                );
              } else {
                remove();
              }
            }}
          >
            <DeleteIcon
              fillOpacity={deleteDisabled ? "50%" : "100%"}
              fontSize="small"
              style={{
                marginLeft: ".5rem",
              }}
            />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grow>
  );
};
