import { makeRequestWithAuthentication } from "../../../../http/authenticated";
import { ApiResponseHeader } from "../../../../domain/serverContract";

export interface DisableUserUserRequest {
  tuid: string;
  disabled: boolean;
}

export interface DisableUserUserResponse extends ApiResponseHeader {}

export async function disableUser(
  request: DisableUserUserRequest
): Promise<DisableUserUserResponse> {
  return makeRequestWithAuthentication(request, "/api/admin/disable_user");
}
