import * as React from "react";
import { SubtleButton } from "../../../../../components/buttons";
import { ColumnContainer } from "../../../../../components/ColumnContainer";
import { ConfirmationDialogBase } from "../../../../../components/dialogTools/ConfirmationDialogBase";
import {
  Dialog,
  getDialogMethods,
  makeDialog,
} from "../../../../../components/dialogTools/DialogManager";
import Emphasize from "../../../../../components/Emphasize";
import { notifyError } from "../../../../../components/NotificationManager";
import SettingDescription from "../../../../../components/SettingDescription";
import { FmTextField } from "../../../../../formManager/FmField";
import { FmForm } from "../../../../../formManager/FmForm";
import { shuffleString } from "../../../../../utilities";

interface FormData {
  fmFormDataVersion: number; // required by FmForm
  letters: string;
}

export interface ReorderLettersDialogProps {
  letters: string;
}

export let reorderLettersDialogInternal: Dialog<
  string,
  ReorderLettersDialogProps
>;

export const openReorderLettersDialog = (props: ReorderLettersDialogProps) => {
  if (!reorderLettersDialogInternal) {
    reorderLettersDialogInternal = makeReorderLettersDialogComponent();
  }
  return getDialogMethods().open(reorderLettersDialogInternal, props);
};

export const makeReorderLettersDialogComponent = () => {
  return makeDialog<string, ReorderLettersDialogProps>({
    componentRenderer: (dialogRenderProps) => {
      const letters = dialogRenderProps.props.letters;
      return (
        <FmForm<FormData>
          suppressPrompt
          name="ReorderLettersDialog"
          suppressSpinner
          fetch={{
            handler: () =>
              Promise.resolve({
                fmFormDataVersion: undefined,
                letters,
              }),
          }}
          onSubmit={(fmProps) => {
            const f = fmProps.formData;
            if (
              f.letters.split("").sort().join("") !==
              letters.split("").sort().join("")
            ) {
              return Promise.reject(
                "You cannot change the letters here, only the order of the letters."
              );
            }
            return Promise.resolve(f.letters);
          }}
        >
          {(fmProps) => (
            <ConfirmationDialogBase
              onClose={(isOkay) => {
                if (isOkay) {
                  fmProps
                    .submit()
                    .then((response: string) => {
                      dialogRenderProps.close(true, response);
                      return response;
                    })
                    .catch((reason) => notifyError(reason));
                } else {
                  dialogRenderProps.close(false, undefined);
                }
              }}
              open={true}
              title={"Set Order"}
              okayText={"Set Order"}
            >
              <ColumnContainer>
                <SettingDescription>
                  Reorder the letters (<Emphasize>{letters}</Emphasize>)
                </SettingDescription>
                <FmTextField<FormData>
                  name="letters"
                  labelText="Reordered Letters"
                  initialFocus
                  width="25rem"
                  maxLength={10}
                />
                <SubtleButton
                  onClick={() =>
                    fmProps.setFormData((draft) => {
                      draft.letters = shuffleString(draft.letters);
                    })
                  }
                >
                  Shuffle Letters
                </SubtleButton>
              </ColumnContainer>
            </ConfirmationDialogBase>
          )}
        </FmForm>
      );
    },
  });
};
