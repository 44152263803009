import * as React from "react";
import { ColumnContainer } from "../../../../../components/ColumnContainer";
import SettingDescription from "../../../../../components/SettingDescription";
import { TellMeMore } from "../../../../../components/TellMeMore";
import { Tip } from "../../../../../components/Tip";
import { FmTextField } from "../../../../../formManager/FmField";
import { FormData } from "./ComputeEditPage";
import Hider from "../../../../../components/Hider";
import { ComputeRulesComponent } from "../../../../compute/components/ComputeRulesComponent";
import { ComputePlaySpec } from "../../../../../domain/compute_types";
import SettingSubheader from "../../../../../components/SettingSubheader";

export const ComputeEditRulesPrologueTab = (props: {
  rulesPrologue: string;
  isActive: boolean;
  playSpec: ComputePlaySpec;
}) => {
  return (
    <Hider hidden={!props.isActive}>
      <ColumnContainer center>
        <Tip>
          Most rules are generated automatically. Read them below before writing
          a prologue.
        </Tip>
        <SettingDescription>
          The rules prologue will be the first information displayed when the
          user sees the rules. It might briefly describe the theme of the game,
          and special rules that are not generated automatically. For example,
          consider stating the focus of the game, such as "division by 2".
          <TellMeMore>
            Automatically generated rules include:
            <ul>
              <li>the operations (add, subtract, multiply, divide)</li>
              <li>the possible number of different challenges per operation</li>
              <li>the range of the possible operands for each operation</li>
              <li>the time limit (if any)</li>
              <li>whether or not challenges may be skipped</li>
              <li>
                whether or not an incorrect answer causes the next challenge to
                be presented
              </li>
              <li>the number of challenges and points per game</li>
              <li>the points required to reach each level</li>
            </ul>
          </TellMeMore>
        </SettingDescription>
        <div>
          <FmTextField<FormData>
            name="rulesPrologue"
            width="70vw"
            textFieldProps={{ multiline: true, rows: 6 }}
          />
        </div>
        <SettingSubheader>Rules</SettingSubheader>
        <SettingDescription>
          Below are the rules, inluding the prologue (if any) above.
        </SettingDescription>
        <ComputeRulesComponent
          playSpec={props.playSpec}
          gameInfo={{
            maxPoints: props.playSpec.challengeCount,
            highestLevel:
              props.playSpec.levels[props.playSpec.levels.length - 1],
          }}
        />
      </ColumnContainer>
    </Hider>
  );
};
