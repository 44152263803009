import Box from "@material-ui/core/Box";
import * as React from "react";
import { PrimaryButton } from "../../../components/buttons";
import { GameType } from "../../../domain/types";
import { FmFormRenderProps } from "../../../formManager/FmForm";
import { handlePublish } from "../games/shared/components/EditPublishTab";
import { CommonFormData } from "../games/shared/editorTypes";

export const FormToolbarPublishButton = (props: {
  fmFormRenderProps: FmFormRenderProps<CommonFormData>;
  gameId: string;
  gameType: GameType;
}) => {
  const fmFormRenderProps = props.fmFormRenderProps;

  return (
    <>
      <PrimaryButton
        onClick={() =>
          handlePublish(
            fmFormRenderProps.isDirty,
            props.gameId,
            props.gameType,
            () =>
              fmFormRenderProps.setDerivedFormData((draftDerivedFormData) => {
                draftDerivedFormData.isPublished = true;
              })
          )
        }
        disabled={fmFormRenderProps.isSubmitting}
      >
        Publish
      </PrimaryButton>
      <Box marginLeft={0.5} />
    </>
  );
};
