import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import * as React from "react";
const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    body: {
      margin: "0.25rem 0 0.25rem 0",
      fontSize: theme.typography.body1.fontSize,
    },
  });
});
export interface SettingDescriptionProps {}

const SettingDescription: React.FunctionComponent<SettingDescriptionProps> = (
  props
) => {
  const classes = useStyles();
  return <div className={classes.body}>{props.children}</div>;
};
export default SettingDescription;
