import { ApiResponseHeader } from "../../../domain/serverContract";
import { makeRequestWithAuthentication } from "../../../http/authenticated";
import { GameRecord } from "../../../domain/types";

export interface GetGameRequest {
  id: string;
}

export interface GetGameResponse extends ApiResponseHeader {
  id: string;
  name: string;
  gameRecord: GameRecord;
}

export async function getGame(
  request: GetGameRequest
): Promise<GetGameResponse> {
  return makeRequestWithAuthentication(request, "/api/get_game");
}
