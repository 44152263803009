import * as React from "react";
import { SubtleButton } from "../../../../../components/buttons";
import { ColumnContainer } from "../../../../../components/ColumnContainer";
import { ConfirmationDialogBase } from "../../../../../components/dialogTools/ConfirmationDialogBase";
import {
  Dialog,
  getDialogMethods,
  makeDialog,
} from "../../../../../components/dialogTools/DialogManager";
import { notifyError } from "../../../../../components/NotificationManager";
import SettingDescription from "../../../../../components/SettingDescription";
import { Challenge } from "../../../../../domain/twist_types";
import { FmTextField } from "../../../../../formManager/FmField";
import { FmForm } from "../../../../../formManager/FmForm";
import { isAnagramOf, shuffleString } from "../../../../../utilities";
import { TellMeMore } from "../../../../../components/TellMeMore";
import { RowContainer } from "../../../../../components/RowContainer";

interface FormData {
  fmFormDataVersion: number; // required by FmForm
  solution: string;
  fixed: string;
  fillin: string;
  clue: string;
}

export interface EditChallengeDialogProps {
  challenge: Challenge;
  // Need solutions to ensure no duplicate solution.
  solutions: string[];
}

export let editChallengeDialogInternal: Dialog<
  Challenge,
  EditChallengeDialogProps
>;

export const openEditChallengeDialog = (props: EditChallengeDialogProps) => {
  if (!editChallengeDialogInternal) {
    editChallengeDialogInternal = makeEditChallengeDialogComponent();
  }
  return getDialogMethods().open(editChallengeDialogInternal, props);
};

export const makeEditChallengeDialogComponent = () => {
  return makeDialog<Challenge, EditChallengeDialogProps>({
    componentRenderer: (dialogRenderProps) => {
      const challenge = dialogRenderProps.props.challenge;
      const otherSolutions = dialogRenderProps.props.solutions.filter(
        (solution) => solution !== challenge.solution
      );
      return (
        <FmForm<FormData>
          suppressPrompt
          name="EditChallengeDialog"
          suppressSpinner
          fetch={{
            handler: () =>
              Promise.resolve({
                fmFormDataVersion: undefined,
                solution: challenge.solution,
                fixed: challenge.fixed,
                fillin: challenge.fillin,
                clue: challenge.clue,
              }),
          }}
          onSubmit={(fmProps) => {
            const f = fmProps.formData;
            if (f.solution.length !== f.fixed.length) {
              return Promise.reject(
                `The pre-filled letters must have exactly the same number of letters and asterisks (${f.solution.length}) as the solution.`
              );
            }
            const misMatch = f.solution
              .split("")
              .find((sl, i) => sl !== f.fixed[i] && "*" != f.fixed[i]);
            if (misMatch !== undefined) {
              return Promise.reject(
                `The pre-filled letters must match the letters on the solution in the same positions.`
              );
            }
            const asterisks = f.fixed.split("").filter((c) => c === "*");
            if (asterisks.length > f.fillin.length) {
              return Promise.reject(
                `The number of spaces (asterisks) for pre-filled letters (${asterisks.length}) must be less than or equal to the number of fill-in letters (${f.fillin.length}).`
              );
            }
            if (otherSolutions.includes(f.solution)) {
              return Promise.reject("Another challenge has the same solution.");
            }
            const c: Challenge = {
              solution: f.solution,
              fixed: f.fixed,
              fillin: f.fillin,
              clue: f.clue,
            };
            return Promise.resolve(c);
          }}
        >
          {(fmProps) => (
            <ConfirmationDialogBase
              onClose={(isOkay) => {
                if (isOkay) {
                  fmProps
                    .submit()
                    .then((response: Challenge) => {
                      dialogRenderProps.close(true, response);
                      return response;
                    })
                    .catch((reason) => notifyError(reason));
                } else {
                  dialogRenderProps.close(false, undefined);
                }
              }}
              open={true}
              title={"Modify Challenge"}
              okayText={"Modify Challenge"}
            >
              <ColumnContainer>
                <SettingDescription>Enter the solution.</SettingDescription>
                <FmTextField<FormData>
                  name="solution"
                  labelText={`Solution (${fmProps.formData.solution.length} of 100 max)`}
                  placeholderText="Solution"
                  initialFocus
                  width="25rem"
                  maxLength={100}
                  onChange={(args) => {
                    // Set up other fields if the fixed field is "virgin".
                    // In that case we shuffle the solution automatically.
                    if (
                      args.draftFormData.fixed ===
                      makeDefaultFixedString(args.oldFieldData)
                    ) {
                      args.draftFormData.fixed = makeDefaultFixedString(
                        args.fieldData
                      );
                      args.draftFormData.fillin = shuffleString(
                        getMinimalFillin(
                          args.fieldData,
                          args.draftFormData.fixed
                        )
                      );
                    }
                  }}
                />
                <SettingDescription>
                  Pre-fill the answer with letters from the solution and
                  asterisks. The player will fill-in the spaces occupied by
                  asterisks with the fill-in letters. Use all asterisks to
                  challenge the player with an anagram. Use the space bar to
                  copy a letter from the solution.
                </SettingDescription>
                <FmTextField<FormData>
                  name="fixed"
                  labelText={`Pre-filled letters (${fmProps.formData.fixed.length} of ${fmProps.formData.solution.length})`}
                  placeholderText={"Pre-filled letters"}
                  width="25rem"
                  maxLength={fmProps.formData.solution.length}
                  onChange={(args) => {
                    // Replace spaces with the corresponding letter from the solution.
                    let t: string[] = [];
                    const c = args.fieldData.split("");
                    const s = args.draftFormData.solution.split("");
                    for (let i = 0; i < c.length; i++) {
                      t[i] = c[i] === " " ? s[i] : c[i];
                    }
                    args.draftFormData.fixed = t.join("");
                  }}
                />
                <SettingDescription>
                  The player will replace each asterisk in the the pre-filled
                  letters with one of the fill-in letters. There may be more
                  fill-in letters than asterisks, but there cannot be fewer.
                </SettingDescription>
                <FmTextField<FormData>
                  name="fillin"
                  placeholderText="Fill-in letters"
                  labelText={`Fill-in Letters (${fmProps.formData.fillin.length})`}
                  width="25rem"
                  maxLength={20}
                />
                <SubtleButton
                  onClick={() =>
                    fmProps.setFormData((draft) => {
                      draft.fillin = shuffleString(draft.fillin);
                    })
                  }
                >
                  Shuffle Fill-in Letters
                </SubtleButton>
                <RowContainer center>
                  <SubtleButton
                    onClick={() =>
                      fmProps.setFormData((draft) => {
                        draft.fillin = shuffleString(
                          getMinimalFillin(draft.solution, draft.fixed)
                        );
                      })
                    }
                  >
                    Remove Extra Fill-in Letters
                  </SubtleButton>
                  <TellMeMore icon>
                    This is useful when you add pre-filled letters and wish to
                    remove them from the fill-in letters. It takes the letters
                    in the solution, removes the pre-filled letters, and then
                    shuffles the remaining letters.
                  </TellMeMore>
                </RowContainer>
                <FmTextField<FormData>
                  name="clue"
                  placeholderText="Clue"
                  labelText={`Clue (${fmProps.formData.clue.length} of 250 max)`}
                  width="25rem"
                  maxLength={250}
                />
              </ColumnContainer>
            </ConfirmationDialogBase>
          )}
        </FmForm>
      );
    },
  });
};

// The default fixed string is spaces for spaces, and asterisks for all other letters.
export const makeDefaultFixedString = (t: string) => {
  return t
    .split("")
    .reduce((result, letter) => {
      result.push(letter === " " ? " " : "*");
      return result;
    }, [] as string[])
    .join("");
};

export const getMinimalFillin = (solution: string, fixed: string) => {
  return fixed
    .split("")
    .reduce((fillin, fixedLetter) => {
      if (fixedLetter === "*") return fillin;
      const index = fillin.findIndex(
        (fillinLetter) => fillinLetter === fixedLetter
      );
      if (index >= 0) {
        fillin.splice(index, 1);
      }
      return fillin;
    }, solution.split(""))
    .join("");
};
