import Box from "@material-ui/core/Box";
import * as React from "react";
import { LetterButtonComponent } from "./LetterButtonComponent";

export interface LettersComponentProps {
  letters: string;
  requiredLetters: string;
  onClick: (letter: string) => void;
}
const LettersComponentInternal = (props: LettersComponentProps) => {
  const { letters, requiredLetters, onClick } = props;
  return (
    <>
      {[...(letters ?? [])].map((letter, index) => {
        return (
          <Box key={index} m={1}>
            <LetterButtonComponent
              onClick={onClick}
              letter={letter}
              isRequired={
                !![...(requiredLetters ?? [])].find(
                  (requiredLetter) => requiredLetter === letter
                )
              }
            />
          </Box>
        );
      })}
    </>
  );
};

export const LettersComponent = React.memo(
  LettersComponentInternal,
  (prevProps, nextProps) => {
    if (prevProps.letters !== nextProps.letters) return false;
    if (prevProps.requiredLetters !== nextProps.requiredLetters) return false;
    return true;
  }
);
