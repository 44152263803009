import Menu from "@material-ui/core/Menu";
import * as React from "react";
import { useState } from "react";
import { useIsMounted } from "../hooks/useIsMounted";

type Coordinate = number | null;
interface Position {
  x: Coordinate;
  y: Coordinate;
}
const initialState: Position = {
  x: null,
  y: null,
};

export interface PmProps {
  close: () => void;
}

export interface PopupMenuProps {
  menuElements: (pmProps: PmProps) => JSX.Element[];
  children?: (pmProps: PmProps) => React.ReactNode;
}
export const PopupMenu = (props: PopupMenuProps) => {
  const [state, setState] = React.useState(initialState);
  const [clickHandler, setClickHandler] = useState(() => {});
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    setState({
      x: event.clientX - 2,
      y: event.clientY - 4,
    });
  };
  const isMountedRef = useIsMounted();
  const handleClose = () => {
    // Parent may unmount -- this prevents warning messages about setting state on unmounted components.
    if (isMountedRef.current) {
      setState(initialState);
    }
  };
  const pmProps = { close: () => handleClose() };
  return (
    <>
      <div
        onContextMenu={handleClick}
        onClick={handleClick}
        style={{ cursor: "context-menu" }}
      >
        {props.children(pmProps)}
      </div>
      <Menu
        keepMounted
        open={state.y !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          state.y !== null && state.x !== null
            ? { top: state.y, left: state.x }
            : undefined
        }
      >
        {props.menuElements(pmProps)}
      </Menu>
    </>
  );
};
